import React from 'react';
import { Button, TableCell, TableRow } from '@mui/material';

import { StyledFlex } from 'src/components/StyledFlex';

interface Props {
  colSpan: number;
  onCancelClick: () => void;
  onDeleteClick: () => void;
  onSaveClick: () => void;
}

export default function ActionButtons(props: Readonly<Props>) {
  const { colSpan, onCancelClick, onDeleteClick, onSaveClick } = props;
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <StyledFlex sx={{ justifyContent: 'flex-end', gap: '10px' }}>
          <Button variant="outlined" onClick={onDeleteClick}>
            Delete
          </Button>
          <Button variant="outlined" onClick={onCancelClick}>
            Cancel
          </Button>
          <Button variant="contained" onClick={onSaveClick}>
            Save
          </Button>
        </StyledFlex>
      </TableCell>
    </TableRow>
  );
}
