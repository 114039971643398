import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import DOMPurify from 'dompurify';
import { UUID } from 'crypto';
import React from 'react';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import SaveRow from '../../components/SaveRow';
import { FigureType } from '../../types';
import { useTranslation } from 'react-i18next';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import ConfirmationModal from 'src/components/ConfirmationModal';

interface Props {
  reportActivityId: UUID;
  figure: FigureType;
}

export default function ActivityRow(props: Readonly<Props>) {
  const { reportActivityId, figure } = props;
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.objectives.table',
  });
  const { queries } = useEsgTaxonomy();

  const [value, setValue] = React.useState<UUID | undefined>(undefined);
  const handleChange = (event: SelectChangeEvent<any>) =>
    setValue(event.target.value);

  const [updateConfirmationOpen, setUpdateConfirmationOpen] =
    React.useState<boolean>(false);

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activity = queries.activities._dataMap[reportActivity.activity_id];
  const activityObjective = queries.activityObjectives._data.find(
    (ao) => ao.report_activity_id === reportActivityId && ao.type === figure
  );
  const possibleCriterias = queries.mainCriterias._data.filter(
    (mc) => mc.activity_id === reportActivity.activity_id
  );
  const possibleObjectives = queries.objectives._data.filter((o) =>
    possibleCriterias.some((mc) => mc.objective_id === o.id)
  );

  React.useEffect(() => {
    if (activityObjective) {
      setValue(activityObjective.objective_id);
    }
  }, [activityObjective]);

  const handleCreateClick = () =>
    queries.activityObjectives.create.mutate({
      objective_id: value,
      type: figure,
      report_activity_id: props.reportActivityId,
    });

  const handleUpdateClick = () =>
    queries.activityObjectives.update
      .mutateAsync({
        id: activityObjective!.id,
        objective_id: value,
      })
      .then(() => {
        queries.dnshCriterias.refetch();
        setUpdateConfirmationOpen(false);
      });

  const handleSaveClick = () => {
    if (activityObjective) {
      setUpdateConfirmationOpen(true);
    } else {
      handleCreateClick();
    }
  };
  const isModified = activityObjective
    ? activityObjective.objective_id !== value
    : !!value;
  const tableCellsx = isModified ? { borderBottom: 'none' } : {};
  const criteria = possibleCriterias.find(
    (mc) => mc.objective_id === value
  )?.criteria;

  return (
    <>
      <NavigationBlockerModal
        key={String(isModified)}
        shouldBlock={isModified}
      />
      <TableRow>
        <TableCell sx={tableCellsx}>{activity.name}</TableCell>
        <TableCell sx={tableCellsx}>
          <FormControl fullWidth>
            <InputLabel size="small" id="label">
              {t('headers.objective')}
            </InputLabel>
            <Select
              size="small"
              fullWidth
              labelId="label"
              label={t('headers.objective')}
              value={value ?? ''}
              onChange={handleChange}
            >
              {possibleObjectives.map((objective) => (
                <MenuItem key={objective.id} value={objective.id}>
                  {objective.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </TableCell>
        <TableCell sx={tableCellsx}>
          <Typography
            variant="captionText"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(criteria ?? t('criteriaNotAvailable')),
            }}
          />
        </TableCell>
      </TableRow>
      {isModified && <SaveRow colSpan={3} onSaveClick={handleSaveClick} />}
      <ConfirmationModal
        open={updateConfirmationOpen}
        onClose={() => setUpdateConfirmationOpen(false)}
        onFalse={() => setUpdateConfirmationOpen(false)}
        onTrue={handleUpdateClick}
        titleKey={t('updateObjective')}
      />
    </>
  );
}
