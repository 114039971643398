import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import { ExpenditureContributionValueType, ExpenditureType } from '../../types';
import ExpenditureTable from '../components/ExpenditureTable';
import SumTableRow from '../components/SumTableRow';

export default function Capex() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.contributions',
  });
  return (
    <>
      <Typography>{t('text')}</Typography>
      <br />
      <ExpenditureTable
        type={ExpenditureType.Capex}
        valueType={ExpenditureContributionValueType.A}
      />
      <ExpenditureTable
        type={ExpenditureType.Capex}
        valueType={ExpenditureContributionValueType.B}
      />
      <ExpenditureTable
        type={ExpenditureType.Capex}
        valueType={ExpenditureContributionValueType.C}
      />

      <SumTableRow type={ExpenditureType.Capex} />
    </>
  );
}
