import React from 'react';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  IconButton,
  Badge,
} from '@mui/material';
import { Delete, Edit as EditIcon } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CustomSelect from 'src/components/CustomSelect';
import SendFormModal from './SendFormModal';
import { useEsgOrganization, useLanguage } from 'src/common/hooks';
import EditModal from './EditModal';
import { useEsgReports, useEsgStakeholderTypes } from 'src/Esg/hooks';
import { booleanOptions, numericOptions } from './utils';
import { useEsgStakeholderData } from 'src/Esg/Dmav2/hooks';
import AcceptableNumberField from 'src/components/NumberField/AcceptableNumberField';
import { StyledTableCell } from './index.styles';
import { EsgStakeholderDataOutSchema } from '../../../types';
import { UUID } from 'crypto';
import CustomizedSwitch from 'src/components/Switch/Switch';
import LightTooltip from 'src/components/LightTooltip';
import CustomizationDialog from './CustomizationDialog';

export default function SelectingStakeholdersTable() {
  const { t: _t } = useTranslation();
  const { t } = useLanguage({
    keyPrefix: 'esg:dma.selectingStakeholders.table.columns',
  });
  const organization = useEsgOrganization();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _data, update, _delete, create } = useEsgStakeholderData(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );
  const {
    data: stakeholderTypes,
    dataMap: stakeholdersTypesMap,
    create: stakeholderTypesCreate,
    _delete: stakeholderTypesDelete,
  } = useEsgStakeholderTypes({ esg_dma_id: report?.esg_dma_id });

  const [customizationRow, setCustomizationRow] = React.useState<
    EsgStakeholderDataOutSchema | undefined
  >();

  if (!report) return null;

  const handleNewRowCreate = (value: number) =>
    create.mutate({
      esg_stakeholder_type_id: value,
      esg_dma_id: report.esg_dma_id,
    });

  const handleNewRowCreateCustomStakeholder = (value: string) => {
    stakeholderTypesCreate
      .mutateAsync({
        name: value,
        esg_dma_id: report.esg_dma_id,
      })
      .then((newStakeholderType) => handleNewRowCreate(newStakeholderType.id));
  };

  const handleDeleteCustomStakeholder = (id: UUID) =>
    stakeholderTypesDelete.mutate(id);

  const _usedStakeholderTypes = _data.map(
    (row: EsgStakeholderDataOutSchema) => row.esg_stakeholder_type_id
  );
  const availableStakeholderTypes = stakeholderTypes.filter(
    (stakeholderType) => !_usedStakeholderTypes.includes(stakeholderType.id)
  );

  return (
    <>
      <Table sx={{ overflowX: 'auto', display: 'block' }}>
        <TableHead>
          <TableRow>
            <StyledTableCell sx={{ backgroundColor: '#f6f6f6' }}>
              {t('stakeholderTypes')}
            </StyledTableCell>
            <StyledTableCell sx={{ minWidth: '200px' }}>
              {t('groupSize')}
            </StyledTableCell>
            <StyledTableCell>{t('directContact')}</StyledTableCell>
            <StyledTableCell>{t('relationshipMaintenance')}</StyledTableCell>
            <StyledTableCell>{t('sustainabilityAwareness')}</StyledTableCell>
            <StyledTableCell>{t('additionalInfo')}</StyledTableCell>
            <StyledTableCell>{t('customization')}</StyledTableCell>
            <StyledTableCell>{t('acceptAnswers')}</StyledTableCell>
            <StyledTableCell sx={{ backgroundColor: '#f6f6f6' }}>
              {t('remove')}
            </StyledTableCell>
            <StyledTableCell
              sx={{ backgroundColor: '#f6f6f6', textAlign: 'center' }}
            >
              {t('send')}
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {_data.map((row: EsgStakeholderDataOutSchema) => {
            const {
              esg_stakeholder_type_id,
              group_size,
              direct_contact,
              relationship_maintenance,
              sustainability_awareness,
              additional_info,
              accept_answers,
            } = row;
            const stakeholder = stakeholdersTypesMap[esg_stakeholder_type_id];
            if (!stakeholder) return null;
            return (
              <TableRow key={row.id}>
                <StyledTableCell sx={{ backgroundColor: '#f6f6f6' }}>
                  {stakeholder.name}
                </StyledTableCell>
                <StyledTableCell>
                  <AcceptableNumberField
                    key={`group-size-${group_size}`}
                    size="small"
                    value={group_size}
                    onAccept={(value) =>
                      update.mutate({ ...row, group_size: Number(value) })
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomSelect
                    key={`${row.id}-direct_contact`}
                    value={direct_contact}
                    options={booleanOptions}
                    onChange={(value) =>
                      update.mutate({ ...row, direct_contact: value })
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <EditModal
                    title={t('relationshipMaintenance')}
                    value={relationship_maintenance}
                    onChange={(value) =>
                      update.mutate({
                        ...row,
                        relationship_maintenance: value,
                      })
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <CustomSelect
                    key={`${row.id}-sustainability_awareness`}
                    value={sustainability_awareness}
                    options={numericOptions}
                    onChange={(value) =>
                      update.mutate({
                        ...row,
                        sustainability_awareness: value,
                      })
                    }
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <EditModal
                    title={t('additionalInfo')}
                    value={additional_info}
                    onChange={(value) =>
                      update.mutate({ ...row, additional_info: value })
                    }
                  />
                </StyledTableCell>
                <StyledTableCell sx={{ textAlign: 'center' }}>
                  <LightTooltip title={t('customizationTooltip')}>
                    <Badge variant="dot" invisible={false} color="error">
                      <IconButton onClick={() => setCustomizationRow(row)}>
                        <EditIcon />
                      </IconButton>
                    </Badge>
                  </LightTooltip>
                </StyledTableCell>
                <StyledTableCell>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CustomizedSwitch
                      sx={{ margin: '0 auto' }}
                      checked={accept_answers}
                      onChange={(event) =>
                        update.mutate({
                          ...row,
                          accept_answers: event.target.checked,
                        })
                      }
                    />
                  </div>
                </StyledTableCell>

                <StyledTableCell sx={{ backgroundColor: '#f6f6f6' }}>
                  <IconButton onClick={() => _delete.mutate(row.id)}>
                    <Delete />
                  </IconButton>
                </StyledTableCell>
                <StyledTableCell
                  sx={{
                    textAlign: 'center',
                    backgroundColor: '#f6f6f6',
                  }}
                >
                  <SendFormModal
                    organizationId={String(organization.id)}
                    reportId={reportId}
                    stakeholderTypeId={esg_stakeholder_type_id}
                    stakeholderName={stakeholder.name}
                    disabled={
                      !group_size ||
                      !sustainability_awareness ||
                      direct_contact === null
                    }
                  />
                </StyledTableCell>
              </TableRow>
            );
          })}
          {!!availableStakeholderTypes.length && (
            <TableRow>
              <StyledTableCell
                sx={{ maxWidth: '200px', backgroundColor: '#f6f6f6' }}
              >
                <CustomSelect
                  key="new-row"
                  value={null}
                  options={availableStakeholderTypes.map((s) => ({
                    value: s.id,
                    label: s.name,
                    isCustom: !!s.esg_dma_id,
                  }))}
                  menuMaxHeight="400px"
                  onChange={handleNewRowCreate}
                  customOptionsProps={{
                    label: t('addNewStakeholderType'),
                    onChangeCustomOption: handleNewRowCreateCustomStakeholder,
                    onDeleteCustomOption: handleDeleteCustomStakeholder,
                  }}
                />
              </StyledTableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      <CustomizationDialog
        row={customizationRow}
        open={!!customizationRow}
        onClose={() => setCustomizationRow(undefined)}
      />
    </>
  );
}
