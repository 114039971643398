import React from 'react';
import { useParams } from 'react-router-dom';

import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { StyledPanel } from 'src/Esg/styles';
import NoData from 'src/components/NoData';
import { useEsgReports } from 'src/Esg/hooks';
import {
  useEsgStakeholderData,
  useEsgStakeholderSurveys,
  useStakeholderTopics,
} from 'src/Esg/Dmav2/hooks';
import { Divider, IconButton, Typography } from '@mui/material';
import { StyledFlex } from 'src/components/StyledFlex';
import PaginatorSwitch, {
  usePaginationSwitch,
} from 'src/components/PaginatorSwitch';
import { Delete } from '@mui/icons-material';
import QuestionnaireTable from 'src/Esg/Dmav2/StakeholderSurvey/Questionnaire/Table';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { getSurveyNumber } from 'src/Esg/Dmav2/StakeholderSurvey/utils';

export default function Individual() {
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const {
    _data: surveys,
    isLoading,
    isError,
    _delete,
  } = useEsgStakeholderSurveys(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report?.esg_dma_id }
  );

  const { currPage, setCurrPage, handlePrevClick, handleNextClick } =
    usePaginationSwitch();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  const handleDelete = () =>
    _delete.mutateAsync(surveys[currPage].id).then(() => {
      setCurrPage(0);
      setDeleteConfirmationOpen(false);
    });

  const { _data: stakeholderDataEntries } = useEsgStakeholderData({
    esg_dma_id: report?.esg_dma_id,
  });

  const stakeholderData = stakeholderDataEntries?.find(
    (entry) =>
      entry.esg_stakeholder_type_id ===
      surveys[currPage]?.esg_stakeholder_type_id
  );

  const { subTopics, subSubTopics } = useStakeholderTopics(stakeholderData?.id);

  if (isLoading) return <PageLoading />;
  if (isError) return <SomethingWentWrong />;
  if (!surveys[currPage]) return <NoData />;
  if (surveys.length === 0) return <NoData />;

  return (
    <StyledPanel sx={{ minWidth: '940px' }}>
      <StyledFlex sx={{ mt: '-12px' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <PaginatorSwitch
            currPage={currPage}
            pagesCount={surveys.length}
            onPrevClick={handlePrevClick}
            onNextClick={handleNextClick}
          />
          <Typography variant="h3">
            {surveys[currPage].esg_stakeholder_type_name} #
            {getSurveyNumber(surveys, currPage)}
          </Typography>
        </div>
        <IconButton onClick={() => setDeleteConfirmationOpen(true)}>
          <Delete />
        </IconButton>
      </StyledFlex>

      <Divider sx={{ m: '20px -32px' }} />
      <QuestionnaireTable
        readOnly
        esgStakeholderSurveyId={surveys[currPage].id}
        topics={{ sub_topics: subTopics, sub_sub_topics: subSubTopics }}
      />
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        textKey="esg:dma.stakeholderAnalytics.tables.deleteConfirmationText"
        titleKey="esg:dma.stakeholderAnalytics.tables.deleteConfirmationTitle"
        onTrue={handleDelete}
      />
    </StyledPanel>
  );
}
