import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useQuestionTable, useQuestionAnswers } from '../../../../hooks';
import { UUID } from 'crypto';
import NoData from '../../Rows/NoData';
import { useTranslation } from 'react-i18next';
import { FieldAnswerOutSchema } from 'src/QuestionnairesV3/types';
import FieldAnswerEditDialog from './FieldAnswerEditDialog';
import Answer from './Answer';

interface Props {
  questionId: UUID;
}

export default function QuestionTableInRows(props: Readonly<Props>) {
  const { questionId } = props;
  const { t } = useTranslation('legacyGhg', {
    keyPrefix: 'questionnaireV3.summary',
  });

  const { _data: questionAnswers } = useQuestionAnswers({
    question_id: questionId,
  });

  const { question, fields } = useQuestionTable(questionId);

  const [editFieldAnswer, setEditFieldAnswer] = React.useState<
    FieldAnswerOutSchema | undefined
  >();

  if (!question) return null;

  const tableSx = {
    minWidth: `${160 * fields.length}px`,
    tableLayout: 'fixed',
  };

  return (
    <>
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table sx={tableSx}>
          <TableHead>
            <TableCell>&nbsp;</TableCell>
            {questionAnswers.map((qa, idx) => (
              <TableCell sx={{ textAlign: 'center' }} key={qa.id}>
                {t('answer')} {idx + 1}
              </TableCell>
            ))}
          </TableHead>
          <TableBody>
            {questionAnswers.length === 0 && (
              <NoData colSpan={questionAnswers.length} />
            )}
            {!!questionAnswers.length &&
              fields.map((field) => (
                <TableRow key={field.id}>
                  <TableCell
                    sx={{
                      backgroundColor: '#fafafa',
                      borderRight: '1px solid #e0e0e0',
                    }}
                  >
                    {field.title}
                  </TableCell>
                  {questionAnswers.map((qa) => (
                    <Answer
                      key={qa.id}
                      questionAnswerId={qa.id}
                      field={field}
                      onClick={(fieldAnswer) => setEditFieldAnswer(fieldAnswer)}
                    />
                  ))}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <FieldAnswerEditDialog
        questionId={questionId}
        fieldAnswer={editFieldAnswer}
        open={!!editFieldAnswer}
        onClose={() => setEditFieldAnswer(undefined)}
      />
    </>
  );
}
