import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { FigureType } from '../../types';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import NumberField from 'src/components/NumberField';
import { useTranslation } from 'react-i18next';

interface Props {
  figure: FigureType;
}

export default function TotalValue({ figure }: Readonly<Props>) {
  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.totalValue' });
  const [value, setValue] = React.useState<string | number | null>(null);

  const totalValue = queries.reportTotalValues._data.find(
    (tv) => tv.type === figure
  );

  React.useEffect(() => {
    setValue(totalValue?.value ?? null);
  }, [totalValue]);

  if (!taxonomyReport || queries.isLoading || reportAnalytics.isLoading)
    return null;

  const handleSave = () =>
    totalValue
      ? queries.reportTotalValues.update
          .mutateAsync({
            id: totalValue.id,
            value: Number(value),
          })
          .then(() => {
            reportAnalytics.refetch();
          })
      : queries.reportTotalValues.create
          .mutateAsync({
            report_id: taxonomyReport.id,
            type: figure,
            value: Number(value),
          })
          .then(() => {
            reportAnalytics.refetch();
          });

  const isModified = totalValue ? totalValue.value !== value : value !== null;

  return (
    <>
      <Typography sx={{ mb: '12px' }}>{t('text', { figure })}</Typography>
      <NumberField
        fullWidth
        label={taxonomyReport.currency_type}
        decimalPlaces={0}
        value={value ?? ''}
        onChange={(value) => setValue(Number(value))}
      />
      <Box width="100%" textAlign="right" mt="8px" height="32px">
        <Button
          size="small"
          onClick={handleSave}
          variant="contained"
          disabled={!isModified}
        >
          {t('save')}
        </Button>
      </Box>
    </>
  );
}
