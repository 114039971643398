import { Close } from '@mui/icons-material';
import { IconButton, IconButtonProps } from '@mui/material';
import React from 'react';

export default function DialogCloseButton(props: IconButtonProps) {
  return (
    <IconButton
      sx={{ position: 'absolute', right: '10px', top: '10px' }}
      {...props}
    >
      <Close />
    </IconButton>
  );
}
