import React from 'react';
import { useTranslation } from 'react-i18next';

import { TableCell, TableHead, TableRow } from '@mui/material';
import colors from 'src/theme/colors';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import {
  ExpenditureContributionValueType,
  ExpenditureType,
} from 'src/Esg/Taxonomy/types';

interface Props {
  type: ExpenditureType;
  valueType: ExpenditureContributionValueType;
}

export default function Head(props: Readonly<Props>) {
  const { type, valueType } = props;
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.contributions.table.headers',
  });

  const { taxonomyReport, queries } = useEsgTaxonomy();
  if (!taxonomyReport || queries.isLoading) return null;

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '50%' }}>
          {type} Type {valueType.toUpperCase()}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '25%' }}>
          {type}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '25%' }}>
          {t('aligned')} {type} ({taxonomyReport.currency_type})
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
