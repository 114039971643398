import React, { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import Login from '../views/Pages/Login';
import Register from '../views/Pages/Register';
import PasswordRecovery from '../views/Pages/PasswordRecovery';
import PasswordNew from '../views/Pages/PasswordNew';
import PasswordSuccess from '../views/Pages/PasswordSuccess';
import EmployeeSurveyForm from '../views/Pages/EmployeeSurvey/employee-survey';
import { client } from '../utils/api-client';
import * as Sentry from '@sentry/react';
import NotFound from 'src/views/components/NotFound';
import { RouteLayout } from '../views/components/Layout';
import TopBar from '../components/TopBar';
import SupplierSurveyIntro from '../Ghg/SupplierSurvey/Intro';
import SupplierSurveyInitialQuestions from '../Ghg/SupplierSurvey/InitialQuestions';
import SupplierSurveyQuestionnaire from '../Ghg/SupplierSurvey/Questionnaire';
import SupplierSurveyEnd from '../Ghg/SupplierSurvey/End';
import { auth } from '../utils/auth';
import LoginWithToken from 'src/views/Pages/LoginWithToken';
import RegisterUBNP from 'src/views/Pages/RegisterUBNP';
import CodeOfConductSurveyForm from '../Ghg/CodeOfConductSurvey/Form';
import CodeOfConductSurveyEnd from '../Ghg/CodeOfConductSurvey/End';
import CodeOfConductSurvey from '../Ghg/CodeOfConductSurvey';
import OperatorSurvey from 'src/Cbam/OperatorSurvey';
import { useLanguage } from '../common/hooks';
import ghgTheme from 'src/Ghg/theme';
import CbamProductionProcessSurveyRoute from '../Cbam/ProductionProcessSurvey';
import Forbidden from 'src/components/Forbidden';
import QueryParamTokenAuthInterceptor from 'src/components/Auth/QueryParamTokenAuthInterceptor';
import StakeholderSurveyEnd from 'src/Esg/Dmav2/StakeholderSurvey/End';
import Intro from 'src/Esg/Dmav2/StakeholderSurvey/Intro';
import Questionnaire from 'src/Esg/Dmav2/StakeholderSurvey/Questionnaire';
import {
  EsgLegacyStakeholderSurveyNavigator,
  EsgLegacyStakeholderSurveyRoute,
} from 'src/Esg';
import DemoAccessExpired from 'src/components/DemoAccessExpired';
import { AxiosResponse } from 'axios';
import MaintenanceModeActive from 'src/components/MaintenanceMode';

const Logout = () => {
  useEffect(() => {
    auth.signOut();
  }, []);

  return <Navigate to="/" />;
};

interface Props {
  children: React.ReactNode;
}

export default function UnauthorizedAccessProvider(props: Props) {
  const { i18n } = useLanguage();
  const navigate = useNavigate();

  // set language
  useEffect(() => {
    const languageInterceptor = client.interceptors.request.use((config) => {
      const lang = i18n.language;
      if (lang) config.headers['Accept-Language'] = `${lang}, en;q=0.9`;
      return config;
    });
    return () => client.interceptors.request.eject(languageInterceptor);
  }, [i18n]);

  // setup sentry
  useEffect(() => {
    const sentryInterceptor = client.interceptors.request.use(
      (config) => config,
      (error) => {
        Sentry.captureException(error);
        return Promise.reject(error);
      }
    );
    return () => client.interceptors.request.eject(sentryInterceptor);
  }, []);

  // handle maintenance mode
  React.useEffect(() => {
    const maintenanceModeInterceptor = client.interceptors.response.use(
      (res: AxiosResponse<any, any>) => res,
      (error) => {
        if (
          error?.response?.status === 503 &&
          error?.response?.data?.detail?.includes('maintenance')
        ) {
          navigate('/maintenance');
        }
        throw error;
      }
    );
    return () => client.interceptors.response.eject(maintenanceModeInterceptor);
  }, []);

  return (
    <ThemeProvider theme={ghgTheme}>
      <Routes>
        <Route element={<RouteLayout topbar={<TopBar />} />}>
          {/* Login, logout */}
          <Route path="/login/:token" element={<LoginWithToken />} />
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/register" element={<Register />} />

          {/* password management */}
          <Route path="/password" element={<PasswordRecovery />} />
          <Route path="/password/new" element={<PasswordNew />} />
          <Route path="/password/success" element={<PasswordSuccess />} />

          {/* 404 */}
          <Route path="/404" element={<NotFound />} />
          <Route path="/403" element={<Forbidden />} />

          {/* Demo access expired */}
          <Route path="/demo-access-expired" element={<DemoAccessExpired />} />
        </Route>

        {/* Maintenance mode */}
        <Route path="/maintenance" element={<MaintenanceModeActive />} />

        {/* TODO: use common Layout for these? */}
        {/* Employee forms */}
        <Route path="/employee_form" element={<EmployeeSurveyForm />} />

        {/* Stakeholder survey */}
        <Route
          path="stakeholders/:token/*"
          element={
            <QueryParamTokenAuthInterceptor>
              <EsgLegacyStakeholderSurveyNavigator>
                <Outlet />
              </EsgLegacyStakeholderSurveyNavigator>
            </QueryParamTokenAuthInterceptor>
          }
        >
          <Route index element={<Intro />} />
          <Route path="survey" element={<Questionnaire />} />
          {/* <Route path="summary" element={<StakeholderSurveySummary />} /> */}
          <Route path="end" element={<StakeholderSurveyEnd />} />
        </Route>

        {/* Legacy Stakeholder survey */}
        <Route
          path="stakeholders-legacy/:token/*"
          element={<EsgLegacyStakeholderSurveyRoute />}
        />

        {/* Supplier survey */}
        <Route path="suppliers/:token">
          <Route index element={<SupplierSurveyIntro />} />
          <Route
            path="initial-questions"
            element={<SupplierSurveyInitialQuestions />}
          />
          <Route
            path="questionnaire"
            element={<SupplierSurveyQuestionnaire />}
          />
          <Route path="end" element={<SupplierSurveyEnd />} />
        </Route>

        <Route path="code-of-conduct-survey/:token">
          <Route index element={<CodeOfConductSurvey />} />
          <Route path="form" element={<CodeOfConductSurveyForm />} />
          <Route path="end" element={<CodeOfConductSurveyEnd />} />
        </Route>

        {/* CBAM survey */}
        <Route
          path="production-process-survey/:token/*"
          element={<CbamProductionProcessSurveyRoute />}
        />

        {/* Registration form for microBNP */}
        <Route
          path="/register_ubnp"
          element={
            <RouteLayout>
              <RegisterUBNP />
            </RouteLayout>
          }
        />

        <Route path="cbam/operator-survey/*" element={<OperatorSurvey />} />

        <Route path="*" element={props.children} />
      </Routes>
    </ThemeProvider>
  );
}
