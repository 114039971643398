import React, { useEffect, useState } from 'react';
import { Outlet, RouteProps, useLocation, useParams } from 'react-router-dom';
import { EditButton, Title } from './index.styles';
import { Container } from '../ReportsIndex/index.styles';
import ReportTabs from '../../../../components/esg/reportTabs';
import { EditIcon } from './icon';
import EditNameModal from './editNameModal';
import { useRequiredSelector, useEsgOrganization } from 'src/common/hooks';

import EsgExportToolsButton from 'src/components/EsgExportToolsButton';
import { useEsgReports } from 'src/Esg/hooks';
import { useEsgDmas } from 'src/Esg/Dmav2/hooks';
import { Box, IconButton } from '@mui/material';
import { StyledFlex } from 'src/components/StyledFlex';

export default function ReportPage(props: RouteProps) {
  const location = useLocation();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma } = useEsgDmas(
    { id: report?.esg_dma_id },
    { enabled: !!report }
  );

  const [open, setOpen] = useState(false);

  const organization = useEsgOrganization();
  const canEdit = organization.can_write_data;

  useEffect(() => {
    if (!!report)
      localStorage.setItem('esg_report', report.id.toString() as string);
  }, [report]);

  if (dma === undefined || report === undefined) return null;

  const TabData = [
    {
      name: 'esg:indicators',
      url: `/esg/reports/${report.id}/indicators`,
    },
    {
      name: 'esg:summary',
      url: `/esg/reports/${report.id}/summary`,
    },
    {
      name: 'esg:download',
      url: `/esg/reports/${report.id}/download`,
    },
  ];

  if (canEdit) {
    TabData.unshift({
      name: 'esg:taxonomy.taxonomy',
      url: `/esg/reports/${report.id}/taxonomy`,
    });
    TabData.unshift({
      name: 'esg:materialityAssesment',
      url: `/esg/reports/${report.id}/dma/v${dma.version}`,
    });
  }

  const handleClose = () => setOpen(false);

  // Determine if the current page is the DMA page
  const shouldHideEsgExportButton =
    location.pathname.split('/').pop() === 'dma';

  return (
    <Container>
      <StyledFlex sx={{ mb: 0 }}>
        <StyledFlex
          sx={{ justifyContent: 'flex-start' }}
          onClick={() => canEdit && setOpen(true)}
        >
          <Title>
            {report.name} ({report.period})
          </Title>
          {canEdit && (
            <IconButton size="small">
              <EditIcon />
            </IconButton>
          )}
        </StyledFlex>

        {!shouldHideEsgExportButton && (
          <EsgExportToolsButton
            reportIds={[report.id]}
            organizationIds={[organization.id]}
            allowDocx
            size="medium"
          />
        )}
      </StyledFlex>

      <EditNameModal
        key={report.id}
        open={open}
        setClose={handleClose}
        report={report}
        shouldRedirect // temporary solution before refactor
      />

      <ReportTabs data={TabData} />

      {props.children}
      <Outlet />
    </Container>
  );
}
