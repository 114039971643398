import { Close } from '@mui/icons-material';
import { Box, IconButton } from '@mui/material';
import React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const fullScreenSx = {
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: '#fff',
  zIndex: 1300, // default MuiDialog z-index (not used MuiDialog direclty as switching components re-renders children and we loose the state)
  overflow: 'auto',
};

export default function FullScreen(props: Readonly<Props>) {
  return (
    <Box sx={props.open ? fullScreenSx : null}>
      {props.open && (
        <IconButton
          sx={{ position: 'absolute', right: '20px' }}
          onClick={props.onClose}
        >
          <Close />
        </IconButton>
      )}
      {props.children}
    </Box>
  );
}
