import { TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import { toCurrency } from 'src/Esg/Taxonomy/utils';

export default function SumRows() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.contributions.table.footer',
  });

  const { taxonomyReport, reportAnalytics } = useEsgTaxonomy();
  if (!taxonomyReport || reportAnalytics.isLoading) return null;

  const analytics = reportAnalytics._instance?.turnover;

  return (
    <TableRow>
      <TableCell colSpan={2}>{t('total')}</TableCell>
      <TableCell>
        {toCurrency(
          analytics?.aligned_contribution,
          taxonomyReport.currency_type
        )}
      </TableCell>
    </TableRow>
  );
}
