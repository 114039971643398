import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, Typography } from '@mui/material';

import colors from 'src/theme/colors';
import ImpactCells from './Cells/Impact';
import RiskOpportunityCells from './Cells/RiskOpportunity';
import {
  EsgDmaImpactOutSchema,
  EsgDmaRiskOpportunityOutSchema,
  TimeHorizon,
} from '../../../types';

interface Props {
  riskOpportunity: EsgDmaRiskOpportunityOutSchema | null;
  impact: EsgDmaImpactOutSchema | null;
  timeHorizon: TimeHorizon;
}

export default function TimeHorizonRow(props: Readonly<Props>) {
  const { timeHorizon, impact, riskOpportunity } = props;
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dma.step5TableV2.body',
  });
  return (
    <TableRow>
      <TableCell style={{ backgroundColor: colors.backgroundInput }}>
        <Typography sx={{ ml: '32px', fontStyle: 'italic', fontSize: '12px' }}>
          {t(timeHorizon)}
        </Typography>
      </TableCell>
      <ImpactCells impact={impact} timeHorizon={timeHorizon} />

      <RiskOpportunityCells
        riskOpportunity={riskOpportunity}
        timeHorizon={timeHorizon}
      />
    </TableRow>
  );
}
