import React from 'react';
import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { UUID } from 'crypto';
import { useEsgQuestionnaireAnswerReview } from 'src/Esg/hooks';
import { useLanguage } from 'src/common/hooks';
import { dateToString } from '../../utils';
import { useAppSelector } from 'src/redux-file/hooks';
import themeColors from 'src/theme/colors';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useTranslation } from 'react-i18next';

export default function QuestionnaireAnswerReviewPanel({
  qaId,
}: {
  qaId: UUID;
}) {
  const { language } = useLanguage();
  const { t } = useTranslation('esg', { keyPrefix: 'questionnaireAnswer' });

  const { _data, _delete, create, update } = useEsgQuestionnaireAnswerReview({
    esg_questionnaire_answer_id: qaId,
  });
  const review = _data[0];

  const [open, setOpen] = React.useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);
  const [comment, setComment] = React.useState('');

  const { id: userId } = useAppSelector(
    (state) => state.platform.user || { id: null }
  );
  const isReviewer = !review || userId === review.reviewed_by_id;

  React.useEffect(() => {
    if (review) setComment(review.comment);
  }, [review]);

  const addReview = (approved: boolean) => {
    const status = approved ? 'approved' : 'rejected';
    if (review) {
      update.mutateAsync({
        id: review.id,
        status,
        comment,
      });
    } else {
      create.mutateAsync({
        esg_questionnaire_answer_id: qaId,
        status,
        comment,
      });
    }
    setOpen(false);
  };

  const removeReview = () => {
    _delete.mutateAsync(review.id);
    setDeleteConfirmationOpen(false);
    setOpen(false);
    setComment('');
  };

  return (
    <Box
      sx={{
        border: '1px solid #ccc',
        backgroundColor: '#fff',
        p: '16px',
        borderRadius: '16px',
        flex: 1,
        position: 'relative',
      }}
    >
      <Typography variant="h6">{t('review')}</Typography>
      <Button
        variant="secondary"
        onClick={() => setOpen(true)}
        sx={{ position: 'absolute', top: '16px', right: '16px' }}
      >
        {review ? t('editReview') : t('addReview')}
      </Button>
      {review ? (
        <>
          <Box
            display="flex"
            alignItems="center"
            sx={{ maxWidth: 'calc(100% - 200px)' }}
          >
            {review.status === 'approved' ? (
              <>
                <DoneOutlinedIcon color="success" />
                <Typography sx={{ ml: '8px' }}>
                  {t('approvedBy', {
                    name: review.reviewed_by,
                    date: dateToString(new Date(review.reviewed_at), language),
                  })}
                </Typography>
              </>
            ) : (
              <>
                <CloseOutlinedIcon color="error" />
                <Typography sx={{ ml: '8px' }}>
                  {t('rejectedBy', {
                    name: review.reviewed_by,
                    date: dateToString(new Date(review.reviewed_at), language),
                  })}
                </Typography>
              </>
            )}
          </Box>
          {review.comment && (
            <>
              <Typography variant="subtitle2" sx={{ mt: 2 }}>
                {t('comment')}
              </Typography>
              <Typography
                sx={{
                  border: '1px solid #ccc',
                  borderRadius: '8px',
                  p: '8px',
                  backgroundColor: '#f9f9f9',
                  maxHeight: '120px',
                  overflow: 'auto',
                }}
              >
                {review.comment}
              </Typography>
            </>
          )}
        </>
      ) : (
        <Typography sx={{ mt: '8px' }}>{t('noReview')}</Typography>
      )}
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Typography variant="h6">{t('review')}</Typography>
        <Box display="flex" flexDirection="column" sx={{ mt: 2 }}>
          <TextField
            value={comment}
            placeholder={t('addReviewComment')}
            onChange={(e) => setComment(e.target.value)}
            rows={4}
            multiline
            disabled={!isReviewer}
          />
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ mt: '16px' }}
          >
            <Box>
              <Button
                variant="secondary"
                onClick={() => addReview(true)}
                endIcon={<DoneOutlinedIcon color="success" />}
                disabled={!isReviewer}
                sx={{ mr: 2 }}
              >
                {t('approve')}
              </Button>
              <Button
                variant="secondary"
                onClick={() => addReview(false)}
                endIcon={<CloseOutlinedIcon color="error" />}
                disabled={!isReviewer}
              >
                {t('reject')}
              </Button>
            </Box>
            {review && (
              <>
                <DeleteOutlineOutlinedIcon
                  onClick={() => setDeleteConfirmationOpen(true)}
                  sx={{ cursor: 'pointer', color: themeColors.esgDark }}
                />
                <ConfirmationModal
                  open={deleteConfirmationOpen}
                  onClose={() => setDeleteConfirmationOpen(false)}
                  onFalse={() => setDeleteConfirmationOpen(false)}
                  onTrue={removeReview}
                  titleKey={t('removeReviewConfirmation')}
                />
              </>
            )}
          </Box>
          {!isReviewer && (
            <Typography sx={{ mt: 2 }}>{t('onlyReviewerCanEdit')}</Typography>
          )}
        </Box>
      </Dialog>
    </Box>
  );
}
