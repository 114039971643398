import React from 'react';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { UUID } from 'crypto';
import { useEsgQuestionnaireAnswer } from 'src/Esg/hooks';
import QuestionnaireAnswerReviewPanel from '../QuestionnaireAnswerReview';
import { useLanguage } from 'src/common/hooks';
import { dateToString } from '../../utils';
import { useTranslation } from 'react-i18next';

export default function QuestionnaireAnswerOverview({ qaId }: { qaId?: UUID }) {
  const { language } = useLanguage();
  const { t } = useTranslation('esg', { keyPrefix: 'questionnaireAnswer' });
  const questionnaireAnswer = useEsgQuestionnaireAnswer(qaId);

  const {
    completed_by: completedBy,
    completed_at: completedAt,
    last_modified_at: lastModified,
  } = questionnaireAnswer.data || {};

  const isCompleted = !!completedBy && !!completedAt;
  const toggleCompleted = () => {
    questionnaireAnswer.setIsCompleted.mutateAsync(!isCompleted);
  };
  const completedLabel = isCompleted
    ? t('completedBy', {
        name: completedBy,
        date: dateToString(new Date(completedAt), language),
      })
    : t('markAsCompleted');

  return qaId ? (
    <Box display="flex" sx={{ mb: '24px' }}>
      <Box
        sx={{
          border: '1px solid #ccc',
          backgroundColor: '#fff',
          p: '16px',
          borderRadius: '16px',
          mr: 2,
          width: '40%',
        }}
      >
        <Typography variant="h6">{t('status')}</Typography>
        {!!lastModified && (
          <Typography>
            {t('lastModified', {
              date: dateToString(new Date(lastModified), language),
            })}
          </Typography>
        )}
        <FormControlLabel
          control={
            <Checkbox checked={!!completedBy} onChange={toggleCompleted} />
          }
          label={<Typography sx={{ mt: '8px' }}>{completedLabel}</Typography>}
          sx={{ mt: '8px', alignItems: 'start' }}
        />
      </Box>
      <QuestionnaireAnswerReviewPanel qaId={qaId} />
    </Box>
  ) : null;
}
