import React from 'react';

import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Popover,
  TextField,
  Typography,
} from '@mui/material';
import { Comment as CommentIcon } from '@mui/icons-material';

import Comment from './Comment';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useTranslation } from 'react-i18next';
import { useFieldAnswerComments } from 'src/QuestionnairesV3/hooks';

interface Props {
  readonly fieldAnswerId: number;
}
export default function FieldAnswerComments(props: Props) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'questionnaireV3.comments',
  });
  const { fieldAnswerId } = props;

  const { _data, _delete, create, update } = useFieldAnswerComments();
  const fieldAnswerComments = _data.filter(
    (x) => x.field_answer_id === fieldAnswerId
  );

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [text, setText] = React.useState('');

  const [confirmationDeleteId, setConfirmationDeleteId] = React.useState<
    number | null
  >(null);

  const handleOpen = (e: React.MouseEvent<HTMLElement>) =>
    setAnchorEl(e.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    setText('');
  };

  const handleSaveClick = () =>
    create
      .mutateAsync({ field_answer_id: fieldAnswerId, text })
      .then(() => setText(''));

  return (
    <>
      <IconButton onClick={handleOpen}>
        <Badge badgeContent={fieldAnswerComments.length} color="primary">
          <CommentIcon />
        </Badge>
      </IconButton>
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Box width="600px" p="20px">
          <Typography variant="h4">{t('comments')}:</Typography>
          <Box height="10px" />
          {fieldAnswerComments.length === 0 && (
            <Typography variant="caption">{t('noComments')}.</Typography>
          )}
          <Box maxHeight="70vh" overflow="auto">
            {fieldAnswerComments.map((comment) => (
              <Comment
                key={comment.id}
                comment={comment}
                onDelete={() => setConfirmationDeleteId(comment.id)}
                onUpdate={(data) => update.mutateAsync(data)}
              />
            ))}
          </Box>
          <Divider sx={{ m: '20px 0px' }} />
          <TextField
            size="small"
            placeholder={t('startTyping')}
            label={t('addComment')}
            fullWidth
            autoFocus
            multiline
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Button
            disabled={!text}
            size="small"
            variant="contained"
            onClick={handleSaveClick}
            sx={{ float: 'right', m: 2, mr: 0 }}
          >
            {t('save')}
          </Button>
          {!!anchorEl && (
            // !!anchorEl to exclude rendering of the confirmation component when anchorEl is null
            <ConfirmationModal
              titleKey="common.areYouSure"
              trueButtonTextKey="common.yes"
              falseButtonTextKey="common.no"
              open={!!confirmationDeleteId}
              onClose={() => setConfirmationDeleteId(null)}
              onFalse={() => setConfirmationDeleteId(null)}
              onTrue={() =>
                _delete
                  .mutateAsync(Number(confirmationDeleteId))
                  .then(() => setConfirmationDeleteId(null))
              }
            />
          )}
        </Box>
      </Popover>
    </>
  );
}
