import React from 'react';

import { TableCell, TableRow, Typography } from '@mui/material';
import { UUID } from 'crypto';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import NumberField from 'src/components/NumberField';
import SaveRow from 'src/Esg/Taxonomy/components/SaveRow';
import { toPercentage } from 'src/Esg/Taxonomy/utils';

interface Props {
  reportActivityId: UUID;
}

export default function ActivityRow({ reportActivityId }: Readonly<Props>) {
  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();

  const [value, setValue] = React.useState<string | number | null>(0);

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activity = queries.activities._dataMap[reportActivity?.activity_id];
  const turnoverValue = queries.turnoverValues._data.find(
    (tv) => tv.report_activity_id === reportActivityId
  );

  React.useEffect(() => {
    if (turnoverValue) setValue(turnoverValue.value);
  }, [turnoverValue]);

  const handleSaveClick = () =>
    turnoverValue
      ? queries.turnoverValues.update
          .mutateAsync({
            ...turnoverValue,
            value: Number(value),
          })
          .then(() => {
            reportAnalytics.refetch();
          })
      : queries.turnoverValues.create
          .mutateAsync({
            report_activity_id: reportActivityId,
            value: Number(value),
          })
          .then(() => {
            reportAnalytics.refetch();
          });

  if (queries.isLoading || reportAnalytics.isLoading) return null;

  const analytics =
    reportAnalytics._instance?.turnover?.activities_analytics.find(
      (aa) => aa.report_activity_id === reportActivityId
    );

  const originalValue = turnoverValue?.value ?? 0;
  const isModified = originalValue !== value;
  const tableCellsx = isModified ? { borderBottom: 'none' } : {};

  return (
    <>
      <TableRow>
        <TableCell sx={tableCellsx}>
          <Typography>{activity.label}</Typography>
        </TableCell>
        <TableCell sx={tableCellsx}>
          <NumberField
            fullWidth
            size="small"
            decimalPlaces={0}
            label={taxonomyReport?.currency_type}
            value={value}
            onChange={(value) => setValue(Number(value))}
          />
        </TableCell>
        <TableCell sx={tableCellsx}>
          {toPercentage(analytics?.value_percentage)}
        </TableCell>
      </TableRow>
      {isModified && <SaveRow colSpan={7} onSaveClick={handleSaveClick} />}
    </>
  );
}
