import { Button } from '@mui/material';
import React from 'react';
import { Fullscreen } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}
export default function FullScreenButton(props: Readonly<Props>) {
  const { t } = useTranslation('legacyGhg', {
    keyPrefix: 'questionnaireV3.questionTable',
  });
  return (
    <Button
      sx={{ float: 'right' }}
      startIcon={<Fullscreen />}
      onClick={props.onClick}
    >
      {t('fullScreen')}
    </Button>
  );
}
