import { UUID } from 'crypto';
import React from 'react';
import { useFields, useQuestionAnswers, useQv3Context } from '../hooks';
import { Box, Button } from '@mui/material';
import Field from './Field';
import { buttonIdPrefix } from './Buttons';
import ConfirmationModal from 'src/components/ConfirmationModal';
import QuestionAnswerHeader from './QuestionAnswerHeader';
import { useLanguage } from 'src/common/hooks';

interface Props {
  questionId: UUID;
  questionAnswerId: number;
}

export default function Fields(props: Readonly<Props>) {
  const { questionId, questionAnswerId } = props;
  const { t } = useLanguage({ keyPrefix: 'questionnaireV3.questionAnswer' });
  const { modifiedQuestionAnswers } = useQv3Context();
  const questionAnswers = useQuestionAnswers({}, { enabled: false });

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState(false);

  const { _data: fields, isLoading } = useFields(
    { question_id: questionId },
    { enabled: !!questionId }
  );

  if (isLoading) return null;
  if (!questionId) return null;

  const handleSaveClick = () => {
    modifiedQuestionAnswers.remove(questionAnswerId);
    document
      .querySelectorAll(`button[id^="${buttonIdPrefix}"]`)
      .forEach((button) => (button as HTMLButtonElement).click());
  };

  return (
    <Box>
      <QuestionAnswerHeader
        questionAnswerId={questionAnswerId}
        onDeleteClick={() => setDeleteConfirmationOpen(true)}
      />

      {fields.map((field) => (
        <Field
          key={`${field.id}::${questionAnswerId}`}
          questionId={questionId}
          questionAnswerId={questionAnswerId}
          fieldId={field.id}
        />
      ))}
      {modifiedQuestionAnswers.ids.includes(questionAnswerId) && (
        <Button fullWidth variant="outlined" onClick={handleSaveClick}>
          {t('saveButton')}
        </Button>
      )}
      <ConfirmationModal
        open={deleteConfirmationOpen}
        titleKey={t('confirmDeleteTitle')}
        textKey={t('confirmDeleteText')}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onClose={() => setDeleteConfirmationOpen(false)}
        onTrue={() => questionAnswers._delete.mutate(questionAnswerId)}
      />
    </Box>
  );
}
