import React from 'react';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { InputProps } from 'src/QuestionnairesV3/types';
import { useFieldOptions } from 'src/QuestionnairesV3/hooks';

export default function RadioSelect(props: Readonly<InputProps>) {
  const { error, value, onChange, field } = props;
  const fieldOptions = useFieldOptions({ field_id: field.id });

  const handleChange = (event: React.ChangeEvent, value: string) =>
    onChange?.(value, event);

  return (
    <FormControl error={!!error}>
      <FormLabel>{field.label}</FormLabel>
      <RadioGroup value={value} onChange={handleChange}>
        {fieldOptions._data?.map((option) => (
          <FormControlLabel
            key={option.id}
            value={option.id}
            control={<Radio />}
            label={option.label}
          />
        ))}
      </RadioGroup>
      {error && <Typography variant="errorHelperText">{error}</Typography>}
    </FormControl>
  );
}
