import { SCALING_FACTOR } from './Plot';

export const displayValueOrFallback = (
  value: number | null | undefined,
  fallback = '-'
) => {
  if (value !== null && value !== undefined) {
    return value.toFixed(2);
  }
  return fallback;
};

export const getBackgroundColor = (value: number | null): string => {
  const startAlpha = 0.02;
  const endAlpha = 0.5;
  if (value === null) return 'rgba(0, 0, 0, 0)';
  const alpha = startAlpha + ((endAlpha - startAlpha) * value) / SCALING_FACTOR;
  return `rgba(233, 188, 70, ${alpha})`;
};
