import React from 'react';
import DOMPurify from 'dompurify';

import { Radio, TableCell, TableRow, Typography } from '@mui/material';
import { UUID } from 'crypto';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import {
  expenditureToFigureType,
  ExpenditureType,
  TaxonomyExpenditureDNSHInSchema,
  TaxonomyExpenditureDNSHOutSchema,
} from '../../../types';
import colors from 'src/theme/colors';

interface Props {
  reportActivityId: UUID;
  type: ExpenditureType;
  objectiveId: UUID;
}

export default function ObjectiveRow(props: Readonly<Props>) {
  const { reportActivityId, type, objectiveId } = props;
  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activityObjective = queries.activityObjectives._data.find(
    (ao) =>
      ao.report_activity_id === reportActivityId &&
      ao.type === expenditureToFigureType(type)
  );
  const dnsh = queries.expenditureDNSH._data.find(
    (t) =>
      t.report_activity_id === reportActivityId &&
      t.expenditure_type === type &&
      t.objective_id === objectiveId
  );

  const initialState: TaxonomyExpenditureDNSHInSchema = {
    expenditure_type: type,
    report_activity_id: reportActivityId,
    objective_id: objectiveId,
    is_compliant_a: false,
    is_compliant_b: false,
    is_compliant_c: false,
  };

  const [form, setForm] = React.useState<
    TaxonomyExpenditureDNSHInSchema | TaxonomyExpenditureDNSHOutSchema
  >(initialState);

  React.useEffect(() => {
    if (dnsh) {
      setForm(dnsh);
    }
  }, [dnsh]);

  const handleChange = (
    isCompliant: keyof TaxonomyExpenditureDNSHInSchema,
    value: boolean
  ) => {
    const newForm = { ...form, [isCompliant]: value };
    if (dnsh) {
      queries.expenditureDNSH.update.mutateAsync(newForm).then(() => {
        reportAnalytics.refetch();
      });
    } else {
      queries.expenditureDNSH.create.mutateAsync(newForm).then(() => {
        reportAnalytics.refetch();
      });
    }
  };

  if (queries.isLoading || !activityObjective) return null;

  const mainCriteria = queries.mainCriterias._data.find(
    (mc) =>
      mc.activity_id === reportActivity.activity_id &&
      mc.objective_id === activityObjective.objective_id
  );
  const dnshCriteria = queries.dnshCriterias._data.find(
    (dc) =>
      dc.main_criteria_id === mainCriteria?.id &&
      dc.objective_id === objectiveId
  );

  if (!dnshCriteria) return null;

  const options: (keyof TaxonomyExpenditureDNSHInSchema)[] = [
    'is_compliant_a',
    'is_compliant_b',
    'is_compliant_c',
  ];

  return (
    <TableRow>
      <TableCell />
      <TableCell>{queries.objectives._dataMap[objectiveId].label}</TableCell>
      <TableCell>
        <Typography
          variant="captionText"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(dnshCriteria.criteria),
          }}
        />
      </TableCell>
      {options.map((isCompliant) => (
        <React.Fragment key={isCompliant}>
          <TableCell
            sx={{
              bgcolor:
                isCompliant === 'is_compliant_b'
                  ? colors.pureWhite
                  : colors.backgroundInput,
            }}
          >
            <Radio
              key={`${dnsh?.id}-${isCompliant}`}
              checked={!!dnsh && !!dnsh[isCompliant]}
              onClick={() => handleChange(isCompliant, true)}
            />
          </TableCell>
          <TableCell
            sx={{
              bgcolor:
                isCompliant === 'is_compliant_b'
                  ? colors.pureWhite
                  : colors.backgroundInput,
            }}
          >
            <Radio
              key={`${dnsh?.id}-non-${isCompliant}`}
              checked={!!dnsh && !dnsh[isCompliant]}
              onClick={() => handleChange(isCompliant, false)}
            />
          </TableCell>
        </React.Fragment>
      ))}
    </TableRow>
  );
}
