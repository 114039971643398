import { Edit } from '@mui/icons-material';
import {
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  ListItemTextProps,
} from '@mui/material';
import React from 'react';
import colors from 'src/theme/colors';

interface Props extends ListItemTextProps {
  listItemIcon?: React.ReactNode;
  actionIcon?: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
}
export default function Step(props: Readonly<Props>) {
  const { listItemIcon, onClick, disabled, ..._props } = props;
  return (
    <ListItem sx={{ p: '24px 8px', borderBottom: '1px solid #e0e0e0' }}>
      {listItemIcon && <ListItemIcon>{listItemIcon}</ListItemIcon>}
      <ListItemText {..._props} />
      <ListItemSecondaryAction>
        <IconButton onClick={onClick} disabled={disabled}>
          {props.actionIcon || <Edit />}
        </IconButton>
      </ListItemSecondaryAction>
    </ListItem>
  );
}
