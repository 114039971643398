import {
  Button,
  Dialog,
  List,
  ListItem,
  Typography,
  Link,
} from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { useCurrentDma, useEsgDmaAdditionalFiles } from '../../hooks';
import { EsgDmaAdditionalFileCategory } from '../../types';

export default function DmaAdditionalFilesButton() {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const { dma } = useCurrentDma();
  const { _data: files } = useEsgDmaAdditionalFiles({
    esg_dma_id: dma?.id,
    category: EsgDmaAdditionalFileCategory.INSTRUCTIONS_STEP5,
  });

  return (
    <>
      <Button
        startIcon={<InsertDriveFileOutlinedIcon />}
        onClick={() => setOpen(true)}
        variant="outlined"
      >
        {t('esg:dma.common.additionalInstructions')}
      </Button>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Typography variant="h6">
          {t('esg:dma.common.additionalInstructions')}
        </Typography>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>
          {files?.map((file) => (
            <ListItem key={file.id} sx={{ display: 'list-item' }}>
              <Link target="_blank" href={file.url}>
                {file.name}
              </Link>
            </ListItem>
          ))}
        </List>
      </Dialog>
    </>
  );
}
