import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { StyledFlex } from 'src/components/StyledFlex';

export const StyledRow = styled(StyledFlex)`
  display: flex;
  align-items: center;
`;

export const StyledSubTopicCell = styled(Box)`
  flex: 8;
  padding: 16px 0 16px 24px;
  background-color: #f8f8f8;
`;

export const StyledCell = styled(StyledFlex)`
  flex: 4;
  padding: 6px 10px;
`;

export const StyledCellSm = styled(StyledFlex)`
  flex: 1;
  padding: 6px 0px;
  max-width: 100%;
`;
