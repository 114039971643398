import React from 'react';
import { useTranslation } from 'react-i18next';
import { TableCell, TableRow, Typography } from '@mui/material';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import { toCurrency, toPercentage } from 'src/Esg/Taxonomy/utils';

export default function SumRows() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.activities.turnover.table',
  });
  const { taxonomyReport, reportAnalytics } = useEsgTaxonomy();

  if (reportAnalytics.isLoading) return null;

  const analytics = reportAnalytics._instance?.turnover;

  return (
    <>
      <TableRow>
        <TableCell>
          <Typography>{t('activitiesNotIncluded')}</Typography>
        </TableCell>
        <TableCell>
          {toCurrency(
            analytics?.non_eligible_value,
            taxonomyReport?.currency_type
          )}
        </TableCell>
        <TableCell>
          {toPercentage(analytics?.non_eligible_percentage)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell>
          <Typography>{t('total')}</Typography>
        </TableCell>
        <TableCell>
          {toCurrency(analytics?.total_value, taxonomyReport?.currency_type)}
        </TableCell>
        <TableCell />
      </TableRow>
      {analytics?.error_values && (
        <TableRow>
          <TableCell colSpan={3}>
            <Typography color="error" variant="body2" textAlign="right">
              {t('error')}
            </Typography>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
