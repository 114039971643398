import React from 'react';
import { Desc, Heading, Title } from './index.styles';
import FormAssumptions from './forms/assumptions';
import ProductCharacteristics from './forms/product-characteristics';
import Materials from './forms/materials';
import { LcaProductMaterial } from 'src/Lca/types';
import { useTranslation } from 'react-i18next';

interface Props {
  values: {
    functional_unit: string;
    data_collection_period: string;
    weight: number | null;
    weight_unit: string;
    dimensions: string;
    dimensions_unit: string;
    volume: number | null;
    volume_unit: string;
    production_process_description: string;
    assumptions_description: string;
    reference_service_life: string;
    cutoff_rules: string;
    additional_information: string;
    materials: Array<LcaProductMaterial>;
  };
  setValues: Function;
}

export default function LcaInfo({ setValues, values }: Props) {
  const { t } = useTranslation('lca', { keyPrefix: 'lcaInformation' });
  return (
    <div>
      <Title>{t('title')}</Title>
      <Desc>{t('desc')}</Desc>
      <Heading>{t('productCharacteristics')}</Heading>
      <ProductCharacteristics values={values} setValues={setValues} />
      <Heading>{t('materialComposition')}</Heading>
      <Materials
        values={values}
        setValues={setValues as (values: any) => void}
      />
      <Heading>{t('assumptions')}</Heading>
      <FormAssumptions values={values} setValues={setValues} />
    </div>
  );
}
