import React from 'react';
import { Table, TableBody } from '@mui/material';

import { useDmaHierarchy, useEsgDmaActions } from '../../hooks';
import Head from './Head';
import TitleRow from './Rows/TitleRow';
import FullScreenPanel from 'src/Esg/components/StyledPanelFullscreen';
import { UUID } from 'crypto';
import { useParams } from 'react-router-dom';
import { useEsgReports } from 'src/Esg/hooks';
import ExportToCsvButton from '../../components/DmaExportButton';

export default function Step2Table({ esg_dma_id }: { esg_dma_id: UUID }) {
  const { stages23Hierarchy } = useDmaHierarchy({ esg_dma_id });

  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { exportStep2ToCsv } = useEsgDmaActions(report?.esg_dma_id);

  return (
    <FullScreenPanel
      customButtonComponent={
        <ExportToCsvButton
          handleExportToCsv={() => exportStep2ToCsv.mutate(null)}
        />
      }
    >
      <Table stickyHeader sx={{ minWidth: '1330px' }}>
        <Head />
        <TableBody>
          {Object.entries(stages23Hierarchy).map(([title, data]) => (
            <TitleRow
              key={title}
              title={title}
              data={data}
              dmaId={esg_dma_id}
            />
          ))}
        </TableBody>
      </Table>
    </FullScreenPanel>
  );
}
