import React from 'react';
import { TableCell, TableRow, Typography } from '@mui/material';
import { UUID } from 'crypto';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import {
  ExpenditureActivityAnalytics,
  ExpenditureAnalytics,
  ExpenditureType,
  TaxonomyExpenditureValueUpdateSchema,
  TaxonomyReportAnalytics,
} from '../../../types';
import NumberField from 'src/components/NumberField';
import SaveRow from 'src/Esg/Taxonomy/components/SaveRow';
import { toPercentage } from 'src/Esg/Taxonomy/utils';

interface Props {
  reportActivityId: UUID;
  type: ExpenditureType;
}

const initialState = {
  value_a: 0,
  value_b: 0,
  value_c: 0,
};

export default function ActivityRow(props: Readonly<Props>) {
  const { reportActivityId, type } = props;
  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();

  const [form, setForm] =
    React.useState<TaxonomyExpenditureValueUpdateSchema>(initialState);

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activity = queries.activities._dataMap[reportActivity?.activity_id];
  const expenditureValue = queries.expenditureValues._data.find(
    (tv) =>
      tv.report_activity_id === reportActivityId && tv.expenditure_type === type
  );

  React.useEffect(() => {
    if (expenditureValue) {
      const { value_a, value_b, value_c } = expenditureValue;
      setForm({ value_a, value_b, value_c });
    }
  }, [expenditureValue]);

  if (queries.isLoading || reportAnalytics.isLoading) return null;

  const handleSave = () =>
    expenditureValue
      ? queries.expenditureValues.update
          .mutateAsync({
            id: expenditureValue.id,
            ...form,
          })
          .then(() => {
            reportAnalytics.refetch();
          })
      : queries.expenditureValues.create
          .mutateAsync({
            ...form,
            expenditure_type: type,
            report_activity_id: reportActivityId,
          })
          .then(() => {
            reportAnalytics.refetch();
          });

  const handleChange = (key: string, value: number | string | null) =>
    setForm((prev) => ({ ...prev, [key]: value }));

  const expenditureAnalytics = reportAnalytics._instance![
    type.toLocaleLowerCase() as keyof TaxonomyReportAnalytics
  ] as ExpenditureAnalytics;
  const analytics = expenditureAnalytics.activities_analytics.find(
    (aa) => aa.report_activity_id === reportActivityId
  );

  const originalValues = expenditureValue
    ? {
        value_a: expenditureValue.value_a,
        value_b: expenditureValue.value_b,
        value_c: expenditureValue.value_c,
      }
    : initialState;

  const isModified = JSON.stringify(originalValues) !== JSON.stringify(form);
  const tableCellsx = isModified ? { borderBottom: 'none' } : {};

  return (
    <>
      <TableRow>
        <TableCell sx={tableCellsx}>
          <Typography>{activity.label}</Typography>
        </TableCell>
        {Object.entries(form).map(([key, value]) => (
          <React.Fragment key={key}>
            <TableCell sx={tableCellsx}>
              <NumberField
                sx={{ minWidth: '100px' }}
                fullWidth
                size="small"
                decimalPlaces={0}
                label={taxonomyReport?.currency_type}
                value={value}
                onChange={(value) => handleChange(key, Number(value))}
              />
            </TableCell>
            <TableCell sx={tableCellsx}>
              {toPercentage(
                analytics?.[
                  `${key}_percentage` as keyof ExpenditureActivityAnalytics
                ] as number
              )}
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
      {isModified && <SaveRow colSpan={7} onSaveClick={handleSave} />}
    </>
  );
}
