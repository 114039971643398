import React from 'react';
import { Table, TableBody, TableContainer, Typography } from '@mui/material';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import Head from './TurnoverTable/Head';
import ActivityRow from './TurnoverTable/ActivityRow';
import { useTranslation } from 'react-i18next';

export default function TurnoverTable() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.dnsh.table' });

  const { queries } = useEsgTaxonomy();
  if (queries.isLoading) return null;

  const reportActivities = queries.reportActivities._data.filter(
    (ra) => ra.turnover_enabled
  );

  return (
    <>
      <Typography>{t('text')}</Typography>
      <br />
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table stickyHeader>
          <Head />
          <TableBody>
            {reportActivities.map((activity) => (
              <ActivityRow key={activity.id} reportActivityId={activity.id} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
