import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material';
import React from 'react';
import { EsgStakeholderDataOutSchema } from 'src/Esg/Dmav2/types';
import Edit from './Edit';
import { useTranslation } from 'react-i18next';
import { useEsgOrganization } from 'src/common/hooks';
import { useParams } from 'react-router-dom';
import { useEsgReports } from 'src/Esg/hooks';
import { useEsgStakeholderData } from 'src/Esg/Dmav2/hooks';
import { Close } from '@mui/icons-material';
import { StyledDialogSubtitle } from '../index.styles';
import HierarchySelector from './HierarchySelector';

interface Props {
  open: boolean;
  onClose: () => void;
  row?: EsgStakeholderDataOutSchema;
}
export default function CustomizationDialog(props: Readonly<Props>) {
  const { row, open, onClose } = props;
  const { t } = useTranslation('esg', {});
  const { t: _t } = useTranslation('esg', {
    keyPrefix: 'dma.selectingStakeholders',
  });

  const organization = useEsgOrganization();
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { update } = useEsgStakeholderData(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  const [currentTab, setCurrentTab] = React.useState<string>('introText');

  const handleTabChange = (_: React.SyntheticEvent, newValue: string) =>
    setCurrentTab(newValue);

  if (!row) return null;

  const defaultOutro = t('stakeholderSurvey.outro.message');
  const defaultIntro = t('stakeholderSurvey.intro.message', {
    organization_name: organization.name,
  });

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      sx={{ minWidth: '400px', minHeight: '400px' }}
    >
      <IconButton
        onClick={onClose}
        sx={{ position: 'absolute', right: '10px', top: '10px' }}
      >
        <Close />
      </IconButton>

      <Tabs value={currentTab} onChange={handleTabChange} sx={{ width: '95%' }}>
        <Tab label={_t('table.columns.introText')} value="introText" />
        <Tab label={_t('table.columns.matters')} value="matters" />
        <Tab label={_t('table.columns.outroText')} value="outroText" />
      </Tabs>

      <Box sx={{ width: '700px', minHeight: '480px' }}>
        {currentTab === 'introText' && (
          <Edit
            title={_t('table.columns.introText')}
            subtitleTranslationKey="esg:dma.selectingStakeholders.table.modals.intro.subtitle"
            value={row.intro_text ?? defaultIntro}
            onChange={(value) =>
              update.mutateAsync({ ...row, intro_text: value }).then(onClose)
            }
          />
        )}
        {currentTab === 'matters' && (
          <Box>
            <StyledDialogSubtitle sx={{ pb: 0 }}>
              {_t('table.modals.matters.subtitle')}
            </StyledDialogSubtitle>
            <DialogContent>
              <HierarchySelector esg_stakeholder_data_id={row.id} />
            </DialogContent>
          </Box>
        )}
        {currentTab === 'outroText' && (
          <Edit
            title={t('dma.selectingStakeholders.table.columns.outroText')}
            subtitleTranslationKey="esg:dma.selectingStakeholders.table.modals.outro.subtitle"
            value={row?.outro_text ?? defaultOutro}
            onChange={(value) =>
              update.mutateAsync({ ...row, outro_text: value }).then(onClose)
            }
          />
        )}
      </Box>
    </Dialog>
  );
}
