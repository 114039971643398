import { Add } from '@mui/icons-material';
import { Button, TableCell, TableRow } from '@mui/material';
import { UUID } from 'crypto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';
import { useEsgSubTopic, useEsgSubSubTopic } from 'src/Esg/Dmav2/hooks';
import colors from '../../../theme/colors';
import AcceptableTextField from 'src/components/AcceptableTextField';

type AddCustomSubtopicRowProps = {
  dmaId: UUID;
  topicId: number;
  subTopicId?: number;
};

export function AddCustomSubtopicRow({
  dmaId,
  topicId,
  subTopicId,
}: AddCustomSubtopicRowProps) {
  const subTopic = useEsgSubTopic({ esg_dma_id: dmaId }, { enabled: false });
  const subSubTopic = useEsgSubSubTopic(
    { esg_dma_id: dmaId },
    { enabled: false }
  );
  const queryClient = useQueryClient();
  const { t } = useTranslation();

  const [visible, setVisible] = React.useState(false);

  const handleSave = async (value: unknown) => {
    if (subTopicId) {
      // if subtopic id is provided, create subsubtopic
      await subSubTopic.create.mutateAsync({
        description: String(value),
        subtopic_id: subTopicId,
        esg_dma_id: dmaId,
      });
    } else {
      // if subtopic id is not provided, create subtopic
      await subTopic.controller.create.mutateAsync({
        description: String(value),
        topic_id: topicId,
        esg_dma_id: dmaId,
      });
    }
    setVisible(false);
    queryClient.invalidateQueries('dmaHierarchy');
  };

  if (!visible)
    // if not visible, show the add button
    return (
      <TableRow>
        <TableCell
          colSpan={10}
          sx={{ pl: '32px', backgroundColor: colors.backgroundGrey }}
        >
          <Button
            size="small"
            variant="secondary"
            startIcon={<Add />}
            onClick={() => setVisible(true)}
          >
            {t('esg:dma.tableV2.addSubtopic')}
          </Button>
        </TableCell>
      </TableRow>
    );

  return (
    <TableRow>
      <TableCell
        sx={{
          pl: '32px',
          backgroundColor: colors.backgroundGrey,
        }}
      >
        <AcceptableTextField
          size="small"
          value=""
          onAccept={handleSave}
          placeholder={t('esg:dma.tableV2.subtopicPlaceholder')}
          onReject={() => setVisible(false)}
          buttonsAlwaysVisible
          inputProps={{ maxlength: 255 }}
        />
      </TableCell>
      <TableCell colSpan={7} />
    </TableRow>
  );
}
