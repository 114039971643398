import { createTheme } from '@mui/material';
import typographies from 'src/theme/typographies';

// eslint-disable-next-line import/prefer-default-export
export const registerTheme = createTheme({
  typography: {
    fontFamily: 'Poppins',
    ...typographies,
  },
  palette: {
    primary: {
      main: '#165D5C',
      contrastText: '#FFF',
    },
    secondary: {
      main: '#FFF',
      contrastText: '#165D5C',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '28px',
          fontSize: '18px',
          fontStyle: 'normal',
          fontWeight: 700,
          lineHeight: '15px',
          textTransform: 'none',
          padding: '16px',
          height: '48px',
          boxShadow: 'none',

          '&:hover': {
            boxShadow: 'none',
            backgroundColor: '#276E6D',
          },
        },
        textSecondary: {
          color: '#38414F',
          border: '1px solid #D9D9D9',
        },
      },
    },

    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          '&.Mui-disabled .MuiOutlinedInput-notchedOutline': {
            '&.Black': {
              borderColor: 'black',
            },
          },
        },
      },
    },
  },
});
