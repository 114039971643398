import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { ExpenditureType } from '../../../types';

interface Props {
  figure: ExpenditureType;
}

export default function Head({ figure }: Readonly<Props>) {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.activities.expenditure.table.headers',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '40%' }}>
          {t('activity')}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '10%' }}>
          {t('a', { figure })}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '10%' }} />
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '10%' }}>
          {t('b', { figure })}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '10%' }} />
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '10%' }}>
          {t('c', { figure })}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '10%' }} />
      </TableRow>
    </TableHead>
  );
}
