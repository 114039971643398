import * as React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface CustomMuiSelectProps {
  label: string;
  options: { value: string; label: string }[];
  value: string;
  onChange: (value: string) => void;
}

const CustomMuiSelect: React.FC<CustomMuiSelectProps> = ({
  label,
  options,
  value,
  onChange,
}) => {
  const labelId = 'custom-select-label';
  const selectId = 'custom-select';

  return (
    <FormControl variant="outlined">
      <InputLabel
        id={labelId}
        sx={{
          color: '#49525F',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '400',
          zIndex: 998,
        }}
      >
        {label}
      </InputLabel>
      <Select
        labelId={labelId}
        id={selectId}
        value={value}
        onChange={(event: SelectChangeEvent<string>) =>
          onChange(event.target.value)
        }
        IconComponent={ArrowDropDownIcon}
        displayEmpty // Ensures the label remains visible even if the selected option has an empty label
        sx={{
          height: '40px',
          color: '#38414F',
          fontSize: '16px',
          fontStyle: 'normal',
          fontWeight: '600',
          letterSpacing: '0.32px',
          borderRadius: '20px',
          '& .MuiSvgIcon-root': {
            fontSize: '2rem',
            color: '#38414F',
          },
        }}
        MenuProps={{
          sx: {
            '.MuiPaper-root': {
              borderRadius: '20px',
            },
            '.MuiList-root': {
              padding: '0',
            },
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={{ borderRadius: '16px', margin: '4px' }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default CustomMuiSelect;
