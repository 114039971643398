import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import Head from './Head';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import ActivityRow from './ActivityRow';
import SumRows from './SumRows';

export default function TurnoverTable() {
  const { taxonomyReport, queries } = useEsgTaxonomy();
  if (!taxonomyReport || queries.isLoading) return null;

  const reportActivities = queries.reportActivities._data.filter(
    (ra) => ra.turnover_enabled
  );

  return (
    <TableContainer sx={{ overflowX: 'auto' }}>
      <Table stickyHeader>
        <Head />
        <TableBody>
          {reportActivities.map((reportActivity) => (
            <ActivityRow
              reportActivityId={reportActivity.id}
              key={reportActivity.id}
            />
          ))}
          <SumRows />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
