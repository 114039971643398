import React from 'react';

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  Typography,
} from '@mui/material';
import { InputProps } from 'src/QuestionnairesV3/types';

export default function SimpleCheckbox(props: Readonly<InputProps>) {
  const { error, field, value, onChange } = props;
  const handleChange = (event: React.ChangeEvent) => {
    const parsedBooleanValue: boolean = !JSON.parse(String(value || false));
    onChange?.(parsedBooleanValue, event);
  };
  return (
    <FormControl error={!!error}>
      <FormControlLabel
        // disabled={!!disabled}
        control={
          <Checkbox
            checked={!!value}
            onChange={handleChange}
            // disabled={disabled}
          />
        }
        label={field.label}
      />
      {error && <Typography variant="errorHelperText">{error}</Typography>}
    </FormControl>
  );
}
