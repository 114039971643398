import React from 'react';
import { useTranslation } from 'react-i18next';

import { ExpenditureType, FigureType } from '../types';
import Turnover from './Turnover';
import ViewContainer from '../components/ViewContainer';
import TaxonomyTabPanel from '../components/TaxonomyTabPanel';
import ExpenditureTable from './components/ExpenditureTable';

export default function DNSH() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.dnsh' });
  const [tab, setTab] = React.useState<FigureType>();

  return (
    <ViewContainer title={t('title')}>
      <TaxonomyTabPanel
        tab={tab}
        onTabChange={setTab}
        turnoverComponent={<Turnover />}
        capexComponent={<ExpenditureTable type={ExpenditureType.Capex} />}
        opexComponent={<ExpenditureTable type={ExpenditureType.Opex} />}
      />
    </ViewContainer>
  );
}
