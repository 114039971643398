import React, { useEffect } from 'react';
import { SubProductModal as Props } from '../index.types';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import Button from 'src/Lca/components/Button';

import { StyledRow } from '../index.styles';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { toast } from 'react-toastify';
import { LcaSubProductInSchema } from '../../index.types';
import SubProductForm from './Form';
import { useLcaSubProducts } from '../../hooks';
import { extractSchemaErrors } from 'src/utils/validation';
import { FormErrors } from './FormValidation';
import { useQueryClient } from 'react-query';
import { useTranslation } from 'react-i18next';

export default function SubProductModal({
  productId,
  open,
  onClose: _onClose,
  id,
}: Props) {
  const { t } = useTranslation('lca', { keyPrefix: 'process' });
  const isNew = !id;
  const subProducts = useLcaSubProducts(
    { product_id: productId },
    { enabled: !!id }
  );
  const subProduct = subProducts._data.find((i) => i.id === id);

  const initialForm = {
    product_id: productId,
    name: '',
    comment: '',
    sub_product_id: undefined,
    stage: undefined,
  };

  const [form, setForm] =
    React.useState<Partial<LcaSubProductInSchema>>(initialForm);
  const [errors, setErrors] = React.useState<FormErrors>({});
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);

  useEffect(() => {
    if (subProduct) {
      setForm(subProduct);
    }
  }, [subProduct]);

  const onClose = (e?: any) => {
    e?.preventDefault();
    setForm(initialForm);
    _onClose();
  };
  const queryClient = useQueryClient();
  const invalidateDiagramQuery = () => {
    queryClient.invalidateQueries({
      queryKey: ['lca-diagram'],
    });
  };

  const handleSubmit = () => {
    const submit = isNew
      ? subProducts.create.mutateAsync
      : subProducts.update.mutateAsync;
    submit(form)
      .then(() => {
        _onClose();
        invalidateDiagramQuery();
      })
      .catch((err: any) => {
        toast.error(t('failedToSave') as string);
        const extractedErrors = extractSchemaErrors(err, form);
        console.log(extractedErrors);
        setErrors(extractedErrors);
      });
  };

  const handleDeleteObject = () => {
    setDeleteConfirmationOpen(false);
    subProducts._delete.mutateAsync(id!).then(() => {
      toast.success(t('subProductRemoved') as string);
      _onClose();
      invalidateDiagramQuery();
    });
  };

  const handleCopyClick = () => {
    if (!subProduct) return;
    subProducts.create
      .mutateAsync({
        product_id: subProduct.product_id,
        name: subProduct.name,
        amount: subProduct.amount,
        comment: subProduct.comment,
        sub_product_id: subProduct.sub_product_id,
        parent_process_id: subProduct.parent_process_id,
        stage: subProduct.stage,
      })
      .then(() => {
        toast.success(t('Successfully copied') as string);
        invalidateDiagramQuery();
      });
  };

  const saveButtonText = isNew ? t('create') : t('saveChanges');

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg">
      <IconButton
        sx={{ position: 'absolute', top: 12, right: 10 }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <DialogTitle>
        <Typography>{t('addSubProduct')}</Typography>
      </DialogTitle>
      <DialogContent sx={{ width: 900 }}>
        <SubProductForm form={form} setForm={setForm} errors={errors} />
        <br />

        {/* ACTION BUTTONS */}

        <StyledRow>
          {isNew && (
            <Button color="default" onClick={onClose} isSecondary>
              {t('cancel')}
            </Button>
          )}

          {!isNew && (
            <StyledRow style={{ padding: 0, justifyContent: 'flex-start' }}>
              <Button
                color="default"
                isSecondary
                onClick={() => setDeleteConfirmationOpen(true)}
              >
                {t('remove')}
              </Button>
              <div style={{ width: 10 }} />
              <Button color="default" onClick={handleCopyClick} isSecondary>
                {t('copy')}
              </Button>
              <div style={{ width: 10 }} />
              <Button color="default" onClick={onClose} isSecondary>
                {t('hide')}
              </Button>
            </StyledRow>
          )}

          <Button color="process" onClick={handleSubmit}>
            {saveButtonText}
          </Button>
        </StyledRow>
      </DialogContent>
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={handleDeleteObject}
        textKey={t('removeConfirmationTextSubproduct')}
        titleKey={t('removeConfirmationTitleSubproduct')}
      />
    </Dialog>
  );
}
