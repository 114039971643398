import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Alert, Button, Divider, TextField, Typography } from '@mui/material';

import { Container } from '../ReportsIndex/index.styles';
import { Box } from './index.styles';
import { IntuitiveCalendarPeriod } from 'src/views/components/IntuitiveCalendar';
import MethodologySelect from './MethodologySelect';
import DmaSelect from './DmaSelect';
import { useEsgReports, useQuestionnaireMethodology } from 'src/Esg/hooks';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { UUID } from 'crypto';
import { useEsgOrganization } from 'src/common/hooks';
import { DmaVersion } from 'src/Esg/Dmav2/types';
import { isValidUUID } from 'src/utils';
import PageLoading from 'src/components/PageLoading';

export default function CreateESGReport() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const organization = useEsgOrganization();
  const { _data: reports, create } = useEsgReports({
    esg_organization_id: organization.id,
  });
  const [yearConfirmationOpen, setYearConfirmationOpen] = useState(false);
  const [name, setName] = useState('');
  const [esgDmaIdOrVersion, setEsgDmaIdOrVersion] = useState<
    UUID | null | DmaVersion
  >(DmaVersion.V1);
  const [date, setDate] = useState<Date | null>(null);
  const period = String(date?.getFullYear());

  const { _data: methodologies, _dataMap: methodologiesMap } =
    useQuestionnaireMethodology();

  const [questionnaireMethodologyId, setQuestionnaireMethodologyId] =
    useState<UUID>(methodologies[0]?.id);

  if (questionnaireMethodologyId === undefined) return <PageLoading />;

  const selectedMethodology = methodologiesMap[questionnaireMethodologyId];

  const yearMatchingReports = reports.filter(
    (report) => report.period === String(date?.getFullYear())
  );
  const matchingReport = yearMatchingReports.find(
    (report) =>
      report.period === String(date?.getFullYear()) &&
      report.questionnaire_methodology_id === questionnaireMethodologyId
  );

  const esgDmaVersion = Number(esgDmaIdOrVersion) || null;
  const esgDmaId = isValidUUID(String(esgDmaIdOrVersion))
    ? (esgDmaIdOrVersion as UUID)
    : null;

  const _create = () =>
    create
      .mutateAsync({
        name,
        period,
        esg_dma_id: esgDmaId,
        esg_dma_version: esgDmaVersion,
        questionnaire_methodology_id: questionnaireMethodologyId,
        esg_organization_id: organization.id,
      })
      .then((data) => {
        setYearConfirmationOpen(false);
        navigate(`/esg/reports/${data.id}/indicators`);
      });

  const handleCreateClick = () =>
    yearMatchingReports.length > 0 ? setYearConfirmationOpen(true) : _create();

  const isCreateDisabled =
    !!matchingReport || !name || !date || !questionnaireMethodologyId;

  return (
    <Container>
      <Typography variant="h1" sx={{ mb: '20px' }}>
        {t('esg:new-report')}
      </Typography>
      <Box>
        <TextField
          fullWidth
          label={t('esg:name-report')}
          value={name}
          onChange={(event) => setName(event.target.value)}
        />
        <br />
        <br />
        <MethodologySelect
          value={questionnaireMethodologyId}
          onChange={(event) =>
            setQuestionnaireMethodologyId(event.target.value as UUID)
          }
        />
        <Divider sx={{ m: '20px 0px' }} />
        <DmaSelect
          value={esgDmaIdOrVersion}
          onDmaChange={setEsgDmaIdOrVersion}
        />
      </Box>
      {matchingReport && (
        <Alert severity="warning" sx={{ m: '20px 0px' }}>
          {t('esg:createReport.alerts.alreadyExists', {
            year: date?.getFullYear(),
            methodology: selectedMethodology?.description,
          })}
        </Alert>
      )}
      <IntuitiveCalendarPeriod
        reportingPeriod="Y"
        selectedDate={date}
        setSelectedDate={(selDate: Date) => setDate(selDate)}
      />

      <Button
        sx={{ float: 'right', mt: '20px' }}
        size="large"
        disabled={isCreateDisabled}
        onClick={handleCreateClick}
      >
        {t('esg:create')}
      </Button>

      <ConfirmationModal
        open={yearConfirmationOpen}
        onClose={() => setYearConfirmationOpen(false)}
        onFalse={() => setYearConfirmationOpen(false)}
        textKey="esg:modal.1.subtitle"
        titleKey="esg:modal.1.title"
        falseButtonTextKey="esg:common.cancel"
        trueButtonTextKey="esg:modal.1.buttons.create"
        onTrue={_create}
      />
    </Container>
  );
}
