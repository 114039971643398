import React from 'react';
import { Scatter } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ChartData, ScatterDataPoint } from 'chart.js';
import { useParams } from 'react-router-dom';
import { Avatar, Box, Typography } from '@mui/material';

import {
  useEsgStakeholdersAnswersAnalytics,
  useEsgTopic,
} from 'src/Esg/Dmav2/hooks';
import { StyledPanel } from 'src/Esg/styles';
import { StyledText, StyledTextLight } from '../styles';
import { StyledFlex } from 'src/components/StyledFlex';
import ChartAxesLegend from './ChartAxisLegend';
import { adjustDataPoints, getDataPoint, scatterOptions } from './utils';
import { useEsgReports } from 'src/Esg/hooks';

interface ScatterChartProps {
  esg_stakeholder_id?: number;
}

export default function ScatterChart({
  esg_stakeholder_id,
}: ScatterChartProps) {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dma.stakeholderAnalytics.dashboard',
  });

  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { analytics } = useEsgStakeholdersAnswersAnalytics(
    report?.esg_dma_id,
    esg_stakeholder_id
  );
  const { topicsMap } = useEsgTopic();

  const chartData: ChartData<'scatter', ScatterDataPoint[], string> = {
    datasets: adjustDataPoints(analytics.topic_summary).map((item, index) => {
      const topic = topicsMap[item.esg_dma_topic_id];
      if (!topic) return { label: '', data: [] };
      return {
        ...getDataPoint(topic, analytics.topic_summary[index], item, topic.key),
        clip: false, // make datapoint visible on edges
      };
    }),
  };

  return (
    <StyledPanel sx={{ minWidth: '860px' }}>
      <StyledText>{t('scatterPlot.title')}</StyledText>
      <StyledTextLight>{t('scatterPlot.subtitle')}</StyledTextLight>
      <StyledFlex
        sx={{
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          mt: '26px',
          gap: '46px',
        }}
      >
        <Box sx={{ position: 'relative', width: '500px', height: '500px' }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              zIndex: 0,
            }}
          >
            <ChartAxesLegend />
          </Box>
          {/* Render the Scatter chart in a container with a higher z-index */}
          <Box
            sx={{
              position: 'relative',
              width: '100%',
              height: '100%',
              zIndex: 1,
            }}
          >
            <Scatter data={chartData} options={scatterOptions} />
          </Box>
        </Box>

        <Box>
          {analytics.topic_summary.map((surveySummary) => {
            const topic = topicsMap[surveySummary.esg_dma_topic_id];
            if (topic === undefined) return null;
            return (
              <StyledFlex
                key={topic.id}
                sx={{ mb: '10px', justifyContent: 'flex-start' }}
              >
                <Avatar sx={{ bgcolor: topic.color || '#D9D9D9' }}>
                  <Typography variant="h6">{topic.key}</Typography>
                </Avatar>
                <Typography variant="h6" sx={{ ml: '10px' }}>
                  {topic.name}
                </Typography>
              </StyledFlex>
            );
          })}
        </Box>
      </StyledFlex>
    </StyledPanel>
  );
}
