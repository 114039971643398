import React from 'react';
import { useTranslation } from 'react-i18next';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { IconButton, Typography } from '@mui/material';
import { KeyboardArrowRight, KeyboardArrowDown } from '@mui/icons-material';

import { StyledFlex } from 'src/components/StyledFlex';

import { EsgDmaMatrixDatapointSchema } from '../types';

import {
  TableContainer,
  MultiRowHeader,
  HeaderCell,
  GroupRow,
  DataRow,
  Cell,
} from './styles';
import colors from 'src/theme/colors';
import { displayValueOrFallback, getBackgroundColor } from './helpers';

interface Props {
  data: EsgDmaMatrixDatapointSchema[];
  onToggleDataPointVisibility: (id: number) => void;
  hiddenDataPoints: number[];
}

function ScoreCell({ score }: { score: number | null }) {
  return (
    <Cell
      style={{
        fontSize: '18px',
        fontWeight: 400,
        backgroundColor: getBackgroundColor(score),
      }}
    >
      {displayValueOrFallback(score)}
    </Cell>
  );
}

export default function DmaMatrixTable(props: Props) {
  const { t } = useTranslation();
  const { data, hiddenDataPoints, onToggleDataPointVisibility } = props;

  // 1) Group data by `esg_topic_key`.
  const groupedData = React.useMemo(() => {
    const map: Record<string, EsgDmaMatrixDatapointSchema[]> = {};
    data.forEach((item) => {
      if (!map[item.esg_topic_key]) {
        map[item.esg_topic_key] = [];
      }
      map[item.esg_topic_key].push(item);
    });
    return Object.entries(map).map(([groupKey, items]) => ({
      groupKey,
      items,
    }));
  }, [data]);

  //Track collapsed groups.
  const [collapsedGroups, setCollapsedGroups] = React.useState<
    Record<string, boolean>
  >({});

  const handleToggleCollapse = React.useCallback((groupKey: string) => {
    setCollapsedGroups((prev) => ({
      ...prev,
      [groupKey]: !prev[groupKey],
    }));
  }, []);

  // Group checkbox for show/hide all items.
  const handleToggleGroupVisibility = React.useCallback(
    (groupKey: string, items: EsgDmaMatrixDatapointSchema[]) => {
      const allHidden = items.every((m) => hiddenDataPoints.includes(m.id));
      items.forEach((m) => {
        const isHidden = hiddenDataPoints.includes(m.id);
        if (allHidden && isHidden) {
          // unhide
          onToggleDataPointVisibility(m.id);
        } else if (!allHidden && !isHidden) {
          // hide
          onToggleDataPointVisibility(m.id);
        }
      });
    },
    [hiddenDataPoints, onToggleDataPointVisibility]
  );

  return (
    <TableContainer>
      <MultiRowHeader>
        <HeaderCell
          style={{
            gridRow: '1 / 3',
            gridColumn: '1 / 2',
          }}
        >
          {t('esg:dmaGrid.symbol')}
        </HeaderCell>
        <HeaderCell
          style={{
            gridRow: '1 / 3',
            gridColumn: '2 / 3',
          }}
        >
          {t('esg:dmaGrid.matter')}
        </HeaderCell>
        <HeaderCell
          style={{
            gridRow: '1 / 2',
            gridColumn: '3 / 5',
          }}
        >
          {t('esg:dmaGrid.materiality')}
        </HeaderCell>
        <HeaderCell
          style={{
            gridRow: '1 / 3',
            gridColumn: '5 / 6',
          }}
        >
          {t('esg:dmaGrid.impact')}
        </HeaderCell>
        <HeaderCell
          style={{
            gridRow: '1 / 3',
            gridColumn: '6 / 7',
          }}
        >
          {t('esg:dmaGrid.showOnGraph')}
        </HeaderCell>

        <HeaderCell
          style={{
            gridRow: '2 / 3',
            gridColumn: '3 / 4',
          }}
        >
          {t('esg:dmaGrid.impact')}
        </HeaderCell>
        <HeaderCell
          style={{
            gridRow: '2 / 3',
            gridColumn: '4 / 5',
          }}
        >
          {t('esg:dmaGrid.financial')}
        </HeaderCell>
      </MultiRowHeader>

      {groupedData.map(({ groupKey, items }) => {
        // If all items in a group are hidden => checkbox unchecked
        const allHidden = items.every((m) => hiddenDataPoints.includes(m.id));
        const collapsed = collapsedGroups[groupKey] ?? false;

        return (
          <React.Fragment key={groupKey}>
            {/* GROUP ROW */}
            <GroupRow>
              {/* Span columns 1-5 for group label & collapse arrow */}
              <Cell
                style={{
                  gridColumn: '1 / span 5',
                  justifyContent: 'flex-start',
                }}
              >
                <button
                  type="button"
                  style={{
                    border: 'none',
                    background: 'transparent',
                    cursor: 'pointer',
                    margin: '12px 8px 8px 8px',
                  }}
                  onClick={() => handleToggleCollapse(groupKey)}
                >
                  {collapsed ? <KeyboardArrowRight /> : <KeyboardArrowDown />}
                </button>
                <Typography variant="h6">{groupKey}</Typography>
              </Cell>

              {/* Group checkbox (col 6) */}
              <Cell>
                <IconButton
                  type="button"
                  onClick={() => handleToggleGroupVisibility(groupKey, items)}
                  sx={{ color: colors.esgDark }}
                >
                  {allHidden ? <CheckBoxOutlineBlankIcon /> : <CheckBoxIcon />}
                </IconButton>
              </Cell>
            </GroupRow>

            {/* If not collapsed, render each matter in a data row */}
            {!collapsed &&
              items.map((matter) => (
                <DataRow key={matter.id}>
                  {/*  Symbol (circle) */}
                  <Cell>
                    <StyledFlex
                      sx={{
                        width: '32px',
                        height: '32px',
                        backgroundColor: matter.esg_topic_color,
                        borderRadius: '50%',
                        justifyContent: 'center',
                      }}
                    >
                      {matter.id}
                    </StyledFlex>
                  </Cell>

                  {/*  Matter title */}
                  <Cell style={{ justifyContent: 'flex-start' }}>
                    {matter.esg_topic_title}
                  </Cell>

                  {/*  Score: Wpływ (nonfinancial) */}
                  <ScoreCell
                    score={matter.average_score?.nonfinancial ?? null}
                  />

                  {/*  Score: Finansowa (financial) */}
                  <ScoreCell score={matter.average_score?.financial ?? null} />

                  {/*  Impact level */}
                  <Cell
                    style={{
                      backgroundColor: colors.backgroundGrey,
                      fontSize: '12px',
                    }}
                  >
                    {t(`esg:dmaGrid.impactLevel.${matter.impact_level}`)}
                  </Cell>

                  {/*  Show/Hide checkbox */}
                  <Cell>
                    <IconButton
                      type="button"
                      sx={{ color: colors.esgDark }}
                      onClick={() => onToggleDataPointVisibility(matter.id)}
                    >
                      {hiddenDataPoints.includes(matter.id) ? (
                        <CheckBoxOutlineBlankIcon />
                      ) : (
                        <CheckBoxIcon />
                      )}
                    </IconButton>
                  </Cell>
                </DataRow>
              ))}
          </React.Fragment>
        );
      })}
    </TableContainer>
  );
}
