import React from 'react';
import { Radio, TableCell, TableRow, Typography } from '@mui/material';
import { UUID } from 'crypto';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import {
  ExpenditureActivityAnalytics,
  ExpenditureAnalytics,
  expenditureToFigureType,
  ExpenditureType,
  TaxonomyExpenditureDNSHInSchema,
  TaxonomyReportAnalytics,
} from '../../../types';
import ObjectiveRow from './ObjectiveRow';
import { useTranslation } from 'react-i18next';
import colors from 'src/theme/colors';
import { useMutation } from 'react-query';
import { client } from 'src/utils/api-client';

interface Props {
  reportActivityId: UUID;
  type: ExpenditureType;
}

export default function ActivityRow(props: Readonly<Props>) {
  const { reportActivityId, type } = props;
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.dnsh.table',
  });

  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activity = queries.activities._dataMap[reportActivity?.activity_id];
  const activityObjective = queries.activityObjectives._data.find(
    (ao) =>
      ao.report_activity_id === reportActivityId &&
      ao.type === expenditureToFigureType(type)
  );

  const batchUpdateExpenditureDNSH = useMutation({
    mutationFn: (data: {
      is_compliant_a?: boolean;
      is_compliant_b?: boolean;
      is_compliant_c?: boolean;
    }) =>
      client.post(`/web/taxonomy/dnsh-batch/expenditure`, {
        report_activity_id: reportActivityId,
        expenditure_type: type,
        is_compliant_a: data.is_compliant_a,
        is_compliant_b: data.is_compliant_b,
        is_compliant_c: data.is_compliant_c,
      }),
    onSuccess: () => {
      queries.expenditureDNSH.refetch();
      reportAnalytics.refetch();
    },
  });

  if (queries.isLoading || reportAnalytics.isLoading) return null;

  const expenditureAnalytics = reportAnalytics._instance![
    type.toLocaleLowerCase() as keyof TaxonomyReportAnalytics
  ] as ExpenditureAnalytics;
  const analytics = expenditureAnalytics.activities_analytics.find(
    (aa) => aa.report_activity_id === reportActivityId
  );

  const options: (keyof TaxonomyExpenditureDNSHInSchema)[] = [
    'is_compliant_a',
    'is_compliant_b',
    'is_compliant_c',
  ];

  return (
    <>
      <TableRow>
        <TableCell colSpan={3}>
          <Typography>{activity.label}</Typography>
        </TableCell>

        {!activityObjective && (
          <>
            <TableCell colSpan={2} sx={{ bgcolor: colors.backgroundInput }} />
            <TableCell colSpan={2} />
            <TableCell colSpan={2} sx={{ bgcolor: colors.backgroundInput }} />
          </>
        )}
        {activityObjective &&
          options.map((isCompliant) => (
            <React.Fragment key={isCompliant}>
              <TableCell
                sx={{
                  bgcolor:
                    isCompliant === 'is_compliant_b'
                      ? colors.pureWhite
                      : colors.backgroundInput,
                }}
              >
                <Radio
                  key={`${reportActivityId}-${isCompliant}-compliant`}
                  checked={
                    analytics?.[
                      `${isCompliant}_dnsh` as keyof ExpenditureActivityAnalytics
                    ] as boolean
                  }
                  onClick={() =>
                    batchUpdateExpenditureDNSH.mutate({ [isCompliant]: true })
                  }
                />
              </TableCell>
              <TableCell
                sx={{
                  bgcolor:
                    isCompliant === 'is_compliant_b'
                      ? colors.pureWhite
                      : colors.backgroundInput,
                }}
              >
                <Radio
                  key={`${reportActivityId}-${isCompliant}-non-compliant`}
                  checked={
                    !analytics?.[
                      `${isCompliant}_dnsh` as keyof ExpenditureActivityAnalytics
                    ] as boolean
                  }
                  onClick={() =>
                    batchUpdateExpenditureDNSH.mutate({ [isCompliant]: false })
                  }
                />
              </TableCell>
            </React.Fragment>
          ))}
      </TableRow>
      {!activityObjective && (
        <TableRow>
          <TableCell />
          <TableCell colSpan={2}>
            <Typography fontStyle="italic">{t('objectiveRequired')}</Typography>
          </TableCell>
          <TableCell colSpan={2} sx={{ bgcolor: colors.backgroundInput }} />
          <TableCell colSpan={2} />
          <TableCell colSpan={2} sx={{ bgcolor: colors.backgroundInput }} />
        </TableRow>
      )}
      {activityObjective &&
        queries.objectives._data.map((o) => (
          <ObjectiveRow
            key={o.id}
            reportActivityId={reportActivityId}
            type={type}
            objectiveId={o.id}
          />
        ))}
    </>
  );
}
