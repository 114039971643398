/* eslint-disable no-unused-vars */
import { UUID } from 'crypto';
import { TokenPayloadBase } from 'src/utils/jwt';

export enum ExpenditureType {
  Capex = 'CAPEX',
  Opex = 'OPEX',
}

export enum ExpenditureContributionValueType {
  A = 'a',
  B = 'b',
  C = 'c',
}

export enum FigureType {
  Turnover = 'TURNOVER',
  Capex = 'CAPEX',
  Opex = 'OPEX',
}

export const expenditureToFigureType = (expenditureType: ExpenditureType) => {
  if (expenditureType === ExpenditureType.Capex) {
    return FigureType.Capex;
  } else {
    return FigureType.Opex;
  }
};

export enum CurrencyType {
  ARS = 'ARS',
  // BGN = 'BGN',
  CZK = 'CZK',
  DKK = 'DKK',
  EUR = 'EUR',
  GBP = 'GBP',
  HUF = 'HUF',
  PLN = 'PLN',
  RON = 'RON',
  RUB = 'RUB',
  UAH = 'UAH',
  USD = 'USD',
  MXN = 'MXN',
  COP = 'COP',
  SEK = 'SEK',
}

export interface NaceCode {
  code: string;
  description: string;
}

export interface TaxonomySectorOutSchema {
  id: UUID;
  name: string;
  label: string;
  color: string;
}

export interface TaxonomyActivityOutSchema {
  id: UUID;
  name: string;
  label: string;
  description: string;
  sector_id: UUID;
  nace_codes: NaceCode[];
}

export interface TaxonomyObjectiveOutSchema {
  id: UUID;
  name: string;
  label: string;
}

export interface TaxonomyMainCriteriaOutSchema {
  id: UUID;
  activity_id: UUID;
  objective_id: UUID;
  criteria: string;
  description: string;
}

export interface TaxonomyDNSHCriteriaOutSchema {
  id: UUID;
  main_criteria_id: UUID;
  objective_id: UUID;
  criteria: string;
}

export interface TaxonomyReportInSchema {
  name: string;
  turnover_enabled: boolean;
  capex_enabled: boolean;
  opex_enabled: boolean;
  currency_type: CurrencyType;
  // this is not a part of the model,
  // but since we hane no taxonomy report view (for crud actions)
  // we need to pass esg_report_id optionally to connect the esg_report to newly created taxonomy instance
  // will be removed in the future (hopefully)
  esg_report_id?: number;
}

export interface TaxonomyReportOutSchema extends TaxonomyReportInSchema {
  id: UUID;
}

export interface TaxonomyReportSectorInSchema {
  report_id: UUID;
  sector_id: UUID;
}

export interface TaxonomyReportSectorOutSchema
  extends TaxonomyReportSectorInSchema {
  id: UUID;
}

export interface TaxonomyReportActivityInSchema {
  report_id: UUID;
  activity_id: UUID;
  turnover_enabled: boolean;
  capex_enabled: boolean;
  opex_enabled: boolean;
}

export interface TaxonomyReportActivityOutSchema
  extends TaxonomyReportActivityInSchema {
  id: UUID;
}

export interface TaxonomyReportTotalValueInSchema {
  report_id: UUID;
  type: FigureType;
  value: number;
}

export interface TaxonomyReportTotalValueOutSchema
  extends TaxonomyReportTotalValueInSchema {
  id: UUID;
}

export interface TaxonomyReportActivityResourceFilterSchema {
  report_activity_id: UUID;
  report_activity__report_id: UUID;
}

export interface TaxonomyTurnoverValueInSchema {
  value: number;
  report_activity_id: UUID;
}

export interface TaxonomyTurnoverValueOutSchema
  extends TaxonomyTurnoverValueInSchema {
  id: UUID;
}

export interface TaxonomyExpenditureValueUpdateSchema {
  value_a: number;
  value_b: number;
  value_c: number;
}
export interface TaxonomyExpenditureValueInSchema
  extends TaxonomyExpenditureValueUpdateSchema {
  expenditure_type: ExpenditureType;
  report_activity_id: UUID;
}

export interface TaxonomyExpenditureValueOutSchema
  extends TaxonomyExpenditureValueInSchema {
  id: UUID;
}

export interface TaxonomyTurnoverContributionInSchema {
  contribution: number;
  report_activity_id: UUID;
}

export interface TaxonomyTurnoverContributionOutSchema
  extends TaxonomyTurnoverContributionInSchema {
  id: UUID;
}

export interface TaxonomyExpenditureContributionUpdateSchema {
  contribution_a: number;
  contribution_b: number;
  contribution_c: number;
}

export interface TaxonomyExpenditureContributionInSchema
  extends TaxonomyExpenditureContributionUpdateSchema {
  expenditure_type: ExpenditureType;
  report_activity_id: UUID;
}

export interface TaxonomyExpenditureContributionOutSchema
  extends TaxonomyExpenditureContributionInSchema {
  id: UUID;
}

export interface TaxonomyTurnoverDNSHInSchema {
  is_compliant: boolean;
  report_activity_id: UUID;
  objective_id: UUID;
}

export interface TaxonomyTurnoverDNSHOutSchema
  extends TaxonomyTurnoverDNSHInSchema {
  id: UUID;
}

export interface TaxonomyExpenditureDNSHInSchema {
  is_compliant_a: boolean;
  is_compliant_b: boolean;
  is_compliant_c: boolean;
  expenditure_type: ExpenditureType;
  report_activity_id: UUID;
  objective_id: UUID;
}

export interface TaxonomyExpenditureDNSHOutSchema
  extends TaxonomyExpenditureDNSHInSchema {
  id: UUID;
}

export interface TaxonomyQv3Token extends TokenPayloadBase {
  data: {
    questionnaire_answer_id: UUID;
  };
}

export interface TaxonomyReportActivityObjectiveUpdateSchema {
  objective_id: UUID;
}

export interface TaxonomyReportActivityObjectiveInSchema
  extends TaxonomyReportActivityObjectiveUpdateSchema {
  report_activity_id: UUID;
  type: FigureType;
}

export interface TaxonomyReportActivityObjectiveOutSchema
  extends TaxonomyReportActivityObjectiveInSchema {
  id: UUID;
}

export interface TaxonomyReportActivityObjectiveFilterSchema {
  report_activity_id?: UUID;
  report_activity__report_id?: UUID;
  type?: FigureType;
  objective_id?: UUID;
}

export interface TurnoverActivityAnalytics {
  report_activity_id: UUID;
  value_percentage: number;
  contribution_aligned: number;
  is_dnsh_compliant: boolean;
}

export interface ExpenditureActivityAnalytics {
  report_activity_id: UUID;
  value_a_percentage: number;
  value_b_percentage: number;
  value_c_percentage: number;
  contribution_a_aligned: number;
  contribution_b_aligned: number;
  contribution_c_aligned: number;
  is_dnsh_compliant_a: boolean;
  is_dnsh_compliant_b: boolean;
  is_dnsh_compliant_c: boolean;
}

export interface FigureAnalytics {
  total_value: number;
  non_eligible_value: number;
  not_sustainable_value: number;
  sustainable_value: number;
  aligned_contribution: number;
  non_eligible_percentage: number;
  not_sustainable_percentage: number;
  sustainable_percentage: number;
  error_values: boolean;
  error_objective_missing: boolean;
}

export interface TurnoverAnalytics extends FigureAnalytics {
  activities_analytics: TurnoverActivityAnalytics[];
}

export interface ExpenditureAnalytics extends FigureAnalytics {
  activities_analytics: ExpenditureActivityAnalytics[];
}

export interface TaxonomyReportAnalytics {
  id: UUID;
  turnover?: TurnoverAnalytics;
  capex?: ExpenditureAnalytics;
  opex?: ExpenditureAnalytics;
}
