import { Typography } from '@mui/material';
import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { useQuestionTable } from '../../hooks';
import { UUID } from 'crypto';
import FullScreen from './components/FullScreen';
import QuestionTableInColumns from './Layouts/InColumns';
import FullScreenButton from './components/FullScreenButton';
import QuestionTableLayoutMenu from './components/LayoutMenu';
import { QuestionTableLayoutSchema } from 'src/QuestionnairesV3/types';
import QuestionTableInRows from './Layouts/InRows';

interface Props {
  questionId: UUID;
}

export default function QuestionTable(props: Readonly<Props>) {
  const { questionId } = props;
  const { question, queries } = useQuestionTable(questionId);
  const { _instance: questionTable } = queries.questionTables;

  const [fullScreen, setFullScreen] = React.useState(false);
  const [localLayout, setLocalLayout] =
    React.useState<QuestionTableLayoutSchema | null>(null);

  if (!questionTable || !question) return null;

  const _layout = localLayout || questionTable.layout;

  return (
    <StyledPanel m="24px 0px">
      <FullScreenButton onClick={() => setFullScreen(true)} />
      <QuestionTableLayoutMenu
        layout={_layout}
        onClick={(layout) => setLocalLayout(layout)}
      />

      <Typography variant="h5" fontWeight={700}>
        {question.title}
      </Typography>

      <Typography sx={{ m: '10px 0px' }} variant="body1">
        {question.subtitle}
      </Typography>

      <FullScreen open={fullScreen} onClose={() => setFullScreen(false)}>
        {_layout === QuestionTableLayoutSchema.IN_COLUMNS && (
          <QuestionTableInColumns questionId={questionId} />
        )}
        {_layout === QuestionTableLayoutSchema.IN_ROWS && (
          <QuestionTableInRows questionId={questionId} />
        )}
      </FullScreen>
    </StyledPanel>
  );
}
