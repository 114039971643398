import React from 'react';
import { StyledSelect } from '../Modals/index.styles';
import { useQuery } from 'react-query';
import { MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { client } from 'src/utils/api-client';
import { useAppDispatch, useAppSelector } from 'src/redux-file/hooks';
import { LcaAreaOfImpactSchema } from 'src/Lca/types';
import { setSelectedAreaOfImpact } from 'src/redux-file/lca/slice';
import { useTranslation } from 'react-i18next';
import { LcaManagementTab } from '../index.types';
import { StyledPanel } from 'src/Lca/styles';
import { StyledFlex } from 'src/components/StyledFlex';

type Props = {
  productId: string | number;
  tab: LcaManagementTab;
  // eslint-disable-next-line no-unused-vars
  setTab: (tab: LcaManagementTab) => void;
};

export default function FilterPanel({
  // eslint-disable-next-line no-unused-vars
  productId,
  tab,
  setTab,
}: Props) {
  const { selectedAreaOfImpact } = useAppSelector((state) => state.lcaDiagram);
  const dispatch = useAppDispatch();
  const { t } = useTranslation('lca');

  const areasQuery = useQuery(
    'lca-areas',
    () =>
      client
        .get<LcaAreaOfImpactSchema[]>('/web/lca/areas-of-impact')
        .then((response) => response.data),
    {
      onSuccess: (data) => {
        if (!selectedAreaOfImpact) {
          const defaultChoice = data.find((area) =>
            area.name.includes('climate change')
          );
          dispatch(setSelectedAreaOfImpact(defaultChoice || data[0]));
        }
      },
    }
  );

  const areas = areasQuery.data || [];

  const selectAreaOfImpact = (event: React.ChangeEvent<{ value: number }>) => {
    const selectedArea = areas.find((area) => area.id === event.target.value);
    if (selectedArea) {
      dispatch(setSelectedAreaOfImpact(selectedArea));
    }
  };

  const handleSetTab = (newTab: LcaManagementTab) => {
    setTab(newTab);
  };

  return (
    <StyledFlex>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <StyledPanel sx={{ mr: '20px' }}>
          <StyledSelect
            value="filter-by-stage"
            style={{
              borderRadius: 25,
              width: 265,
              fontFamily: 'Poppins',
              paddingLeft: 10,
            }}
            disabled
          >
            <option value="filter-by-stage">
              {t('diagram.filterByStage')}
            </option>
          </StyledSelect>
        </StyledPanel>
        <StyledPanel>
          <StyledSelect
            value={selectedAreaOfImpact?.id ?? ''}
            onChange={selectAreaOfImpact}
            style={{
              borderRadius: 25,
              width: 450,
              fontFamily: 'Poppins',
              paddingLeft: 10,
            }}
            disabled={areas.length === 0}
          >
            {areas.map((area) => (
              <MenuItem key={area.id} value={area.id}>
                {area.name} ({area.unit_name})
              </MenuItem>
            ))}
          </StyledSelect>
        </StyledPanel>
      </StyledFlex>
      <StyledPanel style={{ backgroundColor: 'transparent' }}>
        <ToggleButtonGroup value={tab} exclusive fullWidth>
          {Object.values(LcaManagementTab).map((_tab) => (
            <ToggleButton
              key={_tab}
              value={_tab}
              onClick={() => handleSetTab(_tab)}
              disabled={areas.length === 0}
            >
              {t(`diagram.management.tabs.${_tab}`)}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </StyledPanel>
    </StyledFlex>
  );
}
