import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { MainMetricsContainer } from './styles';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from 'chart.js';
import BarChart from './BarChart';
import StakeholdersImpactTable from './StakeholdersImpactTable';
import ScatterChart from './AnswersMatrix';
import { useParams } from 'react-router-dom';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';
import { dataLabelPlugin } from '../../../utils';
import { useEsgStakeholdersAnswersAnalytics } from 'src/Esg/Dmav2/hooks';
import SummaryTable from './SummaryTable';
import { useEsgReports } from 'src/Esg/hooks';
import CustomMuiSelect from 'src/components/CustomMuiSelect';
import { StyledPanel } from 'src/components/StyledPanel';
import { SurveyAnswerSummary } from 'src/Esg/Dma/Stakeholders/StakeholdersAnalytics/Dashboard/index.types';
import ProgressBar from 'src/components/ProgressBar';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  dataLabelPlugin
);

export default function Dashboard() {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:dma.stakeholderAnalytics.dashboard',
  });
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });

  const [selectedStakeholderTypeId, setSelectedStakeholderTypeId] = useState<
    number | undefined
  >(undefined);

  const { analytics, isLoading, isError } = useEsgStakeholdersAnswersAnalytics(
    report?.esg_dma_id,
    selectedStakeholderTypeId
  );

  if (isError) return <SomethingWentWrong />;
  if (isLoading) return <PageLoading />;

  const stakeholderOptions = analytics.surveyed_stakeholder_types.map(
    (type) => ({
      label: type.name,
      value: type.id.toString(),
    })
  );

  const handleStakeholderChange = (value: string) => {
    if (value === '') {
      setSelectedStakeholderTypeId(undefined);
    } else {
      setSelectedStakeholderTypeId(Number(value));
    }
  };

  return (
    <div style={{ fontStyle: 'Poppins !important' }}>
      <Typography variant="h1">{t('mainMetrics')}</Typography>
      <br />
      <br />
      {/* Dropdown for selecting stakeholder type */}
      {analytics.surveyed_stakeholder_types &&
        analytics.surveyed_stakeholder_types.length > 0 && (
          <CustomMuiSelect
            label=""
            options={[
              { label: t('allStakeholdersTypes'), value: '' },
              ...stakeholderOptions,
            ]}
            value={
              selectedStakeholderTypeId ? String(selectedStakeholderTypeId) : ''
            }
            onChange={(newValue) => {
              handleStakeholderChange(newValue);
            }}
          />
        )}
      <br />
      <br />
      {selectedStakeholderTypeId === undefined && (
        <MainMetricsContainer>
          <StyledPanel>
            <Typography variant="h2">{t('stakeholderTypeAnswers')}</Typography>
            <br />
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1">
                      {t('stakeholderType')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">
                      {t('percentageOfReceivedAnswers')}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1">
                      {t('numberOfReceivedAnswers')}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {analytics.survey_answers_summary.map(
                  (row: SurveyAnswerSummary) => (
                    <TableRow key={row.stakeholder_type}>
                      <TableCell>{row.stakeholder_type}</TableCell>
                      <TableCell>
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'end',
                            alignItems: 'center',
                            gap: '5px',
                          }}
                        >
                          <Typography variant="h5">
                            {row.percentage}%
                          </Typography>
                          <ProgressBar width="260px" pct={row.percentage} />
                        </Box>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5"> {row.count}</Typography>
                      </TableCell>
                    </TableRow>
                  )
                )}
              </TableBody>
            </Table>
            <br />
            <Typography variant="h5">
              {t('totalNumber')}: {analytics.responses_count}
            </Typography>
          </StyledPanel>
        </MainMetricsContainer>
      )}

      <ScatterChart esg_stakeholder_id={selectedStakeholderTypeId} />
      <br />
      <StakeholdersImpactTable esg_stakeholder_id={selectedStakeholderTypeId} />
      <br />
      <BarChart esg_stakeholder_id={selectedStakeholderTypeId} />
      <br />
      <SummaryTable esg_stakeholder_id={selectedStakeholderTypeId} />
    </div>
  );
}
