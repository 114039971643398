import React from 'react';
import { Box } from '@mui/material';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import TaxonomyQuestionnaire from '../components/Questionnaire';

const questionnaireId = 'f84aaa74-171f-4444-b0bb-3433507eb7fd';

export default function Safeguards() {
  const { taxonomyId } = useEsgTaxonomy();
  if (!taxonomyId) return null;
  return (
    <Box sx={{ p: '0px 64px' }}>
      <TaxonomyQuestionnaire
        taxonomyId={taxonomyId}
        questionnaireId={questionnaireId}
      />
    </Box>
  );
}
