import { KeyboardArrowDown } from '@mui/icons-material';
import { Menu, MenuItem, Button } from '@mui/material';
import { UUID } from 'crypto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuestionnaireMethodology } from 'src/Esg/hooks';

interface Props {
  value: UUID;
  onChange: (value: UUID) => void;
}
export default function QuestionnaireMethodologySelector(props: Props) {
  const { value, onChange } = props;
  const { _data: methodologies, _dataMap: methodologiesMap } =
    useQuestionnaireMethodology();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const onClose = () => setAnchorEl(null);
  return (
    <>
      <Button
        variant="text"
        onClick={(e) => setAnchorEl(e.currentTarget)}
        endIcon={<KeyboardArrowDown />}
      >
        {t('esg:questionnaireMethodology.methodology')}:{' '}
        {methodologiesMap[value]?.description}
      </Button>
      <Menu open={!!anchorEl} anchorEl={anchorEl} onClose={onClose}>
        {methodologies.map((methodology) => (
          <MenuItem
            key={methodology.id}
            onClick={() => {
              onChange(methodology.id);
              onClose();
            }}
          >
            {methodology.description}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
