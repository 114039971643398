import React, { useEffect } from 'react';
import { Box } from '@mui/material';

import { StyledPanel } from 'src/components/StyledPanel';
import FigureTabs from './FigureTabs';
import { FigureType, TaxonomyReportOutSchema } from '../types';
import { useEsgTaxonomy } from 'src/Esg/hooks';

export const getEnabledFigure = (taxonomyReport: TaxonomyReportOutSchema) => {
  if (taxonomyReport.turnover_enabled) {
    return FigureType.Turnover;
  }
  if (taxonomyReport.capex_enabled) {
    return FigureType.Capex;
  }
  return FigureType.Opex;
};

interface Props {
  tab?: FigureType;
  onTabChange: (tab: FigureType) => void;
  turnoverComponent: React.ReactNode;
  capexComponent: React.ReactNode;
  opexComponent: React.ReactNode;
  unmountTabOnExit?: boolean;
}

export default function TaxonomyTabPanel(props: Readonly<Props>) {
  const {
    tab,
    onTabChange,
    turnoverComponent,
    opexComponent,
    capexComponent,
    unmountTabOnExit,
  } = props;

  const { taxonomyReport } = useEsgTaxonomy();

  useEffect(() => {
    if (taxonomyReport) {
      onTabChange(getEnabledFigure(taxonomyReport));
    }
  }, [taxonomyReport]);

  const hiddenSx = (key: FigureType) => ({
    display: key !== tab ? 'none' : 'inherit',
  });

  return (
    <StyledPanel sx={{ mt: '48px', minWidth: '740px' }}>
      <FigureTabs
        isInsideStyledPanel
        variant="fullWidth"
        value={tab}
        onChange={(_, tab) => onTabChange(tab)}
      />
      {!unmountTabOnExit && (
        // this approach only hides the components,
        // so the unsaved data is still there
        <>
          <Box sx={hiddenSx(FigureType.Turnover)}>{turnoverComponent}</Box>
          <Box sx={hiddenSx(FigureType.Capex)}>{capexComponent}</Box>
          <Box sx={hiddenSx(FigureType.Opex)}>{opexComponent}</Box>
        </>
      )}
      {unmountTabOnExit && (
        // this approach unmounts components on tab exit,
        // used when rendering each qv3 per tab
        <>
          {tab === FigureType.Turnover && <Box>{turnoverComponent}</Box>}
          {tab === FigureType.Capex && <Box>{capexComponent}</Box>}
          {tab === FigureType.Opex && <Box>{opexComponent}</Box>}
        </>
      )}
    </StyledPanel>
  );
}
