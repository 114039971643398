import React from 'react';

import Field from '../components/Field';
import { ValueChainEmployeesByCountryInSchema } from 'src/Esg/Dmav2/types';
import { useAllCountries, useLanguage } from 'src/common/hooks';
import SaveButton from '../components/SaveButton';
import withActivationButton from '../components/withActivationButton';
import { Checkbox, FormControlLabel } from '@mui/material';

interface Props {
  entityId: number;
  // eslint-disable-next-line no-unused-vars
  onSave: (data: ValueChainEmployeesByCountryInSchema) => void;
}

function NewEntityEmpolyeesByCountry(props: Readonly<Props>) {
  const { entityId, onSave } = props;
  const { t } = useLanguage();
  const { data: countries } = useAllCountries();
  const countiresOptions = countries.map((country) => ({
    value: country.iso_code,
    label: country.name,
  }));

  const [noInformation, setNoInformation] = React.useState(false);

  const [form, setForm] = React.useState<ValueChainEmployeesByCountryInSchema>({
    esg_dma_value_chain_entity_id: entityId,
    country: null,
    count: null,
  });

  const handleFormChange = (fieldName: string, value: any) =>
    setForm((prev) => ({ ...prev, [fieldName]: value }));

  const handleNoInformationChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { checked } = e.target;
    setNoInformation(checked);
    if (checked) handleFormChange('count', null);
  };

  const isValid = form.country && (noInformation || form.count !== null);

  return (
    <>
      <Field
        value={form.country ?? ''}
        onChange={(e) => handleFormChange('country', e.target.value)}
        options={countiresOptions}
        label={t('esg:dma.step1.valueChain.country')}
      />
      <Field
        sx={{ mt: '-5px' }}
        isNumber
        value={form.count ?? ''}
        textFieldProps={{ disabled: noInformation }}
        label={t('esg:dma.step1.valueChain.employeeCount')}
        onChange={(e) => handleFormChange('count', e.target.value || null)}
      />
      <FormControlLabel
        sx={{ ml: '25%' }}
        label={t('esg:dma.step1.valueChain.noInfo')}
        control={
          <Checkbox
            checked={noInformation}
            onChange={handleNoInformationChange}
          />
        }
      />
      <SaveButton disabled={!isValid} onSave={() => onSave(form)} />
    </>
  );
}

export default withActivationButton(NewEntityEmpolyeesByCountry);
