import React from 'react';
import { client } from 'src/utils/api-client';
import {
  EcoinventActivity,
  EcoinventActivityMap,
  LCAObject,
} from '../index.types';
import { Autocomplete } from '@mui/material';
import { StyledTextField } from '../Modals/index.styles';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number | null;
  object?: LCAObject;
  // eslint-disable-next-line no-unused-vars
  onChange: (object: EcoinventActivity | null) => void;
  ecoinventVersion?: string;
  isEcoinventLicenceValid: boolean;
}

interface ActivityOption {
  label: string;
  value: number;
}

export default function ActivitiesSearchField({
  object = undefined,
  value,
  onChange,
  ecoinventVersion,
  isEcoinventLicenceValid,
}: Props) {
  const { t } = useTranslation('lca');
  // searchTimeout to fetch delayed data from the API
  const [searchTimeout, setSearchTimeout] = React.useState<any>(null);

  const [data, setData] = React.useState<EcoinventActivity[]>([]);
  const dataMap = React.useMemo(() => {
    const map: EcoinventActivityMap = {};
    data.forEach((item) => {
      map[item.id] = item;
    });
    return map;
  }, [data]);

  const getData = (searchText: string) => {
    // fetch data from the API
    client
      .get(
        `/web/lca/activities?symbol=${searchText}&limit=50&version=${ecoinventVersion}`
      )
      .then((res) => {
        // inject the object assigned activity if already selected
        const newData: EcoinventActivity[] = object?.ecoinvent_activity
          ? [object.ecoinvent_activity, ...res.data]
          : res.data;

        setData(newData);
      });
  };

  // handle search input change
  // fetch data from the API after 1 second of inactivity
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (searchTimeout) clearTimeout(searchTimeout);
    const timeout = setTimeout(() => {
      getData(value);
    }, 1000);
    setSearchTimeout(timeout);
  };

  // fetch data from the API on component mount...
  React.useEffect(() => {
    // ...and when the ecoinventVersion is loaded in the parent component
    if (ecoinventVersion) {
      getData('');
    }
  }, [ecoinventVersion]);

  const handleOnChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    // get the activity from the data array
    // and send it to the parent component
    const activity = dataMap[value] || null;
    onChange(activity);
  };

  // data parsed to { value,label } array for MuiAutocomplete
  const options: ActivityOption[] = data.map((activity: EcoinventActivity) => ({
    value: activity.id,
    label: activity.label ?? '',
  }));

  // searchText filtered and parsed to value array for MuiAutocomplete
  const _options = (options || []).map((item: ActivityOption) => item.value);

  return (
    <Autocomplete
      fullWidth
      options={_options}
      value={value}
      onChange={handleOnChange}
      sx={{ backgroundColor: value ? '#F6F6F6' : 'inherit' }} // I couldn't force it with themes styling
      renderInput={(params) => (
        <StyledTextField
          {...params}
          value=""
          label={t('ecoinventSearch')}
          onChange={handleSearchChange}
        />
      )}
      getOptionLabel={(value) => dataMap[value]?.label ?? ''}
      filterOptions={(x) => x}
      disabled={!isEcoinventLicenceValid}
    />
  );
}
