import {
  QueryOptions,
  useLanguage,
  useResourceController,
} from 'src/common/hooks';
import {
  TaxonomyActivityOutSchema,
  TaxonomyDNSHCriteriaOutSchema,
  TaxonomyExpenditureContributionInSchema,
  TaxonomyExpenditureContributionOutSchema,
  TaxonomyExpenditureDNSHInSchema,
  TaxonomyExpenditureDNSHOutSchema,
  TaxonomyExpenditureValueInSchema,
  TaxonomyExpenditureValueOutSchema,
  TaxonomyMainCriteriaOutSchema,
  TaxonomyObjectiveOutSchema,
  TaxonomyReportActivityInSchema,
  TaxonomyReportActivityObjectiveFilterSchema,
  TaxonomyReportActivityObjectiveInSchema,
  TaxonomyReportActivityObjectiveOutSchema,
  TaxonomyReportActivityOutSchema,
  TaxonomyReportActivityResourceFilterSchema,
  TaxonomyReportInSchema,
  TaxonomyReportOutSchema,
  TaxonomyReportSectorInSchema,
  TaxonomyReportSectorOutSchema,
  TaxonomyReportTotalValueInSchema,
  TaxonomyReportTotalValueOutSchema,
  TaxonomySectorOutSchema,
  TaxonomyTurnoverContributionInSchema,
  TaxonomyTurnoverContributionOutSchema,
  TaxonomyTurnoverDNSHInSchema,
  TaxonomyTurnoverDNSHOutSchema,
  TaxonomyTurnoverValueInSchema,
  TaxonomyTurnoverValueOutSchema,
  TaxonomyReportAnalytics,
} from './types';
import { UUID } from 'crypto';

const staticRequestsQueryOptions = {
  staleTime: 1000 * 60 * 60, // 1 hour
  cacheTime: 1000 * 60 * 60, // 1 hour
};

const dynamicRequestsQueryOptions = {
  staleTime: 1000 * 60 * 5, // 5 minutes
  cacheTime: 1000 * 60 * 10, // 10 minutes
};

export const useSectors = () => {
  const { language } = useLanguage();
  return useResourceController<undefined, TaxonomySectorOutSchema>(
    `/web/taxonomy/sectors`,
    ['taxonomy-static', 'sectors', { language }],
    {},
    staticRequestsQueryOptions,
    ['GET']
  );
};

export const useActivities = () => {
  const { language } = useLanguage();
  return useResourceController<undefined, TaxonomyActivityOutSchema>(
    `/web/taxonomy/activities`,
    ['taxonomy-static', 'activities', { language }],
    {},
    staticRequestsQueryOptions,
    ['GET']
  );
};

export const useObjectives = () => {
  const { language } = useLanguage();
  return useResourceController<undefined, TaxonomyObjectiveOutSchema>(
    `/web/taxonomy/objectives`,
    ['taxonomy-static', 'objectives', { language }],
    {},
    staticRequestsQueryOptions,
    ['GET']
  );
};

export const useMainCriterias = () => {
  const { language } = useLanguage();
  return useResourceController<undefined, TaxonomyMainCriteriaOutSchema>(
    `/web/taxonomy/main-criterias`,
    ['taxonomy-static', 'main-criterias', { language }],
    {},
    staticRequestsQueryOptions,
    ['GET']
  );
};

export const useDNSHCriterias = () => {
  const { language } = useLanguage();
  return useResourceController<undefined, TaxonomyDNSHCriteriaOutSchema>(
    `/web/taxonomy/dnsh-criterias`,
    ['taxonomy-static', 'dnsh-criterias', { language }],
    {},
    staticRequestsQueryOptions,
    ['GET']
  );
};

export const useTaxonomyReports = (
  queryParams: Partial<TaxonomyReportOutSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportInSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<TaxonomyReportInSchema, TaxonomyReportOutSchema>(
    `/web/taxonomy/reports`,
    ['taxonomy', 'reports', queryParams],
    queryParams,
    queryOptions
  );

export const useTaxonomyReportSectors = (
  queryParams: Partial<TaxonomyReportSectorInSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportSectorInSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyReportSectorInSchema,
    TaxonomyReportSectorOutSchema
  >(
    `/web/taxonomy/report-sectors`,
    ['taxonomy', 'report-sectors', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'report-sectors'], ...queryOptions },
    ['GET', 'POST', 'DELETE']
  );

export const useTaxonomyReportActivities = (
  queryParams: Partial<TaxonomyReportActivityInSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportActivityInSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyReportActivityInSchema,
    TaxonomyReportActivityOutSchema
  >(
    `/web/taxonomy/report-activities`,
    ['taxonomy', 'report-activities', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'report-activities'], ...queryOptions }
  );

export const useTaxonomyReportTotalValues = (
  queryParams: Partial<TaxonomyReportTotalValueInSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportTotalValueInSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyReportTotalValueInSchema,
    TaxonomyReportTotalValueOutSchema
  >(
    `/web/taxonomy/report-total-values`,
    ['taxonomy', 'report-total-values', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'report-total-values'], ...queryOptions }
  );

export const useTaxonomyTurnoverValues = (
  queryParams: Partial<TaxonomyReportActivityResourceFilterSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportActivityResourceFilterSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyTurnoverValueInSchema,
    TaxonomyTurnoverValueOutSchema
  >(
    `/web/taxonomy/turnover-values`,
    ['taxonomy', 'turnover-values', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'turnover-values'], ...queryOptions }
  );

export const useTaxonomyExpenditureValues = (
  queryParams: Partial<TaxonomyReportActivityResourceFilterSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportActivityResourceFilterSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyExpenditureValueInSchema,
    TaxonomyExpenditureValueOutSchema
  >(
    `/web/taxonomy/expenditure-values`,
    ['taxonomy', 'expenditure-values', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'expenditure-values'], ...queryOptions }
  );

export const useTaxonomyTurnoverContributions = (
  queryParams: Partial<TaxonomyReportActivityResourceFilterSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportActivityResourceFilterSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyTurnoverContributionInSchema,
    TaxonomyTurnoverContributionOutSchema
  >(
    `/web/taxonomy/turnover-contributions`,
    ['taxonomy', 'turnover-contributions', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'turnover-contributions'], ...queryOptions }
  );

export const useTaxonomyExpenditureContributions = (
  queryParams: Partial<TaxonomyReportActivityResourceFilterSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportActivityResourceFilterSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyExpenditureContributionInSchema,
    TaxonomyExpenditureContributionOutSchema
  >(
    `/web/taxonomy/expenditure-contributions`,
    ['taxonomy', 'expenditure-contributions', queryParams],
    queryParams,
    {
      invalidateKey: ['taxonomy', 'expenditure-contributions'],
      ...queryOptions,
    }
  );

export const useTaxonomyTurnoverDNSH = (
  queryParams: Partial<TaxonomyReportActivityResourceFilterSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportActivityResourceFilterSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyTurnoverDNSHInSchema,
    TaxonomyTurnoverDNSHOutSchema
  >(
    `/web/taxonomy/turnover-dnsh`,
    ['taxonomy', 'turnover-dnsh', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'turnover-dnsh'], ...queryOptions }
  );

export const useTaxonomyExpenditureDNSH = (
  queryParams: Partial<TaxonomyReportActivityResourceFilterSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportActivityResourceFilterSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyExpenditureDNSHInSchema,
    TaxonomyExpenditureDNSHOutSchema
  >(
    `/web/taxonomy/expenditure-dnsh`,
    ['taxonomy', 'expenditure-dnsh', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'expenditure-dnsh'], ...queryOptions }
  );

export const useTaxonomyReportActivityObjectives = (
  queryParams: Partial<TaxonomyReportActivityObjectiveFilterSchema> = {},
  queryOptions: QueryOptions<TaxonomyReportActivityObjectiveFilterSchema> = dynamicRequestsQueryOptions
) =>
  useResourceController<
    TaxonomyReportActivityObjectiveInSchema,
    TaxonomyReportActivityObjectiveOutSchema
  >(
    `/web/taxonomy/activity-objectives`,
    ['taxonomy', 'activity-objectives', queryParams],
    queryParams,
    { invalidateKey: ['taxonomy', 'activity-objectives'], ...queryOptions }
  );

export const useTaxonomyReportAnalytics = (queryParams: { id?: UUID }) =>
  useResourceController<undefined, TaxonomyReportAnalytics>(
    `/web/taxonomy/report-analytics`,
    ['taxonomy', 'report-analytics', queryParams],
    queryParams,
    dynamicRequestsQueryOptions,
    ['GET']
  );
