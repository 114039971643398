import React from 'react';
import { Alert, Box, List, Typography } from '@mui/material';

import { useLanguage } from 'src/common/hooks';
import { StyledPanel } from 'src/components/StyledPanel';
import Step from './components/Step';
import { useNavigate } from 'react-router-dom';
import { useEsgTaxonomy } from '../hooks';
import { Download, FactCheck, Warning } from '@mui/icons-material';
import { client } from 'src/utils/api-client';
import { getFilenameDateSuffix } from 'src/utils/timeConverter';
import { toast } from 'react-toastify';
import { useMutation } from 'react-query';
import PageLoading from 'src/components/PageLoading';

export default function Taxonomy() {
  const { t } = useLanguage({ keyPrefix: 'esg:taxonomy.index' });
  const navigate = useNavigate();
  const { esgReport, taxonomyReport, taxonomyId, queries } = useEsgTaxonomy();

  const exportXlsxMutation = useMutation({
    mutationFn: () =>
      client.get(`/web/taxonomy/report-analytics/${taxonomyId}/xlsx`, {
        responseType: 'blob',
      }),
    onSuccess: (response: any) => {
      const currentDate = getFilenameDateSuffix();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      link.download = `esg-taxonomy-report-${currentDate}.xlsx`;
      link.click();
    },
    onError: () => {
      toast.error(t('downloadError') as string, {
        autoClose: 10_000,
        toastId: 'submit-error',
      });
    },
  });

  if (
    !esgReport ||
    queries.taxonomyReports.isLoading ||
    queries.reportActivities.isLoading ||
    queries.reportSectors.isLoading
  )
    return <PageLoading />;

  const taxonomyExists = !!esgReport.taxonomy_id;
  const taxonomyHasActivitiesAndSectors =
    queries.reportActivities._data.length > 0 &&
    queries.reportSectors._data.length > 0;

  const reportText = !taxonomyExists
    ? t('introduction.steps.createReport')
    : `${t('introduction.steps.report')}: ${taxonomyReport?.name}`;

  const taxonomyEnabled = esgReport.enable_taxonomy;

  return (
    <Box sx={{ mt: '60px' }}>
      {/* introduction (required) taxonomy panel   */}
      <Typography variant="overlineText" color="#6e6e6e">
        {t('introduction.title')}
      </Typography>
      <StyledPanel sx={{ mt: '8px', border: '4px solid #e9bc46' }}>
        <Typography variant="h3">{t('introduction.header')}</Typography>
        <br />
        <Typography>{t('introduction.text')}</Typography>
        <br />
        {!taxonomyEnabled && (
          <Alert severity="info" sx={{ mb: '24px' }}>
            {t('noAccess')}
          </Alert>
        )}
        <List sx={{ '& > :first-of-type': { borderTop: '1px solid #e0e0e0' } }}>
          <Step
            listItemIcon={!taxonomyExists && <Warning />}
            primary={reportText}
            onClick={() => navigate('./report')}
            disabled={!taxonomyEnabled}
          />
          {taxonomyEnabled && taxonomyExists && (
            <Step
              listItemIcon={!taxonomyHasActivitiesAndSectors && <Warning />}
              primary={t('introduction.steps.selection')}
              onClick={() => navigate('./selection')}
            />
          )}
        </List>
      </StyledPanel>

      {taxonomyEnabled && taxonomyReport && taxonomyHasActivitiesAndSectors && (
        <>
          <Box my="32px">
            {/* main taxonomy panel   */}
            <Typography variant="overlineText" color="#6e6e6e">
              {t('main.title')}
            </Typography>
            <StyledPanel sx={{ mt: '8px' }}>
              <Typography variant="h3">{t('main.header')}</Typography>
              <br />
              <Typography>{t('main.text')}</Typography>
              <br />
              <List
                sx={{
                  '& > :first-of-type': { borderTop: '1px solid #e0e0e0' },
                }}
              >
                <Step
                  primary={t('main.steps.objectives')}
                  onClick={() => navigate('./objectives')}
                />
                <Step
                  primary={t('main.steps.activities')}
                  onClick={() => navigate('./activities')}
                />
                <Step
                  primary={t('main.steps.substantialContribution')}
                  onClick={() => navigate('./contributions')}
                />
                <Step
                  primary={t('main.steps.dnsh')}
                  onClick={() => navigate('./dnsh')}
                />
                <Step
                  primary={t('main.steps.safeguards')}
                  onClick={() => navigate('./safeguards')}
                />
                <Step
                  primary={t('main.steps.fuelsForm')}
                  onClick={() => navigate('./fuels-form')}
                />
                <Step
                  primary={t('main.steps.methodology')}
                  onClick={() => navigate('./methodology')}
                />
              </List>
            </StyledPanel>
          </Box>
          <Box>
            {/* summary panel   */}
            <Typography variant="overlineText" color="#6e6e6e">
              {t('summary.title')}
            </Typography>
            <StyledPanel sx={{ mt: '8px' }}>
              <Typography variant="h3">{t('summary.header')}</Typography>
              <br />
              <Typography>{t('summary.text')}</Typography>
              <br />
              <List
                sx={{
                  '& > :first-of-type': { borderTop: '1px solid #e0e0e0' },
                }}
              >
                <Step
                  primary={t('summary.steps.overview')}
                  onClick={() => navigate('./overview')}
                  actionIcon={<FactCheck />}
                />
                <Step
                  primary={t('summary.steps.downloadReport')}
                  onClick={() => exportXlsxMutation.mutate()}
                  actionIcon={<Download />}
                />
              </List>
            </StyledPanel>
          </Box>
        </>
      )}
    </Box>
  );
}
