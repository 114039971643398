import React from 'react';
import { Box, Typography } from '@mui/material';

import SomethingWentWrong from 'src/components/SomethingWentWrong';
import PageLoading from 'src/components/PageLoading';
import { useQuestionnaires, useQv3Context } from '../hooks';
import Description from './Description';
import Questions from '../Questions';
import { StyledFlex } from 'src/components/StyledFlex';
import BackButton from 'src/components/BackButton';

export default function Questionnaire() {
  const { questionnaireId } = useQv3Context();
  const { _instance: questionnaire, isLoading } = useQuestionnaires(
    { id: questionnaireId },
    { enabled: !!questionnaireId }
  );

  // redndering guards
  if (!questionnaireId) return null;
  if (isLoading) return <PageLoading />;
  if (questionnaire === undefined) return <SomethingWentWrong />;

  const { title, description } = questionnaire;

  return (
    <Box sx={{ minWidth: '880px' }}>
      <StyledFlex>
        <BackButton fontSize="large" />
        <Typography variant="h1">{title}</Typography>
      </StyledFlex>

      {/* This Box handles margins (for left-margin-straigh-line and right margin actions (comments, references, etc.)) */}
      <Box m="32px" p="0px 48px" borderLeft="2px solid #bbb">
        <Description description={description} />
        <Questions />
      </Box>
    </Box>
  );
}
