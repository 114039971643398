import React from 'react';
import {
  Checkbox,
  List,
  ListItem,
  Collapse,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useStakeholderTopics } from 'src/Esg/Dmav2/hooks';
import SubSubTopic from './SubSubTopic';

interface Props {
  esg_stakeholder_data_id: number;
  sub_topic_id: number;
}
export default function SubTopic(props: Readonly<Props>) {
  const { esg_stakeholder_data_id, sub_topic_id } = props;
  const { subTopics, subSubTopics, updateSubTopic } = useStakeholderTopics(
    esg_stakeholder_data_id
  );
  const subTopic = subTopics.filter((sub) => sub.id === sub_topic_id)[0]; // TODO? return maps from hooks and use them here
  const subsubtopics = subSubTopics.filter(
    (ss) => ss.subtopic_id === sub_topic_id
  );
  const [open, setOpen] = React.useState(false);

  const hasSubSubTopics = subsubtopics?.length > 0;
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box width="36px" display="flex">
          {hasSubSubTopics && (
            <IconButton size="small" onClick={() => setOpen((prev) => !prev)}>
              {open ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          )}
        </Box>
        <Checkbox
          checked={subTopic.is_selected}
          onChange={(e) =>
            updateSubTopic.mutate({
              sub_topic_id: subTopic.id,
              is_selected: e.target.checked,
            })
          }
        />
        <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
          {subTopic.description}
        </Typography>
      </Box>
      <Collapse in={open}>
        <List disablePadding>
          {subsubtopics.map((ss) => (
            <ListItem
              key={ss.id}
              sx={{ pl: 6, borderLeft: '1px solid gray', ml: '16px' }}
            >
              <SubSubTopic
                esg_stakeholder_data_id={esg_stakeholder_data_id}
                sub_sub_topic_id={ss.id}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
