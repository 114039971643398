import React from 'react';

import { Box, Button, DialogActions, TextField } from '@mui/material';

import { Trans, useTranslation } from 'react-i18next';
import { StyledDialogSubtitle } from '../index.styles';

interface Props {
  value: string | null;
  // eslint-disable-next-line no-unused-vars
  onChange: (reasoning: string) => void;
  subtitleTranslationKey?: string;
  title: string;
}

export default function Edit(props: Readonly<Props>) {
  const { value, onChange, subtitleTranslationKey } = props;
  const { t } = useTranslation('esg', {
    keyPrefix: 'dma.selectingStakeholders.table',
  });

  const [text, setText] = React.useState<string>(value ?? '');
  const handleSaveClick = () => onChange(text);

  return (
    <Box>
      {subtitleTranslationKey && (
        <StyledDialogSubtitle>
          <Trans i18nKey={subtitleTranslationKey} />
        </StyledDialogSubtitle>
      )}
      <TextField
        sx={{ p: '0px 24px' }}
        fullWidth
        multiline
        rows={12}
        placeholder={t('modals.intro.placeholder')}
        value={text}
        onChange={(e) => setText(e.target.value)}
      />
      <br />
      <DialogActions>
        <Button className="primaryButton" onClick={handleSaveClick}>
          {t('modals.reasoning.buttons.save')}
        </Button>
      </DialogActions>
    </Box>
  );
}
