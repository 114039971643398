import React from 'react';

import { TableCell, TableRow, Typography } from '@mui/material';
import { UUID } from 'crypto';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import NumberField from 'src/components/NumberField';
import SaveRow from 'src/Esg/Taxonomy/components/SaveRow';
import { toCurrency } from 'src/Esg/Taxonomy/utils';

interface Props {
  reportActivityId: UUID;
}

export default function ActivityRow({ reportActivityId }: Readonly<Props>) {
  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();

  const [value, setValue] = React.useState<string | number | null>(0);

  const contribution = queries.turnoverContributions._data.find(
    (tv) => tv.report_activity_id === reportActivityId
  );
  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activity = queries.activities._dataMap[reportActivity?.activity_id];

  React.useEffect(() => {
    if (contribution) setValue(contribution.contribution);
  }, [contribution]);

  if (!taxonomyReport || queries.isLoading || reportAnalytics.isLoading)
    return null;
  const handleSaveClick = () =>
    contribution
      ? queries.turnoverContributions.update
          .mutateAsync({
            id: contribution.id,
            contribution: Number(value),
          })
          .then(() => {
            reportAnalytics.refetch();
          })
      : queries.turnoverContributions.create
          .mutateAsync({
            report_activity_id: reportActivityId,
            contribution: Number(value),
          })
          .then(() => {
            reportAnalytics.refetch();
          });

  const analytics =
    reportAnalytics._instance?.turnover?.activities_analytics.find(
      (aa) => aa.report_activity_id === reportActivityId
    );

  const originalValue = contribution?.contribution ?? 0;
  const isModified = originalValue !== value;
  const tableCellsx = isModified ? { borderBottom: 'none' } : {};

  return (
    <>
      <TableRow>
        <TableCell sx={tableCellsx}>
          <Typography>{activity.label}</Typography>
        </TableCell>
        <TableCell sx={tableCellsx}>
          <NumberField
            fullWidth
            size="small"
            decimalPlaces={0}
            label="%"
            value={value}
            onChange={(value) => setValue(Number(value))}
          />
        </TableCell>
        <TableCell sx={tableCellsx}>
          {toCurrency(
            analytics?.contribution_aligned,
            taxonomyReport.currency_type
          )}
        </TableCell>
      </TableRow>
      {isModified && <SaveRow colSpan={7} onSaveClick={handleSaveClick} />}
    </>
  );
}
