import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import TurnoverTable from './TurnoverTable';

export default function Turnover() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.contributions',
  });
  return (
    <>
      <Typography>{t('text')}</Typography>
      <br />
      <TurnoverTable />
    </>
  );
}
