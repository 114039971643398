import React from 'react';
import { useEsgStakeholderAnswerComments } from '../../hooks';
import CommentsPopup from 'src/components/CommentsPopup/CommentsPopup';
import { EsgStakeholderAnswerCommentsOutSchema } from '../../types';

interface Props {
  esgStakeholderSurveyId: number;
  esgStakeholderAnswerId: number;
  readOnly?: boolean;
}

export default function Comments(props: Readonly<Props>) {
  const { esgStakeholderAnswerId, esgStakeholderSurveyId, readOnly } = props;
  const { _data, _delete, create, update } = useEsgStakeholderAnswerComments({
    esg_stakeholder_answer__esg_stakeholder_survey_id: esgStakeholderSurveyId,
  });

  const comments = _data.filter(
    (c) => c.esg_stakeholder_answer_id === esgStakeholderAnswerId
  );

  const handleCreate = React.useCallback(
    async (text: string) => {
      await create.mutateAsync({
        esg_stakeholder_answer_id: esgStakeholderAnswerId,
        text,
      });
    },
    [create, esgStakeholderAnswerId]
  );

  const handleUpdate = React.useCallback(
    async (updated: EsgStakeholderAnswerCommentsOutSchema) => {
      await update.mutateAsync(updated);
    },
    [update]
  );

  const handleDelete = React.useCallback(
    async (id: number) => {
      await _delete.mutateAsync(id);
    },
    [_delete]
  );

  return (
    <CommentsPopup
      comments={comments}
      onCreate={handleCreate}
      onUpdate={handleUpdate}
      onDelete={handleDelete}
      badgeColor="primary"
      readOnly={readOnly}
    />
  );
}
