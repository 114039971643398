import colors from 'src/theme/colors';
import styled from 'styled-components';

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: #38414f !important;
`;

export const MultiRowHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 2fr 2fr 2fr 1fr;
  grid-template-rows: 32px 32px;
  background-color: ${colors.backgroundGrey};
  & > div:nth-child(2) {
    border-left: none;
  }
  & > div:nth-child(1) {
    border-right: none;
  }
`;

export const HeaderCell = styled.div`
  border: 1px solid ${colors.grayInactive};
  text-align: center;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const GroupRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 2fr 2fr 2fr 1fr;
  background-color: ${colors.backgroundInput};
`;

export const DataRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr 2fr 2fr 2fr 1fr;
  & > div:nth-child(2) {
    border-left: none;
  }
  & > div:nth-child(1) {
    border-right: none;
  }
`;

export const Cell = styled.div`
  border: 1px solid #b6bbb9;
  text-align: center;
  font-size: 12px;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  line-height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
`;
