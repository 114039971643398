import React, { MouseEventHandler } from 'react';
import { TableCell } from '@mui/material';
import TouchRipple, {
  TouchRippleActions,
} from '@mui/material/ButtonBase/TouchRipple';

import { FieldOutSchema, FieldValueSchema } from 'src/QuestionnairesV3/types';
import { fieldValueToString } from 'src/QuestionnairesV3/utils';
import { useFieldOptions } from 'src/QuestionnairesV3/hooks';

interface Props {
  onClick: () => void;
  field: FieldOutSchema;
  value?: FieldValueSchema;
}

export default function ValueCell(props: Readonly<Props>) {
  const { onClick, value, field } = props;
  const rippleRef = React.useRef<TouchRippleActions | null>(null);

  const { _dataMap: optionsMap } = useFieldOptions({ field_id: field.id });

  const onRippleStart: MouseEventHandler<HTMLTableCellElement> = (e) => {
    rippleRef?.current?.start(e);
  };
  const onRippleStop: MouseEventHandler<HTMLTableCellElement> = (e) =>
    rippleRef?.current?.stop(e);

  return (
    <TableCell
      onClick={onClick}
      onMouseDown={onRippleStart}
      onMouseUp={onRippleStop}
      sx={{
        textAlign: 'center',
        cursor: 'pointer',
        position: 'relative',
        ':hover': { background: '#eee' },
      }}
    >
      {fieldValueToString(field.type, value, optionsMap)}
      <TouchRipple ref={rippleRef} center={false} />
    </TableCell>
  );
}
