import React from 'react';
import { Checkbox, Typography } from '@mui/material';
import { useStakeholderTopics } from 'src/Esg/Dmav2/hooks';
import { useTranslation } from 'react-i18next';

interface Props {
  esg_stakeholder_data_id: number;
  sub_sub_topic_id: number;
}
export default function SubSubTopic(props: Readonly<Props>) {
  const { esg_stakeholder_data_id, sub_sub_topic_id } = props;
  const { subSubTopics, updateSubSubTopic } = useStakeholderTopics(
    esg_stakeholder_data_id
  );
  const ss = subSubTopics.filter((ss) => ss.id === sub_sub_topic_id)[0]; // TODO? return maps from hooks and use them here
  const { t } = useTranslation();

  return (
    <>
      <Checkbox
        checked={ss.is_selected}
        onChange={(e) =>
          updateSubSubTopic.mutate({
            sub_sub_topic_id: ss.id,
            is_selected: e.target.checked,
          })
        }
      />
      <Typography variant="body2">
        {ss.description ||
          t('esg:dma.selectingStakeholders.unnamedSubSubTopic')}
      </Typography>
    </>
  );
}
