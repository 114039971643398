import React from 'react';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';

import { FigureType } from '../../types';
import Head from './Head';
import { useTranslation } from 'react-i18next';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import ActivityRow from './ActivityRow';

interface Props {
  figure: FigureType;
}
export default function ObjectiveTable(props: Readonly<Props>) {
  const { figure } = props;
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.objectives.table',
  });
  const { queries } = useEsgTaxonomy();
  if (queries.isLoading) return null;

  const reportActivities = queries.reportActivities._data.filter((ra) => {
    if (figure === FigureType.Turnover) return ra.turnover_enabled;
    else if (figure === FigureType.Capex) return ra.capex_enabled;
    else return ra.opex_enabled;
  });

  return (
    <Box>
      <Typography>{t('text')}</Typography>
      <br />
      <Table>
        <Head />
        <TableBody>
          {reportActivities.map((reportActivity) => (
            <ActivityRow
              key={reportActivity.id}
              reportActivityId={reportActivity.id}
              figure={figure}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  );
}
