export type EcoinventActivity = {
  id: number;
  activity_name: string;
  geography: string;
  reference_product_name: string;
  reference_product_unit: string;
  reference_product_amount: number;
  label: string;
};

export type EcoinventActivityMap = {
  [key: string]: EcoinventActivity;
};

export type LcaObjectCustomFactor = {
  area_id: number;
  factor: number | null;
};

export type LCAObject = {
  id?: number;
  name?: string;
  amount?: number | string;
  category?: string;
  subcategory: string;
  life_cycle_phase?: string;
  stage?: string;
  process?: string;
  category_key?: string;
  parent_process?: string;
  unit?: string;
  type_of_material?: string;
  ecoinvent_activity?: EcoinventActivity;
  comment?: string;
  custom_factors: LcaObjectCustomFactor[];
};

export type LCAProcess = {
  id?: number;
  name?: string;
  life_cycle_phase?: string;
  process?: string;
  stage?: string;
  parent_process?: string;
};

export interface LcaSubProductCommonSchema {
  name: string;
  stage: string;
  parent_process_id: number | null;
  amount: number;
  sub_product_id: number;
  comment: string;
}

export interface LcaSubProductInSchema extends LcaSubProductCommonSchema {
  product_id: number;
}

export interface LcaSubProductOutSchema extends LcaSubProductInSchema {
  id: number;
  sub_product_name: string;
}

export interface LcaSubProductUpdateSchema extends LcaSubProductCommonSchema {}

export enum LcaManagementTab {
  // eslint-disable-next-line
  FLOWCHART = 'flowchart',
  // eslint-disable-next-line
  IMPACT = 'impact',
  // eslint-disable-next-line
  ANALYTICS = 'analytics',
}
