import React from 'react';
import { StyledFlex } from 'src/components/StyledFlex';
import { FieldAnswerOutSchema } from '../types';
import { Box, Button } from '@mui/material';
import { useLanguage } from 'src/common/hooks';
import { Delete } from '@mui/icons-material';

interface Props {
  fieldAnswer?: FieldAnswerOutSchema;
  onDeleteClick: () => void;
  onCancelClick: () => void;
  onSaveClick: () => void;
}

export const buttonIdPrefix = 'field-save-button-qa';

export const makeButtonId = (fieldAnswerId?: number) =>
  `${buttonIdPrefix}:${fieldAnswerId ?? 'new'}`;

export default function Buttons(props: Readonly<Props>) {
  const { fieldAnswer, onCancelClick, onDeleteClick, onSaveClick } = props;
  const { t } = useLanguage({ keyPrefix: 'questionnaireV3.questionAnswer' });
  return (
    <StyledFlex mt="24px">
      {!!fieldAnswer && (
        <Box width="260px" textAlign="left">
          <Button
            startIcon={<Delete />}
            size="small"
            variant="outlined"
            onClick={onDeleteClick}
          >
            {t('deleteButton')}
          </Button>
        </Box>
      )}
      <Box width="260px" textAlign={fieldAnswer ? 'center' : 'left'}>
        <Button size="small" variant="outlined" onClick={onCancelClick}>
          {t('cancelButton')}
        </Button>
      </Box>
      <Box width="260px" textAlign="right">
        <Button
          id={makeButtonId(fieldAnswer?.id)}
          size="small"
          variant="contained"
          onClick={onSaveClick}
        >
          {t('saveButton')}
        </Button>
      </Box>
    </StyledFlex>
  );
}
