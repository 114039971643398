import React from 'react';
import { loginWithMfa } from '../../../utils';
import { toast } from 'react-toastify';
import { AxiosError, AxiosResponse } from 'axios';
import MfaDialog from 'src/components/MfaDialog';

interface Props {
  mfaTokenId: string;
}

export default function LoginMfaDialog({ mfaTokenId }: Readonly<Props>) {
  const submit = (mfaCode: string) => {
    loginWithMfa(mfaTokenId, mfaCode)
      .then(() => {
        window.location.href = '/';
      })
      .catch((error: AxiosError) => {
        const response: AxiosResponse = error.response!;
        toast.error(response.data?.detail);
        setTimeout(() => {
          window.location.href = '/';
        }, 5000);
      });
  };

  return (
    <MfaDialog
      onSubmit={submit}
      onCancel={() => {
        window.location.href = '/';
      }}
    />
  );
}
