import {
  EsgDmaTopicOutSchema,
  StakeholdersSurveyTopicSummarySchema,
} from 'src/Esg/Dmav2/types';
import _ from 'lodash';
import { ChartOptions, TooltipItem } from 'chart.js';
import { t } from 'i18next';

export const getDataPoint = (
  topic: EsgDmaTopicOutSchema,
  summary: StakeholdersSurveyTopicSummarySchema,
  adjustedSummary: StakeholdersSurveyTopicSummarySchema,
  label: string
) => ({
  label,
  data: [
    {
      x: adjustedSummary.negative_avg!,
      y: adjustedSummary.positive_avg!,
      description: topic.name,
      realNegativeScore: summary.negative_avg ?? '-',
      realPositiveScore: summary.positive_avg ?? '-',
    },
  ],
  backgroundColor: topic.color || '#D9D9D9',
  pointRadius: 20,
  pointHoverRadius: 25,
});

export function adjustDataPoints<
  T extends { positive_avg: number | null; negative_avg: number | null },
>(summaries: T[]): T[] {
  // Create a deep copy of the summaries array
  const adjustedSummaries = _.cloneDeep(summaries);

  // Use a map to track positions and count how many points share the same position
  const positionCount: {
    [position: string]: T[];
  } = {};

  // Group points by their positions
  adjustedSummaries.forEach((summary) => {
    if (!summary.positive_avg) {
      summary.positive_avg = 0;
    }
    if (!summary?.negative_avg) {
      summary.negative_avg = 0;
    }
    const posKey = `${summary.positive_avg},${summary.negative_avg}`;
    if (!positionCount[posKey]) {
      positionCount[posKey] = [];
    }
    positionCount[posKey].push(summary);
  });

  // Adjust points that are at the exact same position
  Object.keys(positionCount).forEach((posKey) => {
    const points = positionCount[posKey];
    if (points.length > 1) {
      // More than one point at the same position, move them vertically
      points.forEach((point, index) => {
        // Increment the y-coordinate for each subsequent point
        point.negative_avg! += 0.01 * (index + 1);
      });
    }
  });

  const minDistance = 0.6;

  for (let i = 0; i < adjustedSummaries.length; i++) {
    for (let j = i + 1; j < adjustedSummaries.length; j++) {
      const dx =
        adjustedSummaries[j].positive_avg! - adjustedSummaries[i].positive_avg!;
      const dy =
        adjustedSummaries[j].negative_avg! - adjustedSummaries[i].negative_avg!;
      // Ensure the distance is at least 0.01 to avoid division by zero
      const distance = Math.max(Math.sqrt(dx * dx + dy * dy), 0.01);

      if (distance < minDistance) {
        // Calculate the required adjustment to reach the minimum distance.
        const overlap = minDistance - distance;
        const adjustX = (overlap / distance) * dx;
        const adjustY = (overlap / distance) * dy;

        // This maintains the relative positioning while ensuring minimal movement.
        adjustedSummaries[i].positive_avg! -= adjustX / 2;
        adjustedSummaries[i].negative_avg! -= adjustY / 2;
        adjustedSummaries[j].positive_avg! += adjustX / 2;
        adjustedSummaries[j].negative_avg! += adjustY / 2;
      }
    }
  }

  return adjustedSummaries;
}

export const scatterOptions: ChartOptions<'scatter'> = {
  maintainAspectRatio: true,
  aspectRatio: 1,
  scales: {
    x: {
      type: 'linear',
      min: 0,
      max: 3.5,
      ticks: {
        stepSize: 0.5,
        callback(value) {
          return Number(value) > 3 ? '' : value;
        },
        padding: 10,
      },
      grid: {
        display: true,
        drawOnChartArea: true,
        lineWidth: 1,
        color: 'rgba(0, 0, 0, 0.1)',
      },
    },
    y: {
      min: 0,
      max: 3.5,
      ticks: {
        stepSize: 0.5,
        callback(value) {
          return Number(value) > 3 ? '' : value;
        },
        padding: 10,
      },
      grid: {
        display: true,
        drawOnChartArea: true,
        lineWidth: 1,
        color: 'rgba(0, 0, 0, 0.1)',
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label(context: TooltipItem<'scatter'>) {
          const { description, realNegativeScore, realPositiveScore } =
            context.raw as {
              description: string;
              realNegativeScore: number;
              realPositiveScore: number;
            };
          return `${description}, ${t(
            'esg:dma.stakeholderAnalytics.dashboard.scatterPlot.negativeScore'
          )}: ${realNegativeScore}, ${t(
            'esg:dma.stakeholderAnalytics.dashboard.scatterPlot.positiveScore'
          )}: ${realPositiveScore}`;
        },
      },
    },
  },
};
