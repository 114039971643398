import React from 'react';
import { useTaxonomyReports } from '../hooks';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PageLoading from 'src/components/PageLoading';
import { StyledPanel } from 'src/components/StyledPanel';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Box,
  Typography,
  Alert,
  DialogActions,
} from '@mui/material';
import { CurrencyType, TaxonomyReportInSchema } from '../types';
import NavigationBlockerModal from 'src/components/NavigationBlockerModal';
import BackButton from 'src/components/BackButton';
import { StyledFlex } from 'src/components/StyledFlex';
import { useEsgReports } from 'src/Esg/hooks';

const initialForm: TaxonomyReportInSchema = {
  name: '',
  currency_type: CurrencyType.EUR,
  turnover_enabled: true,
  capex_enabled: false,
  opex_enabled: false,
};

export default function TaxonomyReport() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.report' });
  const { reportId } = useParams();
  const { _instance: esgReport, refetch: refetchEsgReport } = useEsgReports({
    id: Number(reportId),
  });
  const {
    _instance: taxonomyReport,
    create,
    update,
  } = useTaxonomyReports({
    id: esgReport?.taxonomy_id,
  });

  const [form, setForm] = React.useState<TaxonomyReportInSchema>(initialForm);

  React.useEffect(() => {
    if (taxonomyReport) setForm(taxonomyReport);
  }, [taxonomyReport]);

  // guard
  if (!esgReport) return <PageLoading />;

  const handleFormChange = (key: string, value: string | boolean) =>
    setForm((prevState) => ({ ...prevState, [key]: value }));

  const taxonomyReportExists = esgReport?.taxonomy_id;

  const _refetch = () => {
    refetchEsgReport();
  };

  const handleUpdateClick = () => update.mutateAsync(form).then(_refetch);
  const handleCreateClick = () =>
    create.mutateAsync({ ...form, esg_report_id: esgReport.id }).then(_refetch);

  const currencies = Object.values(CurrencyType); // I think we could fetch the currencies from the backend
  const isModified = (taxonomyReport || initialForm) !== form;

  return (
    <Box sx={{ p: '0px 64px' }}>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <BackButton fontSize="large" />
        <Typography variant="h1">{t('title')}</Typography>
      </StyledFlex>

      {!taxonomyReportExists && (
        <Alert severity="warning">{t('noTaxonomyReport')}</Alert>
      )}

      <StyledPanel sx={{ mt: '48px' }}>
        <NavigationBlockerModal shouldBlock={isModified} />

        <TextField
          required
          fullWidth
          label={t('form.name')}
          value={form.name}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleFormChange('name', e.currentTarget.value)
          }
        />

        <Box sx={{ mt: '24px' }}>
          <Typography>{t('enabledFields')}</Typography>
          {['turnover_enabled', 'capex_enabled', 'opex_enabled'].map(
            (field) => (
              <FormControl key={field}>
                <FormControlLabel
                  label={t(`form.${field}`)}
                  control={
                    <Checkbox
                      checked={!!form[field as keyof TaxonomyReportInSchema]}
                      onChange={(e) =>
                        handleFormChange(
                          field as keyof TaxonomyReportInSchema,
                          e.target.checked
                        )
                      }
                    />
                  }
                />
              </FormControl>
            )
          )}
        </Box>

        <Box mt="24px">
          <FormControl fullWidth required>
            <InputLabel id="currencyTypeSelect">
              {t('form.currency_type')}
            </InputLabel>
            <Select
              fullWidth
              labelId="currencyTypeSelect"
              label={t('form.currency_type')}
              value={form.currency_type}
              onChange={(e) =>
                handleFormChange('currency_type', e.target.value)
              }
            >
              {currencies.map((currency) => (
                <MenuItem key={currency} value={currency}>
                  {currency}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <DialogActions>
          {taxonomyReportExists && (
            <Button disabled={!isModified} onClick={handleUpdateClick}>
              {t('save')}
            </Button>
          )}
          {!taxonomyReportExists && (
            <Button disabled={!isModified} onClick={handleCreateClick}>
              {t('create')}
            </Button>
          )}
        </DialogActions>
      </StyledPanel>
    </Box>
  );
}
