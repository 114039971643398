import React, { useEffect } from 'react';

import { Box, TableHead, TableRow, Typography } from '@mui/material';

import { useEsgUserPermissions } from '../hooks';
import { StyledTableCell, StyledTableStickyCell } from '../styles';
import PageLoading from 'src/components/PageLoading';
import TableCollapsable from './TableCollapsable';
import { useTranslation } from 'react-i18next';
import TableBody from './TableBody';
import QuestionnaireMethodologySelector from 'src/Superadmin/components/Panels/QuestionnaireMethodologySelector';
import { UUID } from 'crypto';
import { useQuestionnaireMethodology } from 'src/Esg/hooks';

interface Props {
  userId: number;
  reportYear: number | null;
}

export default function EsgUserPermissionTable(props: Props) {
  const { userId, reportYear } = props;
  const { t } = useTranslation();
  const { isLoading, options, userOrganizations } =
    useEsgUserPermissions(userId);
  const reports = options.reports.filter((r) => r.period === reportYear);

  const { _data: methodologies, isLoading: methodologyIsLoading } =
    useQuestionnaireMethodology();

  const [questionnaireMethodologyId, setQuestionnaireMethodologyId] =
    React.useState<UUID>(methodologies[0]?.id);
  // Needed to prevent the methodology from changing when the reports are refetched
  //  in the child component
  const [isMethodologyManuallyChanged, setIsMethodologyManuallyChanged] =
    React.useState(false);
  // Reset the methodology setting when the report year changes
  useEffect(() => {
    setIsMethodologyManuallyChanged(false);
  }, [reportYear]);

  // Set methodology based on the reports for the selected period
  useEffect(() => {
    if (isMethodologyManuallyChanged) return;
    // Find last methodology from methodologies list that exists in reports for the selected period
    if (!methodologies) return;
    const methodologyId = methodologies
      .slice()
      .reverse()
      .find((m) =>
        reports.some((r) => r.questionnaire_methodology_id === m.id)
      )?.id;
    if (!methodologyId) return;
    setQuestionnaireMethodologyId(methodologyId);
  }, [reports, methodologies, isMethodologyManuallyChanged]);

  const handleMethodologyChange = (methodologyId: UUID) => {
    setQuestionnaireMethodologyId(methodologyId);
    setIsMethodologyManuallyChanged(true);
  };

  if (isLoading || methodologyIsLoading) return <PageLoading />;
  if (reports.length === 0)
    return (
      <Typography>
        {t(
          'superadmin:settings.tabs.users.usersTable.permissions.other.noReportsAvailable'
        )}
      </Typography>
    );
  if (!userOrganizations.length)
    return (
      <Typography>
        {t(
          'superadmin:settings.tabs.users.usersTable.permissions.other.noOrganizationsReportAccess'
        )}
      </Typography>
    );

  return (
    <>
      <QuestionnaireMethodologySelector
        value={questionnaireMethodologyId}
        onChange={handleMethodologyChange}
      />
      <Box sx={{ overflowX: 'scroll' }}>
        <TableCollapsable isExpanded>
          <TableHead>
            <TableRow>
              <StyledTableStickyCell>
                {t(
                  'superadmin:settings.tabs.users.usersTable.permissions.headers.subtopic'
                )}
              </StyledTableStickyCell>
              {userOrganizations.map((organization) => (
                <StyledTableCell key={`head-${organization.id}`}>
                  {organization.name}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody
            reportYear={reportYear}
            userId={userId}
            questionnaireMethodologyId={questionnaireMethodologyId}
          />
        </TableCollapsable>
      </Box>
    </>
  );
}
