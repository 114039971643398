import { IconButton, SvgIconOwnProps } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

interface Props {
  fontSize?: SvgIconOwnProps['fontSize'];
  navigateTo?: string;
}

export default function BackButton(props: Readonly<Props>) {
  const navigate = useNavigate();
  const { navigateTo, fontSize } = props;
  const handleGoBack = () =>
    typeof navigateTo === 'string' ? navigate(navigateTo) : navigate(-1);
  return (
    <IconButton onClick={handleGoBack}>
      <ChevronLeftIcon fontSize={fontSize} />
    </IconButton>
  );
}
