import React from 'react';
import { Desc, Heading, Title } from './index.styles';
import { StyledTextField } from './productInformation';
import GoalsBasic from './forms/goals-basic';
import Assertions from './forms/assertions';
import ToggleButtonLCA from './ToggleButton';
import { useTranslation } from 'react-i18next';

interface Props {
  values: {
    goals: {
      intended_applications: string;
      study_reasons: string;
      target_audience: string;
      comissioner_name: string;
      external_experts_involved: boolean;
      external_experts_description: string;
      results_disclosed: boolean;
      assertions_disclosed: boolean;
      results_confidential: boolean;
      assertions_confidential: boolean;
      disclosure_description: string;
      comment: string;
    };
  };
  setValues: Function;
}

export default function Goals({ setValues, values }: Props) {
  const { t } = useTranslation('lca', { keyPrefix: 'goals' });
  const handle_external_experts_involved = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({
      ...values,
      goals: {
        ...values.goals,
        external_experts_involved: e.target.value === 'true',
      },
    });
  };
  const handle_external_experts_description = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({
      ...values,
      goals: {
        ...values.goals,
        external_experts_description: e.target.value,
      },
    });
  };

  const handle_comment = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({
      ...values,
      goals: {
        ...values.goals,
        comment: e.target.value,
      },
    });
  };

  const options = [
    { name: t('yes'), value: true },
    { name: t('no'), value: false },
  ];

  return (
    <div>
      <Title>{t('title')}</Title>
      <Desc>{t('desc')}</Desc>
      <Heading>{t('basicInformation')}</Heading>
      <GoalsBasic values={values} setValues={setValues} />
      <Heading>{t('areExternalExpertsOrStakeholdersInvolved')}</Heading>
      <ToggleButtonLCA
        options={options}
        setState={handle_external_experts_involved}
        state={values.goals.external_experts_involved}
      />
      {values.goals.external_experts_involved && (
        <StyledTextField
          style={{ marginTop: 24 }}
          fullWidth
          label={t('nameThem')}
          name="external_experts_description"
          value={values.goals.external_experts_description}
          onChange={handle_external_experts_description}
        />
      )}
      <Heading>{t('discloseResults')}</Heading>
      <Assertions values={values} setValues={setValues} />
      <Heading>{t('additionalInformation')}</Heading>
      <StyledTextField
        fullWidth
        label={t('additionalInformationQuestion')}
        name="comment"
        value={values.goals.comment}
        onChange={handle_comment}
      />
    </div>
  );
}
