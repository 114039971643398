import { useMutation, useQueryClient } from 'react-query';
import { useExportToFileMutation } from 'src/common/hooks';
import { client } from 'src/utils/api-client';

export type LcaProductCopyInSchema = {
  name: string;
};

export const useProductActions = (productId: number) => {
  const queryClient = useQueryClient();
  const url = `/web/lca/products/${productId}`;
  const invalidateProducts = () => {
    queryClient.invalidateQueries({ queryKey: ['lca', 'products'] });
  };

  const copyProduct = useMutation({
    mutationFn: (data: LcaProductCopyInSchema) =>
      client.post(`${url}/copy`, data),
    onSuccess: invalidateProducts,
  });

  const deleteProduct = useMutation({
    mutationFn: () => client.delete(url),
    onSuccess: invalidateProducts,
  });

  const exportToExcel = useExportToFileMutation(`${url}/export-to-excel`);

  return { copyProduct, deleteProduct, exportToExcel };
};
