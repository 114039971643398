import React from 'react';
import { TableRow } from '@mui/material';
import { useParams } from 'react-router-dom';

import { EsgDmaSubTopicHierarchySchema } from '../../../types';
import { useRiskOpportunities } from '../../../hooks';
import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import AddImpactRow from './AddRiskOpportunityRow';
import RiskOpportunityRow from './RiskOpportunityRow';
import { useEsgReports } from 'src/Esg/hooks';
import DeleteCustomSubtopicCell from '../../../components/DeleteCustomSubtopicCell';

interface Props {
  subtopic: EsgDmaSubTopicHierarchySchema;
  color?: string;
}

export default function SubTopicRow(props: Props) {
  const { color, subtopic } = props;
  const [collapsed, setCollapsed] = React.useState(false);
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _data: riskOpportunities } = useRiskOpportunities(
    { esg_dma_id: report?.esg_dma_id },
    { enabled: !!report }
  );

  // subtopic should have at least 1 virtual subsubtopic
  const subSubTopicId = subtopic.subsubtopics[0]?.id;
  const riskOpportunity = riskOpportunities.filter(
    (i) => i.esg_dma_sub_sub_topic_id === subSubTopicId
  );
  return (
    <>
      <TableRow>
        <CollapsableButtonCell
          colSpan={11}
          text={subtopic.description}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          sx={{ padding: '8px 8px 8px 32px' }}
        />
        <DeleteCustomSubtopicCell subtopic={subtopic} />
      </TableRow>

      {!collapsed &&
        riskOpportunity.map((riskOpportunity) => (
          <RiskOpportunityRow
            key={riskOpportunity.id}
            riskOpportunity={riskOpportunity}
            topicColor={color}
          />
        ))}

      {!collapsed && <AddImpactRow subSubTopicId={subSubTopicId} />}
    </>
  );
}
