import {
  PivotTableChart,
  TableRows,
  TableChart,
  Check,
} from '@mui/icons-material';
import {
  Button,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { QuestionTableLayoutSchema } from 'src/QuestionnairesV3/types';

interface Props {
  layout: QuestionTableLayoutSchema;
  // eslint-disable-next-line no-unused-vars
  onClick: (layout: QuestionTableLayoutSchema) => void;
}

const Icons = {
  [QuestionTableLayoutSchema.IN_COLUMNS]: <TableChart />,
  [QuestionTableLayoutSchema.IN_ROWS]: <TableRows />,
};

export default function QuestionTableLayoutMenu(props: Readonly<Props>) {
  const { layout } = props;
  const { t } = useTranslation('legacyGhg', {
    keyPrefix: 'questionnaireV3.questionTable',
  });

  const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);

  const closeMenu = () => setAnchor(null);
  const handleMenuClick = (_layout: QuestionTableLayoutSchema) => {
    closeMenu();
    props.onClick(_layout);
  };

  return (
    <>
      <Button
        sx={{ float: 'right' }}
        startIcon={<PivotTableChart />}
        onClick={(e) => setAnchor(e.currentTarget)}
      >
        {t('layout')}: {t(layout)}
      </Button>
      <Menu
        open={!!anchor}
        anchorEl={anchor}
        onClose={closeMenu}
        sx={{ maxWidth: '400px' }}
      >
        {Object.entries(QuestionTableLayoutSchema).map(([_, _layout]) => (
          <MenuItem
            key={_layout}
            selected={_layout === layout}
            onClick={() => handleMenuClick(_layout)}
          >
            <ListItemAvatar>{Icons[_layout]}</ListItemAvatar>
            <ListItemText primary={t(_layout)} />
            {_layout === layout && (
              <ListItemSecondaryAction>
                <Check />
              </ListItemSecondaryAction>
            )}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
