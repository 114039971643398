import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import themeColors from 'src/theme/colors';
import {
  ExpenditureAnalytics,
  ExpenditureType,
  TaxonomyReportAnalytics,
} from '../../types';
import { toCurrency } from '../../utils';

interface Props {
  type: ExpenditureType;
}
export default function SumTableRow({ type }: Readonly<Props>) {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.contributions.table.footer',
  });

  const { taxonomyReport, reportAnalytics } = useEsgTaxonomy();
  if (!taxonomyReport || reportAnalytics.isLoading) return null;

  const analytics = reportAnalytics._instance?.[
    type.toLocaleLowerCase() as keyof TaxonomyReportAnalytics
  ] as ExpenditureAnalytics;

  return (
    <Table>
      <TableHead>
        <TableRow sx={{ bgcolor: themeColors.backgroundInput }}>
          <TableCell sx={{ width: '50%' }}>Total</TableCell>
          <TableCell sx={{ width: '25%' }} />
          <TableCell sx={{ width: '25%' }} />
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell colSpan={2}>{t('total')}</TableCell>
          <TableCell>
            {toCurrency(
              analytics?.aligned_contribution,
              taxonomyReport.currency_type
            )}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
