import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { ExpenditureType } from '../../../types';

interface Props {
  figure: ExpenditureType;
}

export default function Head({ figure }: Readonly<Props>) {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.dnsh.table.headers',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell
          rowSpan={2}
          sx={{ bgcolor: colors.backgroundInput, width: '5%' }}
        >
          {t('activity')}
        </TableCell>
        <TableCell
          rowSpan={2}
          sx={{ bgcolor: colors.backgroundInput, width: '10%' }}
        />
        <TableCell
          rowSpan={2}
          sx={{ bgcolor: colors.backgroundInput, width: '61%' }}
        />
        <TableCell
          colSpan={2}
          sx={{
            bgcolor: colors.backgroundInput,
            width: '8%',
            textAlign: 'center',
          }}
        >
          {t('a', { figure })}
        </TableCell>
        <TableCell
          colSpan={2}
          sx={{
            bgcolor: colors.backgroundInput,
            width: '8%',
            textAlign: 'center',
          }}
        >
          {t('b', { figure })}
        </TableCell>
        <TableCell
          colSpan={2}
          sx={{
            bgcolor: colors.backgroundInput,
            width: '8%',
            textAlign: 'center',
          }}
        >
          {t('c', { figure })}
        </TableCell>
      </TableRow>
      <TableRow>
        {['a', 'b', 'c'].map((letter) => (
          <React.Fragment key={letter}>
            <TableCell
              sx={{ bgcolor: colors.backgroundInput, textAlign: 'center' }}
            >
              {t('yes')}
            </TableCell>
            <TableCell
              sx={{ bgcolor: colors.backgroundInput, textAlign: 'center' }}
            >
              {t('no')}
            </TableCell>
          </React.Fragment>
        ))}
      </TableRow>
    </TableHead>
  );
}
