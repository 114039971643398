import React from 'react';

import {
  Button,
  Dialog,
  DialogActions,
  IconButton,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';

import { Trans, useTranslation } from 'react-i18next';
import LightTooltip from 'src/components/LightTooltip';
import { StyledDialogTitle, StyledDialogSubtitle } from './index.styles';
import CircleIcon from '@mui/icons-material/Circle';

interface Props {
  value: string | null;
  attention?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChange: (reasoning: string) => void;
  subtitleTranslationKey?: string;
  title: string;
  defaultValue?: string;
}

export default function EditModal(props: Readonly<Props>) {
  const {
    value,
    onChange,
    subtitleTranslationKey,
    attention,
    title,
    defaultValue = '',
  } = props;

  const { t } = useTranslation('esg', {
    keyPrefix: 'dma.selectingStakeholders.table',
  });

  const [open, setOpen] = React.useState<boolean>(false);
  const [text, setText] = React.useState<string>(value || defaultValue);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleSaveClick = () => {
    onChange(text);
    handleClose();
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <LightTooltip title={t('columns.reasoningTooltip')}>
        <IconButton onClick={handleOpen}>
          <EditIcon />
          {attention && (
            <CircleIcon
              sx={{
                fontSize: '10px',
                color: '#A03137',
                position: 'absolute',
                top: '3px',
                right: 0,
              }}
            />
          )}
        </IconButton>
      </LightTooltip>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <IconButton
          sx={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
          onClick={handleClose}
        >
          <CloseIcon sx={{ fontSize: '24px' }} />
        </IconButton>

        <StyledDialogTitle>{title}</StyledDialogTitle>
        {subtitleTranslationKey && (
          <StyledDialogSubtitle>
            <Trans i18nKey={subtitleTranslationKey} />
          </StyledDialogSubtitle>
        )}
        <TextField
          sx={{ p: '0px 24px' }}
          fullWidth
          multiline
          rows={6}
          placeholder={t('modals.intro.placeholder')}
          value={text}
          onChange={(e) => setText(e.target.value)}
        />

        <DialogActions sx={{ p: '24px' }}>
          <Button variant="secondary" onClick={handleClose}>
            {t('modals.reasoning.buttons.cancel')}
          </Button>
          <Button className="primaryButton" onClick={handleSaveClick}>
            {t('modals.reasoning.buttons.save')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
