import React from 'react';
import { UUID } from 'crypto';
import { Theme, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';

import qv3Theme from './theme';
import Questionnaire from './Questionnaire';
import Qv3AuthProvider from './components/AuthProvider';
import Qv3ContextProvider from './context';
import { Qv3Options } from './types';

interface Props {
  questionnaireId: UUID;
  questionnaireAnswerId: UUID;
  tokenFetcher: () => Promise<string | undefined>;
  theme?: Theme;
  options?: Qv3Options;
}

export default function QuestionnaireV3(props: Readonly<Props>) {
  const { questionnaireId, questionnaireAnswerId, tokenFetcher, theme } = props;

  // rendering guards
  if (!questionnaireId) return null;
  if (!questionnaireAnswerId) return null;

  return (
    <ThemeProvider theme={deepmerge(qv3Theme, theme)}>
      <Qv3AuthProvider tokenFetcher={tokenFetcher}>
        <Qv3ContextProvider
          questionnaireId={questionnaireId}
          questionnaireAnswerId={questionnaireAnswerId}
          options={props.options}
        >
          <Questionnaire />
        </Qv3ContextProvider>
      </Qv3AuthProvider>
    </ThemeProvider>
  );
}
