import React from 'react';
import { useTranslation } from 'react-i18next';
import { FigureType } from '../types';

import ViewContainer from '../components/ViewContainer';
import TaxonomyTabPanel from '../components/TaxonomyTabPanel';
import ObjectiveTable from './ObjectiveTable';

export default function Objectives() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.objectives' });
  const [tab, setTab] = React.useState<FigureType>();

  return (
    <ViewContainer title={t('title')}>
      <TaxonomyTabPanel
        tab={tab}
        onTabChange={setTab}
        turnoverComponent={<ObjectiveTable figure={FigureType.Turnover} />}
        capexComponent={<ObjectiveTable figure={FigureType.Capex} />}
        opexComponent={<ObjectiveTable figure={FigureType.Opex} />}
      />
    </ViewContainer>
  );
}
