import React from 'react';

import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

interface Props {
  onClick: () => void;
}

export default function NewAnswerButton(props: Readonly<Props>) {
  const { t } = useTranslation('legacyGhg', {
    keyPrefix: 'questionnaireV3.questionTable',
  });
  return (
    <ListItem disablePadding>
      <ListItemButton onClick={props.onClick}>
        <ListItemIcon>
          <Add />
        </ListItemIcon>
        <ListItemText primary={t('createNewAnswer')} />
      </ListItemButton>
    </ListItem>
  );
}
