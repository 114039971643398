import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';
import React from 'react';
import { useQuestionTable, useQuestionAnswers } from '../../../../hooks';
import { UUID } from 'crypto';
import NewAnswerRow from './NewAnswer';
import Answer from './Answer';
import NoData from '../../Rows/NoData';
import TableRowWithDimensions from '../../components/TableRowWithDimensions';

interface Props {
  questionId: UUID;
}

export default function QuestionTableInColumns(props: Readonly<Props>) {
  const { questionId } = props;
  const { _data } = useQuestionAnswers({ question_id: questionId });
  const { question, fields, dimensions } = useQuestionTable(questionId);

  if (!question) return null;

  const tableSx = {
    minWidth: `${160 * fields.length}px`,
    tableLayout: 'fixed',
  };

  return (
    <TableContainer sx={{ overflowX: 'auto' }}>
      <Table sx={tableSx}>
        <TableHead>
          <TableRowWithDimensions dimensionsCount={dimensions.length}>
            {fields.map((field) => (
              <TableCell key={field.id} sx={{ minWidth: '120px' }}>
                {field.title}
              </TableCell>
            ))}
          </TableRowWithDimensions>
        </TableHead>
        <TableBody>
          {_data.length === 0 && <NoData colSpan={fields.length} />}
          {_data.map((answer) => (
            <Answer
              key={answer.id}
              questionId={questionId}
              questionAnswerId={answer.id}
            />
          ))}
          <NewAnswerRow questionId={questionId} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
