import React from 'react';
import { TableRow } from '@mui/material';

interface Props {
  dimensionsCount: number;
  children: React.ReactNode;
}
export default function TableRowWithDimensions(props: Readonly<Props>) {
  const { dimensionsCount, children } = props;
  return (
    <TableRow
      sx={{
        [`& > :nth-of-type(-n+${dimensionsCount})`]: {
          backgroundColor: '#fafafa',
        },
        [`& > :nth-of-type(${dimensionsCount})`]: {
          borderRight: '1px solid #e0e0e0',
        },
      }}
    >
      {children}
    </TableRow>
  );
}
