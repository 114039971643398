import React from 'react';
import DOMPurify from 'dompurify';

import { Radio, TableCell, TableRow, Typography } from '@mui/material';
import { UUID } from 'crypto';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import { FigureType } from '../../../types';

interface Props {
  reportActivityId: UUID;
  objectiveId: UUID;
}

export default function ObjectiveRow({
  reportActivityId,
  objectiveId,
}: Readonly<Props>) {
  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activityObjective = queries.activityObjectives._data.find(
    (ao) =>
      ao.report_activity_id === reportActivityId &&
      ao.type === FigureType.Turnover
  );
  const dnsh = queries.turnoverDNSH._data.find(
    (t) =>
      t.report_activity_id === reportActivityId &&
      t.objective_id === objectiveId
  );

  const handleChange = (isCompliant: boolean) =>
    dnsh
      ? queries.turnoverDNSH.update
          .mutateAsync({
            ...dnsh,
            is_compliant: isCompliant,
          })
          .then(() => {
            reportAnalytics.refetch();
          })
      : queries.turnoverDNSH.create
          .mutateAsync({
            report_activity_id: reportActivityId,
            objective_id: objectiveId,
            is_compliant: isCompliant,
          })
          .then(() => {
            reportAnalytics.refetch();
          });

  if (queries.isLoading || !activityObjective) return null;

  const mainCriteria = queries.mainCriterias._data.find(
    (mc) =>
      mc.activity_id === reportActivity.activity_id &&
      mc.objective_id === activityObjective.objective_id
  );
  const dnshCriteria = queries.dnshCriterias._data.find(
    (dc) =>
      dc.main_criteria_id === mainCriteria?.id &&
      dc.objective_id === objectiveId
  );

  if (!dnshCriteria) return null;

  return (
    <TableRow>
      <TableCell />
      <TableCell>{queries.objectives._dataMap[objectiveId].label}</TableCell>
      <TableCell>
        <Typography
          variant="captionText"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(dnshCriteria.criteria),
          }}
        />
      </TableCell>
      <TableCell>
        <Radio
          key={`${dnsh?.id}-compliant`}
          checked={!!dnsh && dnsh.is_compliant}
          onClick={() => handleChange(true)}
        />
      </TableCell>
      <TableCell>
        <Radio
          key={`${dnsh?.id}-non-compliant`}
          checked={!!dnsh && !dnsh.is_compliant}
          onClick={() => handleChange(false)}
        />
      </TableCell>
    </TableRow>
  );
}
