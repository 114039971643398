import React from 'react';
import { Table, TableBody, TableContainer, Typography } from '@mui/material';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import { ExpenditureType } from '../../../types';
import Head from './Head';
import ActivityRow from './ActivityRow';
import { useTranslation } from 'react-i18next';

interface Props {
  type: ExpenditureType;
}

export default function ExpenditureTable({ type }: Readonly<Props>) {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.dnsh.table' });

  const { queries } = useEsgTaxonomy();
  if (queries.isLoading) return null;

  const reportActivities = queries.reportActivities._data.filter((ra) => {
    if (type === ExpenditureType.Capex) return ra.capex_enabled;
    else return ra.opex_enabled;
  });

  return (
    <>
      <Typography>{t('text')}</Typography>
      <br />
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table stickyHeader>
          <Head figure={type} />
          <TableBody>
            {reportActivities.map((activity) => (
              <ActivityRow
                key={activity.id}
                type={type}
                reportActivityId={activity.id}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
