import React from 'react';
import { useTranslation } from 'react-i18next';

import { FigureType } from '../types';
import ViewContainer from '../components/ViewContainer';
import TaxonomyTabPanel from '../components/TaxonomyTabPanel';
import OverviewTable from './OverviewTable';

export default function Overview() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.overview' });
  const [tab, setTab] = React.useState<FigureType>();

  return (
    <ViewContainer title={t('title')}>
      <TaxonomyTabPanel
        tab={tab}
        onTabChange={setTab}
        turnoverComponent={<OverviewTable figure={FigureType.Turnover} />}
        capexComponent={<OverviewTable figure={FigureType.Capex} />}
        opexComponent={<OverviewTable figure={FigureType.Opex} />}
      />
    </ViewContainer>
  );
}
