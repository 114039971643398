import { Tab, Tabs, TabsProps } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import { FigureType } from '../types';

interface Props extends TabsProps {
  isInsideStyledPanel?: boolean;
}

export default function FigureTabs(props: Readonly<Props>) {
  const { isInsideStyledPanel, ..._props } = props;
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.activities' });
  const { taxonomyReport } = useEsgTaxonomy();

  const sx = !isInsideStyledPanel
    ? { ...(props.sx || {}) }
    : {
        m: '-36px -32px',
        mb: '24px',
        bgcolor: '#e0e0e0',
        border: '1px solid #e0e0e0',
        borderTopLeftRadius: '16px',
        borderTopRightRadius: '16px',
        ...(props.sx || {}),
      };

  return (
    <Tabs {..._props} sx={sx}>
      {Object.entries(FigureType).map(([_, figure]) => (
        <Tab
          key={figure}
          label={t(figure)}
          id={figure}
          value={figure}
          disabled={
            // rednering all tabs,
            // disabled if the taxonomy_report.[figure]_enabled is false
            taxonomyReport?.[
              `${figure.toLowerCase()}_enabled` as keyof typeof taxonomyReport
            ] === false
          }
        />
      ))}
    </Tabs>
  );
}
