import React from 'react';
import { List } from '@mui/material';
import { useDmaHierarchy } from 'src/Esg/Dmav2/hooks';
import Topic from './Topic';

interface Props {
  esg_stakeholder_data_id: number;
}

export default function HierarchySelector(props: Readonly<Props>) {
  const { esg_stakeholder_data_id } = props;
  const { topics } = useDmaHierarchy({});
  return (
    <List>
      {topics.map((topic) => (
        <Topic
          key={topic.id}
          esg_stakeholder_data_id={esg_stakeholder_data_id}
          topic_id={topic.id}
        />
      ))}
    </List>
  );
}
