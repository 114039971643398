import React from 'react';
import DmaPanel from './components/DmaPanel';
import { useLanguage } from 'src/common/hooks';
import { StyledFlex } from 'src/components/StyledFlex';
import {
  Stage0,
  Stage1,
  Stage2,
  Stage3,
  Stage4,
  Stage5,
  Stage6,
  Stage7,
} from './Stages';
import { useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useEsgReports } from '../hooks';
import { useEsgDmas } from './hooks';
import PageLoading from 'src/components/PageLoading';
import SomethingWentWrong from 'src/components/SomethingWentWrong';

export default function Dmav2() {
  const { t } = useLanguage({ keyPrefix: 'esg:dma' });
  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma, isLoading } = useEsgDmas(
    { id: report?.esg_dma_id },
    { enabled: !!report }
  );
  if (isLoading) return <PageLoading />;
  if (!dma) return <SomethingWentWrong />;

  return (
    <>
      <StyledFlex sx={{ justifyContent: 'flex-end' }}>
        <Typography variant="h6">Version: {dma.version}</Typography>
      </StyledFlex>

      <DmaPanel title={t('panels.introduction')}>
        <Stage0 />
      </DmaPanel>
      <br />

      <DmaPanel title={t('panels.dataCollectionAndAnalysis')}>
        <Stage1 />
        <br />
        <Stage2 />
        <br />
        <Stage3 />
        <br />
        <Stage4 />
      </DmaPanel>
      <br />

      <DmaPanel title={t('panels.doubleMaterialityAssessment')}>
        <Stage5 />
        <br />
        <Stage6 />
        <br />
        {/* NOTE temporary disabled, used to stage 7 */}
        {/* <RelevantDataPointsStage />
        <br /> */}
        <Stage7 />
      </DmaPanel>
    </>
  );
}
