import React from 'react';
import {
  Button,
  Menu,
  MenuItem,
  Dialog,
  TextField,
  Box,
  Typography,
  Divider,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { StyledCustomSelectValue, StyledNullComponent } from './index.styles';
import { t } from 'i18next';
import { defaultColors } from './utils';
import { useTranslation } from 'react-i18next';

type optionType = {
  label: string;
  selectedLabel?: string;
  value: any;
  isCustom?: boolean;
};

interface CustomOptionsProps {
  label: string;
  // eslint-disable-next-line no-unused-vars
  onChangeCustomOption: (value: any) => void;
  // eslint-disable-next-line no-unused-vars
  onDeleteCustomOption: (value: any) => void;
}

type ColorsType = {
  [key: string]: {
    main: string;
    contrastText: string;
  };
};

interface AddCustomOptionDialogContentProps
  extends Omit<CustomOptionsProps, 'onDeleteCustomOption'> {
  // eslint-disable-next-line no-unused-vars
  setCustomAddOptionOpen: (value: boolean) => void;
}

function AddCustomOptionDialogContent(
  props: AddCustomOptionDialogContentProps
) {
  const { t } = useTranslation('esg');
  const { label, onChangeCustomOption, setCustomAddOptionOpen } = props;
  const [customOptionValue, setCustomOptionValue] = React.useState('');

  return (
    <>
      <Typography variant="h6">{label}</Typography>
      <TextField
        value={customOptionValue}
        onChange={(e) => setCustomOptionValue(e.target.value)}
        sx={{ mt: '16px' }}
      />
      <Box display="flex" sx={{ mt: '16px', justifyContent: 'space-between' }}>
        <Button
          variant="secondary"
          onClick={() => setCustomAddOptionOpen(false)}
        >
          {t('cancel')}
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            onChangeCustomOption(customOptionValue);
            setCustomOptionValue('');
            setCustomAddOptionOpen(false);
          }}
          sx={{ mr: '8px' }}
        >
          {t('add')}
        </Button>
      </Box>
    </>
  );
}

interface Props {
  key?: string;
  options: optionType[];
  value: any;
  // eslint-disable-next-line no-unused-vars
  onChange?: (value: any) => void;
  disabled?: boolean;
  fullWidth?: boolean;
  maxWidth?: string;
  menuMaxHeight?: string;
  colors?: ColorsType;
  customOptionsProps?: CustomOptionsProps;
}
export default function CustomSelect(props: Props) {
  const {
    key,
    fullWidth,
    disabled = false,
    colors = defaultColors,
    customOptionsProps,
  } = props;
  const { options, value, onChange = () => {} } = props;

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [customAddOptionOpen, setCustomAddOptionOpen] = React.useState(false);

  const selectedOption = options.find((opt) => opt.value === value);
  const handleClose = () => setAnchorEl(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement>) =>
    !disabled && setAnchorEl(event.currentTarget);

  const nullLabel = disabled
    ? t('esg:common.customSelect.disabled')
    : t('esg:common.select');

  return (
    <center style={{ opacity: disabled ? 0.4 : 1, width: '100%' }}>
      {value === null ? (
        <StyledNullComponent onClick={handleOpen} disabled={disabled}>
          {nullLabel}
          <ArrowDropDownIcon />
        </StyledNullComponent>
      ) : (
        <Button
          disabled={disabled}
          id={key}
          disableElevation
          onClick={handleOpen}
          variant="secondary"
          size="small"
          sx={{
            textTransform: 'none',
            borderRadius: '20px',
            height: '40px',
            minWidth: '40px',
            maxWidth: props.maxWidth,
            whiteSpace: 'nowrap',
            backgroundColor: colors[value]?.main,
            color: colors[value]?.contrastText,
            // No background change on hover
            '&:hover': { backgroundColor: colors[value]?.main },
          }}
          fullWidth={fullWidth}
        >
          <StyledCustomSelectValue sx={{ color: colors[value]?.contrastText }}>
            {selectedOption?.selectedLabel ?? selectedOption?.label}
          </StyledCustomSelectValue>
        </Button>
      )}
      <Menu
        id={key}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        sx={{ maxHeight: props.menuMaxHeight }}
      >
        {options.map((option) => (
          <MenuItem
            key={option.value}
            sx={{ minWidth: '150px', whiteSpace: 'wrap', flex: 1 }}
            disableRipple
          >
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Box
                flexGrow={1}
                onClick={() => {
                  onChange(option.value);
                  handleClose();
                }}
              >
                {option.label}
              </Box>
              {option.isCustom && customOptionsProps && (
                <ClearOutlinedIcon
                  fontSize="small"
                  onClick={() =>
                    customOptionsProps.onDeleteCustomOption(option.value)
                  }
                />
              )}
            </Box>
          </MenuItem>
        ))}
        {customOptionsProps && (
          <Box>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                setCustomAddOptionOpen(true);
              }}
              sx={{ minWidth: '150px', whiteSpace: 'wrap' }}
              disableRipple
            >
              <AddOutlinedIcon />
              {customOptionsProps.label}
            </MenuItem>
          </Box>
        )}
      </Menu>
      {customOptionsProps && (
        <Dialog
          open={customAddOptionOpen}
          onClose={() => setCustomAddOptionOpen(false)}
        >
          <AddCustomOptionDialogContent
            {...customOptionsProps}
            setCustomAddOptionOpen={setCustomAddOptionOpen}
          />
        </Dialog>
      )}
    </center>
  );
}
