import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import { useEsgTaxonomy } from 'src/Esg/hooks';

export default function Head() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.selection.header',
  });
  const { queries, taxonomyReport } = useEsgTaxonomy();

  if (queries.isLoading) return null;

  return (
    <TableHead>
      <TableRow>
        <TableCell
          sx={{
            bgcolor: colors.backgroundInput,
            width: '30%',
            minWidth: '210px',
            textAlign: 'center',
          }}
        >
          {t('activity')}
        </TableCell>
        <TableCell
          sx={{
            bgcolor: colors.backgroundInput,
            width: '50%',
            minWidth: '350px',
            textAlign: 'center',
          }}
        >
          {t('description')}
        </TableCell>
        {taxonomyReport?.turnover_enabled && (
          <TableCell
            sx={{
              bgcolor: colors.backgroundInput,
              width: '100px',
              textAlign: 'center',
            }}
          >
            {t('turnover')}
          </TableCell>
        )}
        {taxonomyReport?.capex_enabled && (
          <TableCell
            sx={{
              bgcolor: colors.backgroundInput,
              width: '100px',
              textAlign: 'center',
            }}
          >
            {t('capex')}
          </TableCell>
        )}
        {taxonomyReport?.opex_enabled && (
          <TableCell
            sx={{
              bgcolor: colors.backgroundInput,
              width: '100px',
              textAlign: 'center',
            }}
          >
            {t('opex')}
          </TableCell>
        )}
        {/* Delete icon column */}
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '56px' }} />
      </TableRow>
    </TableHead>
  );
}
