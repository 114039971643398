import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from '@mui/material';

import { UUID } from 'crypto';
import { useTranslation } from 'react-i18next';
import { countFigureTypes } from 'src/Esg/Taxonomy/utils';
import { useEsgTaxonomy } from 'src/Esg/hooks';

export default function AddSectorRow() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.selection' });
  const { taxonomyId, taxonomyReport, queries } = useEsgTaxonomy();

  const handleSave = (value: unknown) =>
    queries.reportSectors.create.mutateAsync({
      report_id: taxonomyId,
      sector_id: value as UUID,
    });

  if (queries.isLoading) return null;

  const reportSectorIds = queries.reportSectors._data.map((ra) => ra.sector_id);
  const options = queries.sectors._data.filter(
    (a) => !reportSectorIds.includes(a.id)
  );

  return (
    <TableRow>
      <TableCell colSpan={3 + countFigureTypes(taxonomyReport)}>
        <FormControl sx={{ minWidth: '250px' }}>
          <InputLabel id="sector">{t('addSector')}</InputLabel>
          <Select
            labelId="sector"
            label={t('addSector')}
            value=""
            onChange={(e) => handleSave(e.target.value)}
            placeholder={t('addSector')}
          >
            {options.map((sector) => (
              <MenuItem key={sector.id} value={sector.id}>
                {sector.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
    </TableRow>
  );
}
