import React from 'react';
import { useTranslation } from 'react-i18next';
import { FigureType } from '../types';

import ViewContainer from '../components/ViewContainer';
import TaxonomyTabPanel from '../components/TaxonomyTabPanel';
import Turnover from './Turnover';
import Capex from './Capex';
import Opex from './Opex';

export default function Contributions() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.contributions' });
  const [tab, setTab] = React.useState<FigureType>();

  return (
    <ViewContainer title={t('title')}>
      <TaxonomyTabPanel
        tab={tab}
        onTabChange={setTab}
        turnoverComponent={<Turnover />}
        capexComponent={<Capex />}
        opexComponent={<Opex />}
      />
    </ViewContainer>
  );
}
