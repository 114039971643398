import React from 'react';
import { useTranslation } from 'react-i18next';

import { FigureType } from '../types';
import Capex from './Capex';
import Opex from './Opex';
import Turnover from './Turnover';
import ViewContainer from '../components/ViewContainer';
import TaxonomyTabPanel from '../components/TaxonomyTabPanel';

export default function Activities() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.activities' });
  const [tab, setTab] = React.useState<FigureType>();

  return (
    <ViewContainer title={t('title')}>
      <TaxonomyTabPanel
        tab={tab}
        onTabChange={setTab}
        turnoverComponent={<Turnover />}
        capexComponent={<Capex />}
        opexComponent={<Opex />}
      />
    </ViewContainer>
  );
}
