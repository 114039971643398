import React from 'react';
import PageLoading from 'src/components/PageLoading';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import { FigureType } from '../../types';
import TotalValue from '../components/TotalValue';
import TurnoverTable from './TurnoverTable';

export default function Turnover() {
  const { taxonomyReport } = useEsgTaxonomy();
  if (!taxonomyReport) return <PageLoading />;

  return (
    <>
      <TotalValue figure={FigureType.Turnover} />
      <br />
      <TurnoverTable />
    </>
  );
}
