import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';

export default function Head() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.objectives.table.headers',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '25%' }}>
          {t('activity')}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '15%' }}>
          {t('objective')}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '60%' }}>
          {t('criteria')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
