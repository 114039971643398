import React from 'react';
import TotalValue from '../components/TotalValue';
import { ExpenditureType, FigureType } from '../../types';
import ExpenditureTable from '../components/ExpenditureTable';
import { StyledPanel } from 'src/components/StyledPanel';
import { Typography } from '@mui/material';

export default function Capex() {
  return (
    <>
      <StyledPanel sx={{ mb: '32px', border: '4px solid #e9bc46' }}>
        <Typography variant="h6">
          The Capex KPI is defined as the share of Taxonomy-aligned capital
          expenditures (Capex) in the numerator, divided into three categories
          (a – c) as defined in the Disclosures Delegated Act, divided by the
          total Capex (in the denominator).
        </Typography>
        <Typography variant="captionText" component="li">
          Type A: related to assets or processes that are associated with
          Taxonomy-aligned economic activities (where turnover is aligned);
        </Typography>
        <Typography variant="captionText" component="li">
          Type B: part of a plan to expand Taxonomy-aligned economic activities
          or to allow Taxonomy-eligible economic activities to become
          Taxonomy-aligned under conditions specified in the Delegated Act
          (where turnover is eligible but not aligned); and
        </Typography>
        <Typography variant="captionText" component="li">
          Type C: Related to the purchase of output from Taxonomy-aligned
          economic activities and individual measures enabling the target
          activities to become low-carbon or to lead to greenhouse gas
          reductions, provided that such measures are implemented and
          operational within 18 months (where turnover is not eligible nor
          aligned).
        </Typography>
      </StyledPanel>
      <TotalValue figure={FigureType.Capex} />
      <br />
      <ExpenditureTable type={ExpenditureType.Capex} />
    </>
  );
}
