import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';

interface MfaDialogProps {
  onSubmit: (mfaCode: string) => void;
  onCancel: () => void;
}

export default function MfaDialog({ onSubmit, onCancel }: MfaDialogProps) {
  const [value, setValue] = React.useState<string>('');
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { t } = useTranslation('superadmin', { keyPrefix: 'user.mfa' });

  const handleSubmitMutation = useMutation(
    async () => {
      setIsLoading(true);
    },
    {
      onSuccess: () => {
        onSubmit(value);
      },
    }
  );

  return (
    <Dialog open>
      <DialogTitle>{t('dialogTitle')}</DialogTitle>
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: '1em',
        }}
      >
        <DialogContentText>{t('dialogText')}</DialogContentText>
        <TextField
          label={t('code')}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          disabled={isLoading}
        />
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          width="100%"
        >
          <Button onClick={onCancel} variant="secondary" disabled={isLoading}>
            {t('cancelButton')}
          </Button>
          <Button
            onClick={() => handleSubmitMutation.mutate()}
            disabled={!value.trimStart() || isLoading}
            endIcon={
              isLoading && <CircularProgress color="secondary" size={20} />
            }
          >
            {t('confirmButton')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
