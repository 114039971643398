import React from 'react';
import { useTranslation } from 'react-i18next';
import { client } from '../../../../utils/api-client';
import { Box, Switch } from '@mui/material';
import MfaDialog from 'src/components/MfaDialog';
import { toast } from 'react-toastify';

interface Props {
  isMfaEnabled?: boolean;
}

export default function MfaToggle({ isMfaEnabled }: Readonly<Props>) {
  const [mfaTokenId, setMfaTokenId] = React.useState<string | null>(null);
  const mfaDialogOpen = mfaTokenId !== null;

  const { t } = useTranslation('superadmin', { keyPrefix: 'user.mfa' });

  const onChange = () => {
    client.post('web/settings_v2/my-account/generate-mfa-token').then((res) => {
      setMfaTokenId(res.data);
    });
  };

  const submit = (mfaCode: string) => {
    client
      .put(
        `web/settings_v2/my-account/set-mfa?is_mfa_enabled=${!isMfaEnabled}`,
        {},
        {
          headers: {
            'x-mfa-token-id': mfaTokenId,
            'x-mfa-code': mfaCode,
          },
          validateStatus: null, // we do not want default interceptors
        }
      )
      .then((response) => {
        if (response.status >= 400) {
          toast.error(response.data?.detail);
          setMfaTokenId(null);
        } else {
          toast.success(t('settingsUpdated') as string);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      });
  };

  return (
    <Box>
      <Switch onChange={onChange} checked={isMfaEnabled} />
      {t('name')}
      {mfaDialogOpen && (
        <MfaDialog onSubmit={submit} onCancel={() => setMfaTokenId(null)} />
      )}
    </Box>
  );
}
