import React from 'react';
import { Qv3Options, Qv3Context as Qv3ContextType } from './types';
import { UUID } from 'crypto';

export const Qv3Context = React.createContext<Qv3ContextType>({
  questionnaireAnswerId: undefined,
  questionnaireId: undefined,
  options: { hints: {} },
  modifiedQuestionAnswers: {
    ids: [],
    add: () => {},
    remove: () => {},
  },
});

interface Props {
  children: React.ReactNode;
  questionnaireId: UUID;
  questionnaireAnswerId: UUID;
  options?: Qv3Options;
}

// This Context is the questionnaire context
// It provides the questionnaireId and questionnaireAnswerId
// It also provides the queries for questionnaires, questions, fields, fieldAnswers
// It also provides the values (middle state) of the field answers and utility functions to manipulate the values
export default function Qv3ContextProvider(props: Readonly<Props>) {
  const { questionnaireId, questionnaireAnswerId, options } = props;
  const [modifiedQuestionAnswers, setModifiedQuestionAnswers] = React.useState<
    number[]
  >([]);

  const _context = {
    questionnaireAnswerId,
    questionnaireId,
    options,
    modifiedQuestionAnswers: {
      ids: modifiedQuestionAnswers,
      add: (questionAnswerId: number) =>
        !modifiedQuestionAnswers.includes(questionAnswerId) &&
        setModifiedQuestionAnswers((prev) => [...prev, questionAnswerId]),
      remove: (questionAnswerId: number) =>
        setModifiedQuestionAnswers((prev) =>
          prev.filter((id) => id !== questionAnswerId)
        ),
    },
  };
  const context = React.useMemo(() => _context, [_context]);

  return (
    <Qv3Context.Provider value={context}>{props.children}</Qv3Context.Provider>
  );
}
