import { Button, DialogActions } from '@mui/material';
import { LcaProductCopyInSchema, useProductActions } from './hooks';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ModalBase } from 'src/components/ConfirmationModal';
import TextField from 'src/components/TextField';
import {
  SchemaErrors,
  extractSchemaErrors,
  mapError,
} from 'src/utils/validation';
import { toast } from 'react-toastify';

interface CopyProductModalProps {
  open: boolean;
  onClose?: () => void;
  productId: number;
  productName: string;
}

type FormState = LcaProductCopyInSchema;
type FormErrors = SchemaErrors<FormState>;

export default function CopyProductModal(props: CopyProductModalProps) {
  const actions = useProductActions(props.productId);
  const { t } = useTranslation('lca');
  const placeholderName = t('product.modals.copy.inputs.name.placeholder', {
    name: props.productName,
  });

  const [form, setForm] = useState<FormState>({ name: '' });
  const [formErrors, setFormErrors] = useState<FormErrors>({});

  React.useEffect(() => {
    // reset form state
    setForm({ name: placeholderName });
    setFormErrors({});
  }, [props.open]);

  const submit = () => {
    setFormErrors({});
    actions.copyProduct
      .mutateAsync(form)
      .then(() => {
        toast.success(t('product.modals.copy.success') as string);
        props.onClose?.();
      })
      .catch((error) => {
        const errors = extractSchemaErrors(error, form);
        setFormErrors(errors);
      });
  };

  return (
    <ModalBase
      open={props.open}
      onClose={props.onClose}
      titleKey="lca:product.modals.copy.title"
    >
      <TextField
        label={t('product.modals.copy.inputs.name.label')}
        placeholder={placeholderName}
        value={form.name}
        onChange={(e) => setForm({ ...form, name: e.target.value })}
        errorText={
          mapError(formErrors, 'name', form.name) &&
          t('product.modals.copy.inputs.name.error') // TODO: temporary, until we have proper schema error messages
        }
        sx={{ width: '100%' }}
      />
      <DialogActions
        sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: '20px',
          paddingBottom: 0,
        }}
      >
        <Button
          onClick={props.onClose}
          variant="secondary"
          sx={{ minWidth: '242px' }}
        >
          <Trans i18nKey="common.cancel" />
        </Button>
        <Button onClick={submit} sx={{ minWidth: '242px' }}>
          <Trans i18nKey="lca:product.modals.copy.buttons.submit" />
        </Button>
      </DialogActions>
    </ModalBase>
  );
}
