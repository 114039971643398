import React from 'react';
import { InputProps } from '../../types';

import NumberField from 'src/components/NumberField';

export default function DecimalTextField(props: Readonly<InputProps>) {
  const { value, onChange, error, field } = props;

  return (
    <NumberField
      fullWidth
      value={value ?? ''}
      label={field.label}
      onChange={(value, event) => onChange?.(value, event)}
      error={!!error}
      helperText={error}
      // disabled={disabled}
      placeholder={field.placeholder}
      sx={{
        '& .MuiOutlinedInput-root': {
          backgroundColor: value ? '#F2F2F2' : 'inherit',
        },
      }}
    />
  );
}
