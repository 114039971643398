import React from 'react';
import { MenuItem } from '@mui/material';
import { LCAProcess, LcaSubProductInSchema } from '../../index.types';
import {
  StyledLine,
  StyledNumberField,
  StyledRow,
  StyledSectionName,
  StyledSelect,
  StyledTextField,
} from '../index.styles';
import { useLcaDiagram } from 'src/Lca/hooks';
import { useQuery } from 'react-query';
import { client } from 'src/utils/api-client';
import { FormErrors } from './FormValidation';
import { mapError } from 'src/utils/validation';
import ProcessesSearchField from '../../components/ProcessesSearchField';
import { useTranslation } from 'react-i18next';

interface Props {
  form: Partial<LcaSubProductInSchema>;
  setForm: React.Dispatch<React.SetStateAction<Partial<LcaSubProductInSchema>>>;
  errors: FormErrors;
}

export default function SubProductForm(props: Props) {
  const { t } = useTranslation('lca', { keyPrefix: 'process' });
  const { form, errors, setForm } = props;

  const isError = (fieldName: keyof LcaSubProductInSchema) =>
    !!form[fieldName] && !!mapError(errors, fieldName, form[fieldName]);
  const errorText = (fieldName: keyof LcaSubProductInSchema) =>
    isError(fieldName) && mapError(errors, fieldName, form[fieldName] || '');

  const { stagesAsOptions } = useLcaDiagram(form.product_id);
  const getProcesses = useQuery(
    `lca-product-${form.product_id}-processes`,
    () =>
      client
        .get<LCAProcess[]>(`/web/lca/processes/${form.product_id}`)
        .then((response) => response.data)
  );
  const processOptions = getProcesses.data?.filter(
    (process: LCAProcess) => !form.stage || process.stage === form.stage
  );

  const handleProcessChange = (newProcess: number | string) => {
    const process = processOptions?.find(
      (process: LCAProcess) => process.id === newProcess
    );
    setForm({
      ...form,
      parent_process_id: process?.id,
      stage: process?.stage,
    });
  };

  const handleStageChange = (newStage: string) => {
    if (newStage === form.stage) return;
    setForm({
      ...form,
      stage: newStage,
      parent_process_id: undefined,
    });
  };

  return (
    <>
      <StyledSectionName>{t('subProductDetails')}</StyledSectionName>
      <StyledRow>
        <ProcessesSearchField
          value={form.sub_product_id}
          onChange={(value) =>
            setForm({ ...form, sub_product_id: value as number })
          }
        />
      </StyledRow>
      <StyledRow>
        <StyledTextField
          fullWidth
          name="name"
          value={form.name}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          error={isError('name')}
          helperText={errorText('name')}
          label={t('optionalCustomName')}
        />
      </StyledRow>
      <StyledRow>
        <StyledNumberField
          fullWidth
          name="amount"
          type="number"
          value={form.amount || null}
          onChange={(value) => setForm({ ...form, amount: value as number })}
          error={isError('amount')}
          helperText={errorText('amount')}
          label={t('amount')}
        />
      </StyledRow>

      <StyledRow>
        <StyledTextField
          fullWidth
          multiline
          minRows={2}
          name="comment"
          value={form.comment}
          onChange={(e) => setForm({ ...form, comment: e.target.value })}
          error={isError('comment')}
          helperText={errorText('comment')}
          label={t('optionalComment')}
        />
      </StyledRow>

      <StyledLine />

      <StyledSectionName>{t('lifeCyclePhases')}</StyledSectionName>
      <StyledRow>
        <StyledSelect
          fullWidth
          name="stage"
          value={form.stage || ''}
          onChange={(e: React.ChangeEvent<{ value: string }>) =>
            handleStageChange(e.target.value)
          }
          error={isError('stage')}
          label={t('lifeCyclePhase')}
        >
          <MenuItem value="">
            <em>{t('none')}</em>
          </MenuItem>
          {stagesAsOptions.map(({ value, label }) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </StyledSelect>
        <div style={{ width: 20 }} />
        <StyledSelect
          fullWidth
          name="process"
          value={form.parent_process_id || ''}
          onChange={(e: React.ChangeEvent<{ value: string }>) =>
            handleProcessChange(e.target.value)
          }
          error={isError('parent_process_id')}
          label={t('optionalAddToProcess')}
        >
          <MenuItem value="">
            <em>{t('none')}</em>
          </MenuItem>
          {processOptions?.map((process: LCAProcess) => (
            <MenuItem key={process.id} value={process.id}>
              {process.name}
            </MenuItem>
          ))}
        </StyledSelect>
      </StyledRow>
    </>
  );
}
