import React from 'react';
import LcaLayout from 'src/Lca/components/layout';
import Breadcrumbs from 'src/Lca/components/Breadcrumbs';
import { Description, Subtitle, Title } from 'src/Lca/components/Texts';
import { useTranslation } from 'react-i18next';
import IntroductionsGrid from 'src/Lca/components/IntroductionsGrid';
import CreateLcaProductInformation from './CreateProductInformation';

export default function LcaCreateProduct() {
  const { t } = useTranslation('lca');
  const data = [
    { name: t('productList'), url: '/lca/products' },
    { name: t('newProduct'), url: '' },
    { name: t('introduction'), url: '' },
  ];

  const [renderForm, setRenderForm] = React.useState(false);

  if (renderForm) return <CreateLcaProductInformation />;

  return (
    <LcaLayout>
      <Breadcrumbs data={data} />
      <Subtitle text={t('introduction').toUpperCase()} />
      <div style={{ marginTop: '44px', marginBottom: '40px' }}>
        <Title text={t('products')} />
      </div>
      <Description text={t('introductionDescription')} />
      <IntroductionsGrid variant="big" onClick={() => setRenderForm(true)} />
      <div style={{ paddingBottom: '80px' }} />
    </LcaLayout>
  );
}
