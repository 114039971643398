import { Box, Typography } from '@mui/material';
import React from 'react';
import BackButton from 'src/components/BackButton';
import { StyledFlex } from 'src/components/StyledFlex';

interface Props {
  title: string;
  children: React.ReactNode;
}

export default function ViewContainer(props: Readonly<Props>) {
  const { title, children } = props;
  return (
    <Box sx={{ p: '0px 64px 64px' }}>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <BackButton fontSize="large" />
        <Typography variant="h1">{title}</Typography>
      </StyledFlex>
      {children}
    </Box>
  );
}
