import React from 'react';
import DmaStage from '../components/DmaStage';
import DmaStep from '../components/DmaStep';
import { useLanguage } from 'src/common/hooks';
import { ImageSlider } from '../components/ImageSlider';
import { generateImageUrls } from '../utils';

export default function Stage7() {
  const { t, language: l } = useLanguage({ keyPrefix: 'esg:dma' });
  return (
    <DmaStage title={t('stagesV2.selfAssessment.title')}>
      <DmaStep
        title={t('stagesV2.selfAssessment.steps.1')}
        videoComponent={<ImageSlider images={generateImageUrls(l, 11, 1)} />}
      />
      <DmaStep
        title={t('stagesV2.selfAssessment.steps.2')}
        navigateTo="../../questionnaires/Other-SA"
      />
    </DmaStage>
  );
}
