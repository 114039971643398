import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormControlLabel } from '@mui/material';
import { login } from '../../../utils';
import {
  ButtonContainer,
  Container,
  FormControlLabelWrapper,
  InputPassword,
  LoginButton,
  PasswordLink,
  StyledAlert,
} from './index.styles';
import OnboardingHeading from '../../components/OnboardingHeading';
import { Label, StyledCheckbox } from '../../components/TaskList/index.styles';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import LoginMfaDialog from './LoginMfaDialog';

export default function Login() {
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(true);
  const { t } = useTranslation();

  const [mfaTokenId, setMfaTokenId] = useState<string | null>(null);
  const mfaDialogOpen = mfaTokenId !== null;

  const handleSubmit = async () => {
    setError('');
    try {
      await login(email, password);
      // If login is successful, redirect to the '/' route
      window.location.href = '/';
    } catch (error: any) {
      if (
        error instanceof AxiosError &&
        error.response?.status === 403 &&
        error.response.data.mfa_token_id
      ) {
        setMfaTokenId(error.response.data.mfa_token_id);
        return;
      }

      let errorMessage = error.message;
      if (error instanceof AxiosError && error.response?.status === 401) {
        console.log(error.response.data.detail);
        if (error.response.data.detail === 'Too many login attempts') {
          errorMessage = t('login.lockout');
        } else {
          errorMessage = t('login.invalid-credentials');
        }
      }
      toast.error(t(errorMessage) as string);
      setError(errorMessage);
    }
  };
  return (
    <>
      <Container>
        <OnboardingHeading text={t('login.login-text')}>
          <form onSubmit={handleSubmit}>
            <div>
              <InputPassword
                id="email"
                label={t('login.email')}
                type="email"
                value={email}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setEmail(e.currentTarget.value)
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSubmit();
                }}
                variant="outlined"
                autoFocus
                fullWidth
                required
              />

              <InputPassword
                id="password"
                label={t('login.password')}
                type={showPassword ? 'password' : 'text'}
                value={password}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setPassword(e.currentTarget.value)
                }
                onKeyDown={(e) => {
                  if (e.key === 'Enter') handleSubmit();
                }}
                variant="outlined"
                fullWidth
                required
              />
            </div>

            <FormControlLabelWrapper>
              <FormControlLabel
                control={
                  <StyledCheckbox
                    checked={!showPassword}
                    onChange={() => setShowPassword(!showPassword)}
                  />
                }
                label={
                  <Label checked={false}>{t('login.show-password')}</Label>
                }
              />
            </FormControlLabelWrapper>

            {error && <StyledAlert severity="error">{t(error)}</StyledAlert>}
          </form>

          <ButtonContainer>
            <PasswordLink to="/password">
              {t('login.password-recovery-link')}
            </PasswordLink>
            <LoginButton type="submit" onClick={handleSubmit}>
              <span>{t('login.login')}</span>
              <svg width="8" height="12" viewBox="0 0 8 12" fill="none">
                <path
                  d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z"
                  fill="white"
                />
              </svg>
            </LoginButton>
          </ButtonContainer>
        </OnboardingHeading>
      </Container>
      {mfaDialogOpen && <LoginMfaDialog mfaTokenId={mfaTokenId} />}
    </>
  );
}
