import { Delete, MoreVert } from '@mui/icons-material';
import {
  Box,
  Chip,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
} from '@mui/material';
import React from 'react';
import { useLanguage } from 'src/common/hooks';

interface Props {
  questionAnswerId: number;
  onDeleteClick: () => void;
}

export default function QuestionAnswerHeader(props: Readonly<Props>) {
  const { questionAnswerId, onDeleteClick } = props;
  const { t } = useLanguage({ keyPrefix: 'questionnaireV3.questionAnswer' });

  const [menuAnchor, setMenuAnchor] = React.useState<null | HTMLElement>(null);

  const handleDeleteClick = () => {
    setMenuAnchor(null);
    onDeleteClick();
  };

  return (
    <Box width="100%" textAlign="right">
      <Chip
        sx={{ m: '16px 8px' }}
        variant="outlined"
        label={`${t('answer')}: #${questionAnswerId}`}
      />
      <IconButton size="small" onClick={(e) => setMenuAnchor(e.currentTarget)}>
        <MoreVert />
      </IconButton>
      <Menu
        open={!!menuAnchor}
        anchorEl={menuAnchor}
        onClose={() => setMenuAnchor(null)}
      >
        <MenuItem onClick={handleDeleteClick}>
          <ListItemAvatar>
            <Delete />
          </ListItemAvatar>
          <ListItemText primary={t('deleteButton')} />
        </MenuItem>
      </Menu>
    </Box>
  );
}
