import React from 'react';

import { Button, ButtonProps } from '@mui/material';
import { EditNote, FiberManualRecord } from '@mui/icons-material';

import { useTranslation } from 'react-i18next';

interface Props extends ButtonProps {
  value?: string;
}
export default function ReasoningButton(props: Readonly<Props>) {
  const { value, ..._props } = props;
  const { t } = useTranslation('esg', {
    keyPrefix: 'dma.step5TableV2.reasoning',
  });
  return (
    <Button
      fullWidth
      size="small"
      variant="outlined"
      startIcon={<EditNote />}
      endIcon={
        <FiberManualRecord
          sx={{
            color: value ? 'green' : 'orange',
            fontSize: '12px !important',
          }}
        />
      }
      {..._props}
    >
      {t('reasoning')}
    </Button>
  );
}
