import React from 'react';
import { StyledTextField } from '../productInformation';
import NumberInput from '../../../../components/esg/Inputs/number';
import { FormControl, MenuItem, SelectChangeEvent } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import { useTranslation } from 'react-i18next';

interface Props {
  values: {
    functional_unit: string;
    data_collection_period: string;
    weight: number | null;
    weight_unit: string;
    dimensions: string;
    dimensions_unit: string;
    volume: number | null;
    volume_unit: string;
  };
  setValues: Function;
}

const weight_unit = [{ name: 'g' }, { name: 'kg' }, { name: 't' }];
const dimensions_unit = [{ name: 'mm' }, { name: 'cm' }, { name: 'm' }];
const volume_unit = [
  { label: 'mm3', value: 'mm_3' },
  { label: 'cm3', value: 'cm_3' },
  { label: 'm3', value: 'm_3' },
];

export default function ProductCharacteristics({ setValues, values }: Props) {
  const { t } = useTranslation('lca', { keyPrefix: 'productCharacteristics' });
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | SelectChangeEvent,
    name: string
  ) => {
    setValues({
      ...values,
      [name]: e.target.value,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <StyledTextField
        fullWidth
        label={t('functionalUnitDeclaredUnit')}
        name="functional_unit"
        value={values.functional_unit}
        onChange={(e) => handleChange(e, 'functional_unit')}
      />
      <StyledTextField
        fullWidth
        label={t('dataCollectionPeriod')}
        name="data_collection_period"
        value={values.data_collection_period}
        onChange={(e) => handleChange(e, 'data_collection_period')}
      />
      <div
        style={{
          display: 'flex',
          gap: '24px',
        }}
      >
        <NumberInput
          color="#7E4308"
          state={values}
          label={t('productWeight')}
          name="weight"
          value={values.weight}
          setValue={setValues}
        />
        <FormControl fullWidth>
          <InputLabel>{t('weightUnit')}</InputLabel>
          <Select
            value={values.weight_unit}
            label={t('weightUnit')}
            onChange={(event) => handleChange(event, 'weight_unit')}
          >
            {weight_unit.map((el) => (
              <MenuItem key={el.name} value={el.name}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: 'flex',
          gap: '24px',
        }}
      >
        <StyledTextField
          fullWidth
          label={t('productDimensions')}
          name="dimensions"
          value={values.dimensions}
          onChange={(e) => handleChange(e, 'dimensions')}
        />
        <FormControl fullWidth>
          <InputLabel>{t('productDimensionsUnit')}</InputLabel>
          <Select
            value={values.dimensions_unit}
            label={t('productDimensionsUnit')}
            onChange={(event) => handleChange(event, 'dimensions_unit')}
          >
            {dimensions_unit.map((el) => (
              <MenuItem key={el.name} value={el.name}>
                {el.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

      <div
        style={{
          display: 'flex',
          gap: '24px',
        }}
      >
        <NumberInput
          color="#7E4308"
          state={values}
          label={t('optionalVolume')}
          name="volume"
          value={values.volume}
          setValue={setValues}
        />
        <FormControl fullWidth>
          <InputLabel>{t('optionalVolumeUnit')}</InputLabel>
          <Select
            value={values.volume_unit}
            label={t('optionalVolumeUnit')}
            onChange={(event) => handleChange(event, 'volume_unit')}
          >
            {volume_unit.map((el) => (
              <MenuItem key={el.value} value={el.value}>
                {el.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
}
