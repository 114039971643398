import React from 'react';
import { IconButton, TableCell, TableRow } from '@mui/material';

import CollapsableButtonCell from 'src/Esg/Dmav2/components/CollapseButtonCell';
import { UUID } from 'crypto';
import { Delete } from '@mui/icons-material';
import { countFigureTypes } from 'src/Esg/Taxonomy/utils';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import AddActivityRow from './AddActivityRow';
import ActivityRow from './ActivityRow';

interface Props {
  reportSectorId: UUID;
}

export default function SectorRow({ reportSectorId }: Readonly<Props>) {
  const { taxonomyReport, queries } = useEsgTaxonomy();
  const [collapsed, setCollapsed] = React.useState(false);

  if (queries.isLoading) return null;

  const reportSector = queries.reportSectors._dataMap[reportSectorId];
  const sector = queries.sectors._dataMap[reportSector?.sector_id];
  const sectorActivities = queries.activities._data
    .filter((a) => a.sector_id === sector?.id)
    .map((a) => a.id);
  const usedActivities = queries.reportActivities._data.filter((a) =>
    sectorActivities.includes(a.activity_id)
  );

  return (
    <>
      <TableRow>
        <CollapsableButtonCell
          style={{ padding: '8px' }}
          colSpan={2 + countFigureTypes(taxonomyReport)}
          text={sector.label}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
          sx={{ bgcolor: sector.color }}
        />
        <TableCell sx={{ bgcolor: sector.color }}>
          <IconButton
            onClick={() => queries.reportSectors._delete.mutate(reportSectorId)}
            disabled={usedActivities.length > 0}
          >
            <Delete />
          </IconButton>
        </TableCell>
      </TableRow>

      {!collapsed &&
        usedActivities.map((activity) => (
          <ActivityRow key={activity.id} reportActivityId={activity.id} />
        ))}
      {!collapsed && sectorActivities.length > usedActivities.length && (
        <AddActivityRow sectorId={reportSector.sector_id} />
      )}
    </>
  );
}
