import React from 'react';
import {
  Dialog,
  DialogContent,
  TextField,
  DialogActions,
  Button,
  DialogTitle,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogCloseButton from 'src/components/DialogCloseButton';
import { useDmaMatterReasonings } from 'src/Esg/Dmav2/hooks';
import { UUID } from 'crypto';

interface Props {
  esgDmaId: UUID;
  children: React.ReactNode;
}

interface Data {
  impact_id?: number;
  risk_opportunity_id?: number;
  reasoningTextKey?: 'impact_reasoning' | 'risk_opportunity_reasoning';
}

const initialData: Data = {
  impact_id: undefined,
  risk_opportunity_id: undefined,
  reasoningTextKey: undefined,
};

// This Dialog has own context to render only once at the table level
// not under each row
const ReasoningDialogContext = React.createContext<{
  open: boolean;
  data: Data;
  // eslint-disable-next-line no-unused-vars
  openDialog: (data: Data) => void;
  closeDialog: () => void;
}>({
  open: false,
  data: initialData,
  openDialog: () => {},
  closeDialog: () => {},
});

export const useReasoningDialog = () =>
  React.useContext(ReasoningDialogContext);

export default function ReasoningDialogProvider(props: Readonly<Props>) {
  const { esgDmaId } = props;
  const { t } = useTranslation('esg', {
    keyPrefix: 'dma.step5TableV2.reasoning',
  });
  const [open, setOpen] = React.useState(false);
  const [data, setData] = React.useState<Data>(initialData);
  const [text, setText] = React.useState('');

  const { impact_id, risk_opportunity_id, reasoningTextKey } = data;

  const {
    _data: reasonings,
    create,
    update,
  } = useDmaMatterReasonings({
    esg_dma_id: esgDmaId,
  });

  const reasoning = reasonings.find(
    (reasoning) =>
      reasoning.esg_dma_impact_id === (impact_id ?? null) &&
      reasoning.esg_dma_risk_opportunity_id === (risk_opportunity_id ?? null)
  );

  React.useEffect(() => {
    if (reasoning && reasoningTextKey)
      setText(reasoning?.[reasoningTextKey] ?? '');
  }, [impact_id, risk_opportunity_id, reasoning, reasoningTextKey]);

  const openDialog = (_data: Data) => {
    setData(_data);
    setOpen(true);
  };

  const closeDialog = () => {
    setOpen(false);
    setData(initialData);
    setTimeout(() => setText(''), 300);
  };

  const handleSaveClick = () =>
    reasoning
      ? update
          .mutateAsync({ id: reasoning.id, [String(reasoningTextKey)]: text })
          .then(closeDialog)
      : create
          .mutateAsync({
            esg_dma_id: esgDmaId,
            esg_dma_impact_id: impact_id,
            esg_dma_risk_opportunity_id: risk_opportunity_id,
            [String(reasoningTextKey)]: text,
          })
          .then(closeDialog);

  const value = React.useMemo(
    () => ({ open, data, openDialog, closeDialog }),
    [open]
  );

  return (
    <ReasoningDialogContext.Provider value={value}>
      {props.children}
      <Dialog open={open} onClose={closeDialog}>
        <DialogCloseButton onClick={closeDialog} />
        <DialogTitle>{t('title')}</DialogTitle>
        <DialogContent>
          <TextField
            label={t('placeholder')}
            value={text}
            fullWidth
            onChange={(e) => setText(e.target.value)}
            multiline
            rows={6}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={closeDialog}>
            {t('cancel')}
          </Button>
          <Button onClick={handleSaveClick}>{t('save')}</Button>
        </DialogActions>
      </Dialog>
    </ReasoningDialogContext.Provider>
  );
}
