import React, { ChangeEvent, MouseEvent } from 'react';
import { Box, SelectChangeEvent, Typography } from '@mui/material';

import { useFieldAnswers, useFields, useQv3Context } from '../hooks';
import FieldInput from './Inputs';
import { FieldValueSchema } from '../types';
import Buttons from './Buttons';
import { UUID } from 'crypto';

interface Props {
  questionId: UUID;
  questionAnswerId: number;
  fieldId: UUID;
  options?: {
    alwaysShowButtons: boolean;
  };
}

// this component is reusable (keep it neat)
export default function Field(props: Readonly<Props>) {
  const { questionAnswerId, fieldId, questionId, options } = props;
  const alwaysShowButtons = options?.alwaysShowButtons || false;

  const { modifiedQuestionAnswers } = useQv3Context();
  const { _dataMap: fieldMap } = useFields(
    { question_id: questionId },
    { enabled: !!questionId }
  );
  const field = fieldMap[fieldId];
  const fieldAnswers = useFieldAnswers(
    { question_answer_id: questionAnswerId },
    { enabled: !!questionAnswerId }
  );

  const fieldAnswer = fieldAnswers._data.find((fa) => fa.field_id === field.id);
  const isNew = !fieldAnswer?.id;

  const [value, setValue] = React.useState<FieldValueSchema>(null);
  const [originalValue, setOriginalValue] =
    React.useState<FieldValueSchema>(null);
  const isModified = value !== originalValue;

  React.useEffect(() => {
    if (!fieldAnswer) return;
    setValue(fieldAnswer.value);
    setOriginalValue(fieldAnswer.value);
  }, [fieldAnswer]);

  if (!field) return null;

  const handleOnChange = (
    value: FieldValueSchema,
    _: ChangeEvent | MouseEvent | SelectChangeEvent
  ) => {
    setValue(value);
    modifiedQuestionAnswers.add(questionAnswerId);
  };

  const handleCancelClick = () => setValue(originalValue);

  const handleDeleteClick = () =>
    fieldAnswer &&
    fieldAnswers._delete.mutateAsync(fieldAnswer.id).then(() => {
      setValue(null);
      setOriginalValue(null);
    });

  const handleUpdate = () =>
    fieldAnswer && fieldAnswers.update.mutate({ id: fieldAnswer.id, value });

  const handleCreate = () =>
    fieldAnswers.create.mutate({
      field_id: field.id,
      question_answer_id: questionAnswerId,
      value,
    });

  const handleSaveClick = !isNew ? handleUpdate : handleCreate;

  return (
    <Box m={0}>
      <Typography variant="body1" fontWeight={600} color="#38414f">
        {field.title}
      </Typography>
      <br />
      <FieldInput
        field={field}
        value={value}
        onChange={handleOnChange}
        error={fieldAnswers._errors[field.id]}
      />
      {(isModified || alwaysShowButtons) && (
        <Buttons
          fieldAnswer={fieldAnswer}
          onCancelClick={handleCancelClick}
          onDeleteClick={handleDeleteClick}
          onSaveClick={handleSaveClick}
        />
      )}
    </Box>
  );
}
