import React from 'react';
import { StyledPanel } from 'src/components/StyledPanel';
import { Box, Table, TableBody, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Head from './SelectionTable/Head';
import SectorRow from './SelectionTable/Rows/SectorRow';
import AddSectorRow from './SelectionTable/Rows/AddSectorRow';
import { StyledFlex } from 'src/components/StyledFlex';
import BackButton from 'src/components/BackButton';
import { useEsgTaxonomy } from 'src/Esg/hooks';

export default function Selection() {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.selection' });
  const { queries } = useEsgTaxonomy();

  const isAddSectorAvailable =
    queries.sectors._data.length > queries.reportSectors._data.length;

  return (
    <Box sx={{ p: '0px 64px' }}>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <BackButton fontSize="large" />
        <Typography variant="h1">{t('title')}</Typography>
      </StyledFlex>

      <StyledPanel sx={{ mt: '48px' }}>
        <Table stickyHeader>
          <Head />
          <TableBody>
            {queries.reportSectors._data.map((sector) => (
              <SectorRow key={sector.id} reportSectorId={sector.id} />
            ))}
            {isAddSectorAvailable && <AddSectorRow />}
          </TableBody>
        </Table>
      </StyledPanel>
    </Box>
  );
}
