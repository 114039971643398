/* eslint-disable no-unused-vars */

import { UUID } from 'crypto';
import { OrganizationalLogoOutSchema } from 'src/components/OrganizationalLogo/types';
import { SurveyAnswerSummary } from '../Dma/Stakeholders/StakeholdersAnalytics/Dashboard/index.types';
import { UploadedFileSchema } from '../../Ghg/CodeOfConductSurvey/types';

export enum DmaVersion {
  V1 = 1,
  V2 = 2,
}
export interface EsgDmaImpactInSchema {
  esg_dma_id: UUID;
  esg_dma_sub_sub_topic_id: number;
  name: string;
  description: string;
  category: string;
  human_rights_related: boolean;
  upstream_related: boolean;
  own_operations_related: boolean;
  downstream_related: boolean;
}

export interface EsgDmaImpactOutSchema extends EsgDmaImpactInSchema {
  id: number;

  // scores
  preliminary_score?: number;
  stakeholders_impact_score?: number;
  final_score?: number;
  is_important?: boolean;
}

export enum EsgDmaImpactCategory {
  POSITIVE_REAL = 'positive_real',
  POSITIVE_POTENTIAL = 'positive_potential',
  NEGATIVE_REAL = 'negative_real',
  NEGATIVE_POTENTIAL = 'negative_potential',
}

export interface EsgStakeholderAnswerInSchema {
  esg_dma_sub_topic_id: number | null;
  esg_dma_sub_sub_topic_id: number | null;

  esg_stakeholder_survey_id: number;
  positive: number | null;
  negative: number | null;
}

export interface EsgStakeholderAnswerOutSchema
  extends EsgStakeholderAnswerInSchema {
  id: number;
}

export interface EsgStakeholderAnswerCommentsInSchema {
  text: string;
  esg_stakeholder_answer_id: number;
}

export interface EsgStakeholderAnswerCommentsOutSchema
  extends EsgStakeholderAnswerCommentsInSchema {
  id: number;
  created_at: string;
  created_by_id: number;
  created_by: string;
  updated_at: string;
}

export interface EsgStakeholderAnswerCommentsUpdateSchema {
  text: string;
}
export interface EsgStakeholderAnswerCommentsFilterSchema {
  esg_stakeholder_answer__esg_stakeholder_survey_id?: number;
}

export interface EsgDmaTopicOutSchema {
  id: number;
  key: string;
  name: string;
  color: string;
  hint: string;
}

export interface EsgDmaSubtopicInSchema {
  esg_dma_id: UUID;
  topic_id: number;
  description: string;
}
export interface EsgDmaSubtopicOutSchema {
  topic_id: number;
  id: number;
  description: string;
  is_stakeholder_survey: boolean;
  hint: string;
  esg_dma_id: UUID | null;
}

export interface EsgDmaSubSubTopicInSchema {
  esg_dma_id: UUID;
  subtopic_id: number;
  description: string;
}

export interface EsgDmaSubSubTopicOutSchema {
  id: number;
  esg_dma_id: UUID | null;
  subtopic_id: number;
  description: string;
  is_virtual: boolean;
}

export interface EsgDmaOverriddenSubTopicOutSchema
  extends EsgDmaSubtopicOutSchema {
  is_selected: boolean;
}

export interface EsgDmaOverriddenSubSubTopicOutSchema
  extends EsgDmaSubSubTopicOutSchema {
  is_selected: boolean;
}

export interface EsgDmaSubTopicHierarchySchema extends EsgDmaSubtopicOutSchema {
  subsubtopics: EsgDmaSubSubTopicOutSchema[];
}

export interface EsgDmaTopicHierarchySchema extends EsgDmaTopicOutSchema {
  subtopics: EsgDmaSubTopicHierarchySchema[];
}

export interface EsgStakeholderSurveyMetadataOutSchema {
  report_period: string;
  intro_text?: string;
  outro_text?: string;
  accept_answers: boolean;
  organization_name: string;
  logo: OrganizationalLogoOutSchema | null;
  token?: string;
  topics?: EsgStakeholderSurveyTopicsSchema;
}

export interface EsgStakeholderCreateSurveyOutSchema {
  token: string;
}

enum StakeholderNatureChoices {
  AFFECTED_STAKEHOLDER = 'affected_stakeholder',
  USER = 'user',
  AFFECTED_STAKEHOLDER_AND_USER = 'affected_stakeholder_and_user',
  NOT_APPLICABLE = 'not_applicable',
}

export interface EsgStakeholderSurveyTopicsSchema {
  sub_topics: EsgDmaOverriddenSubTopicOutSchema[];
  sub_sub_topics: EsgDmaOverriddenSubSubTopicOutSchema[];
}

export interface EsgStakeholderDataInSchema {
  esg_dma_id: UUID;
  esg_stakeholder_type_id: number;
  group_size: number | null;
  direct_contact: boolean | null;
  relationship_maintenance: string | null;
  sustainability_awareness: number | null;
  additional_info: string | null;
  intro_text: string | null;
  outro_text: string | null;
  accept_answers: boolean;

  // v1 (to remove)
  impact?: boolean | null;
  relevance?: boolean | null;
  reasoning?: string | null;
  selected_for_survey?: boolean | null;
  severity?: number | null;
  stakeholder_nature?: string | null;
}

export interface EsgStakeholderDataOutSchema
  extends EsgStakeholderDataInSchema {
  id: number;
}

export interface EsgStakeholdersTypeSchema {
  id: number;
  name: string;
  key: string;
}

export interface StakeholdersSurveySummarySchema {
  esg_dma_sub_topic_id: number;

  positive_0_avg: number | null;
  positive_2_avg: number | null;
  positive_1_avg: number | null;
  positive_3_avg: number | null;
  positive_avg: number | null;

  negative_0_avg: number | null;
  negative_1_avg: number | null;
  negative_2_avg: number | null;
  negative_3_avg: number | null;
  negative_avg: number | null;

  score: number | null;
}

export interface StakeholdersSurveyTopicSummarySchema {
  esg_dma_topic_id: number;

  positive_avg: number | null;
  negative_avg: number | null;
}
export interface EsgDmaStakeholdersAnswersAnalyticsSchema {
  responses_count: number;
  surveyed_stakeholder_types_count: number;
  surveyed_stakeholder_types: EsgStakeholdersTypeSchema[];
  stakeholder_types_with_responses_count: number;
  summary: StakeholdersSurveySummarySchema[];
  topic_summary: StakeholdersSurveyTopicSummarySchema[];
  survey_answers_summary: SurveyAnswerSummary[];
}

export interface EsgDmaStakeholderQualityAnswerInSchema {
  esg_dma_id: UUID;
  subtopic_id: number;
  issue_raised: boolean;
  impact_materiality: boolean;
  financial_materiality: boolean;
  comment: string;
}

export interface EsgDmaStakeholderQualityAnswerOutSchema
  extends EsgDmaStakeholderQualityAnswerInSchema {
  id: number;
}

export interface EsgDmaImpactBasicSchema {
  id: number;
  name: string | null;
  description: string | null;
  category: EsgDmaImpactCategory | null;
}

export enum EsgDmaRiskOpportunityCategory {
  RISK = 'risk',
  OPPORTUNITY = 'opportunity',
  NONE = 'none',
}

export interface EsgDmaRiskOpportunityInSchema {
  esg_dma_id: UUID;
  esg_dma_impact_id: number | null;
  esg_dma_sub_sub_topic_id: number;
  name: string;
  description: string;
  category: EsgDmaRiskOpportunityCategory;
  upstream_related: boolean;
  own_operations_related: boolean;
  downstream_related: boolean;
  impact_id?: number;
}

export interface EsgDmaRiskOpportunityOutSchema
  extends EsgDmaRiskOpportunityInSchema {
  id: number;
  // scores
  financial_score?: number;
  is_important?: boolean;
}
export interface EsgDmaRiskOpportunityBasicSchema {
  id: number;
  name: string | null;
  description: string | null;
  category: EsgDmaRiskOpportunityCategory | null;
}

export enum TimeHorizon {
  SHORT = 'short',
  MEDIUM = 'medium',
  LONG = 'long',
}

export interface Stage23HierarchySchema {
  topicId: number;
  subtopics: EsgDmaSubTopicHierarchySchema[];
  subsubtopics: EsgDmaSubSubTopicOutSchema[];
  color: string;
}

export interface EsgDmaValueChainEntityInSchema {
  esg_dma_id: UUID;
  name?: string;
  essential_products?: string;
  essential_services?: string;
  collaboration_details?: string;
  is_upstream?: boolean;
  is_own_operations?: boolean;
  is_downstream?: boolean;
}

export interface EsgDmaValueChainEntityOutSchema
  extends EsgDmaValueChainEntityInSchema {
  id: number;
}

export interface ValueChainEmployeesByCountryInSchema {
  country: string | null;
  count: number | null;
  esg_dma_value_chain_entity_id: number;
}

export interface ValueChainEmployeesByCountryOutSchema
  extends ValueChainEmployeesByCountryInSchema {
  id: number;
}

export interface ValueChainStakeholderTypeInSchema {
  esg_stakeholder_type_id: number;
  esg_dma_value_chain_entity_id: number;
}

export interface ValueChainStakeholderTypeOutSchema
  extends ValueChainStakeholderTypeInSchema {
  id: number;
}

export interface EsgStakeholderOverriddenSubTopicSchema {
  sub_topic_id: number;
  is_selected: boolean;
}

export interface EsgStakeholderOverriddenSubSubTopicSchema {
  sub_sub_topic_id: number;
  is_selected: boolean;
}
export interface EsgDmaInSchema {
  name?: string;
  version: DmaVersion;
  subscriber_id: number;
}
export interface EsgDmaOutSchema extends EsgDmaInSchema {
  id: UUID;
  stakeholder_score_weight: number;
  materiality_threshold: number;
  allow_adjust_stakeholder_score_weight: boolean;
  allow_adjust_materiality_threshold: boolean;
  allow_create_custom_subtopics: boolean;
}

export interface EsgDmaImpactMatterDataInSchema {
  esg_dma_id: UUID;
  esg_dma_impact_id: number;
  time_horizon: TimeHorizon;
  impact_scale: number | null;
  impact_scope: number | null;
  impact_probability: number | null;
  impact_irreversibility: number | null;
}

export interface EsgDmaImpactMatterDataOutSchema
  extends EsgDmaImpactMatterDataInSchema {
  id: number;
  financial_score: number;
}

export interface EsgDmaRiskOpportunityMatterDataInSchema {
  esg_dma_id: UUID;
  esg_dma_risk_opportunity_id: number;
  time_horizon: TimeHorizon;
  financial_scale: number | null;
  financial_probability: number | null;
}

export interface EsgDmaRiskOpportunityMatterDataOutSchema
  extends EsgDmaRiskOpportunityMatterDataInSchema {
  id: number;
  financial_score: number;
}

// parsed data for step 5
// prepareData function in src/Esg/Dmav2/Step5/Step5Table/Rows/utils.ts
export interface MatterDataSchema {
  subSubTopicId: number;
  title: string;
  impact: EsgDmaImpactOutSchema | null;
  riskOpportunity: EsgDmaRiskOpportunityOutSchema | null;
}

export interface EsgDmaMatrixDataSchema {
  datapoints: EsgDmaMatrixDatapointSchema[];
  threshold?: number;
}

export interface EsgDmaMatrixDatapointSchema {
  id: number;
  esg_topic_key: string;
  esg_topic_title: string;
  esg_topic_color: string;
  impact_level: string;
  scores: {
    short: {
      financial: number | null;
      nonfinancial: number | null;
    };
    medium: {
      financial: number | null;
      nonfinancial: number | null;
    };
    long: {
      financial: number | null;
      nonfinancial: number | null;
    };
  };
  average_score: {
    financial: number;
    nonfinancial: number;
  } | null;
}

export interface EsgDatapointMaterialityOutSchema {
  esg_datapoint_id: string;
  is_material: boolean;
}

export interface EsgStakeholderSurveyOutSchema {
  id: number;
  esg_dma_id: UUID;
  esg_stakeholder_type_id: number;
  esg_stakeholder_type_name: string;
}

export interface DecodedToken {
  data?: {
    esg_stakeholder_survey_id?: number;
  };
}

export enum EsgDmaAdditionalFileCategory {
  INSTRUCTIONS_STEP2 = 'instructions_step2',
  INSTRUCTIONS_STEP3 = 'instructions_step3',
  INSTRUCTIONS_STEP5 = 'instructions_step5',
}

export interface EsgDmaAdditionalFileInSchema {
  esg_dma_id: UUID;
  file_id: UUID;
  category: EsgDmaAdditionalFileCategory;
}

export interface EsgDmaAdditionalFileOutSchema {
  id: UUID;
  esg_dma_id: UUID;
  file_id: UUID;
  category: EsgDmaAdditionalFileCategory;
  name: string;
  url: string;
}

export interface EsgDmaMatterReasoningUpdateSchema {
  impact_reasoning?: string;
  risk_opportunity_reasoning?: string;
}

export interface EsgDmaMatterReasoningInSchema
  extends EsgDmaMatterReasoningUpdateSchema {
  esg_dma_id: UUID;
  esg_dma_impact_id?: number;
  esg_dma_risk_opportunity_id?: number;
}

export interface EsgDmaMatterReasoningOutSchema
  extends EsgDmaMatterReasoningInSchema {
  id: number;
}

export interface EsgDmaMatterReasoningFilterSchema {
  esg_dma_id?: UUID;
  esg_dma_impact_id?: number;
  esg_dma_risk_opportunity_id?: number;
}
