import { TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';

interface Props {
  colSpan?: number;
  message: string;
}

export default function ErrorRow(props: Readonly<Props>) {
  const { colSpan, message } = props;
  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ borderBottom: 'none', p: 0 }}>
        <Typography variant="errorHelperText">{message}</Typography>
      </TableCell>
    </TableRow>
  );
}
