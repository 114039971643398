import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';

export default function Head() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.dnsh.table.headers',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '5%' }}>
          {t('activity')}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '15%' }} />
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '80%' }} />
        <TableCell
          sx={{
            bgcolor: colors.backgroundInput,
            width: '0%',
            textAlign: 'center',
          }}
        >
          {t('yes')}
        </TableCell>
        <TableCell
          sx={{
            bgcolor: colors.backgroundInput,
            width: '0%',
            textAlign: 'center',
          }}
        >
          {t('no')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
