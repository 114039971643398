import { TaxonomyReportOutSchema } from './types';

// eslint-disable-next-line import/prefer-default-export
export const countFigureTypes = (report?: TaxonomyReportOutSchema) => {
  if (!report) return 0;
  let count = 0;
  if (report.turnover_enabled) count += 1;
  if (report.capex_enabled) count += 1;
  if (report.opex_enabled) count += 1;
  return count;
};

export const toPercentage = (value?: number | null, _default?: string) => {
  if (value === undefined || value === null || value === 0)
    return _default || '';
  return `${value.toFixed(2)} %`;
};

export const toCurrency = (
  value?: number | null,
  currency?: string,
  _default?: string
) => {
  if (value === undefined || value === null || value === 0)
    return _default || '';
  if (!currency) return value.toFixed(2);
  return `${value.toFixed(2)} ${currency}`;
};
