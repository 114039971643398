import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  CloudText,
  CloudTitle,
  LinkButton,
  LinksWrapper,
} from './index.styles';

export default function BasicInfoContent() {
  const { t } = useTranslation();
  return (
    <>
      <CloudTitle>{t('basicInfoCloud.title')}</CloudTitle>
      <CloudText
        dangerouslySetInnerHTML={{ __html: t('basicInfoCloud.desc') }}
      />
      <LinkButton
        href="mailto:support@envirly.com"
        target="_blank"
        rel="noreferrer"
      >
        support@envirly.com
      </LinkButton>
      <LinksWrapper>
        <a href="tel:+48881197622" target="_blank" rel="noreferrer">
          +48 881 197 622
        </a>
        <a href="mailto:support@envirly.com" target="_blank" rel="noreferrer">
          support@envirly.com
        </a>
      </LinksWrapper>
    </>
  );
}
