import React from 'react';

import { TableCell, TableRow } from '@mui/material';

import themeColors from 'src/theme/colors';
import ReasoningButton from './ReasoningButton';
import { useReasoningDialog } from './ReasoningDialog';
import { useParams } from 'react-router-dom';
import { useEsgReports } from 'src/Esg/hooks';
import { useDmaMatterReasonings } from 'src/Esg/Dmav2/hooks';

interface Props {
  impactId?: number;
  riskOpportunityId?: number;
}

export default function MatterReasoningRow(props: Readonly<Props>) {
  const { impactId, riskOpportunityId } = props;
  const { openDialog } = useReasoningDialog();

  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _data: reasonings } = useDmaMatterReasonings({
    esg_dma_id: report?.esg_dma_id,
  });

  const reasoning = reasonings.find(
    (reasoning) =>
      reasoning.esg_dma_impact_id === impactId &&
      reasoning.esg_dma_risk_opportunity_id === riskOpportunityId
  );

  return (
    <TableRow>
      <TableCell sx={{ backgroundColor: themeColors.backgroundInput }} />
      <TableCell colSpan={4} sx={{ textAlign: 'center' }}>
        {impactId && (
          <ReasoningButton
            value={reasoning?.impact_reasoning}
            onClick={() =>
              openDialog({
                impact_id: impactId,
                risk_opportunity_id: riskOpportunityId,
                reasoningTextKey: 'impact_reasoning',
              })
            }
          />
        )}
      </TableCell>
      <TableCell
        colSpan={4}
        sx={{ backgroundColor: themeColors.backgroundInput }}
      />
      <TableCell colSpan={2} sx={{ textAlign: 'center' }}>
        {riskOpportunityId && (
          <ReasoningButton
            value={reasoning?.risk_opportunity_reasoning}
            onClick={() =>
              openDialog({
                impact_id: impactId,
                risk_opportunity_id: riskOpportunityId,
                reasoningTextKey: 'risk_opportunity_reasoning',
              })
            }
          />
        )}
      </TableCell>
      <TableCell
        colSpan={2}
        sx={{ backgroundColor: themeColors.backgroundInput }}
      />
    </TableRow>
  );
}
