import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import legacyGhgEn from './locale/en/legacyGhg.json';
import ghgEn from './locale/en/ghg.json';
import esgEn from './locale/en/esg.json';
import superadminEn from './locale/en/superadmin.json';
import registrationEn from './locale/en/registration.json';
import cbamEn from './locale/en/cbam.json';
import superdashboardGhgEn from './locale/en/superdashboardGhg.json';
import lcaEn from './locale/en/lca.json';

import legacyGhgPl from './locale/pl/legacyGhg.json';
import ghgPl from './locale/pl/ghg.json';
import esgPl from './locale/pl/esg.json';
import superadminPl from './locale/pl/superadmin.json';
import registrationPl from './locale/pl/registration.json';
import cbamPl from './locale/pl/cbam.json';
import superdashboardGhgPl from './locale/pl/superdashboardGhg.json';
import registrationBnpPl from './locale/pl/registration_bnp.json';
import lcaPl from './locale/pl/lca.json';

import legacyGhgCs from './locale/cs/legacyGhg.json';
import ghgCs from './locale/cs/ghg.json';
import esgCs from './locale/cs/esg.json';
import superadminCs from './locale/cs/superadmin.json';
import registrationCs from './locale/cs/registration.json';
import cbamCs from './locale/cs/cbam.json';
import superdashboardGhgCs from './locale/cs/superdashboardGhg.json';
import lcaCs from './locale/cs/lca.json';

import legacyGhgEs from './locale/es/legacyGhg.json';
import ghgEs from './locale/es/ghg.json';
import esgEs from './locale/es/esg.json';
import superadminEs from './locale/es/superadmin.json';
import registrationEs from './locale/es/registration.json';
import cbamEs from './locale/es/cbam.json';
import superdashboardGhgEs from './locale/es/superdashboardGhg.json';
import lcaEs from './locale/es/lca.json';

import legacyGhgRo from './locale/ro/legacyGhg.json';
import ghgRo from './locale/ro/ghg.json';
import esgRo from './locale/ro/esg.json';
import superadminRo from './locale/ro/superadmin.json';
import registrationRo from './locale/ro/registration.json';
import cbamRo from './locale/ro/cbam.json';
import superdashboardGhgRo from './locale/ro/superdashboardGhg.json';
import lcaRo from './locale/ro/lca.json';

import { LocalStorageKeys } from './common/constants';
import { DevTools, I18nextPlugin, Tolgee, withTolgee } from '@tolgee/i18next';

export const SUPPORTED_NAMESPACES = [
  'legacyGhg',
  'ghg',
  'esg',
  'superadmin',
  'superdashboardGhg',
  'registration',
  'registration_bnp',
  'cbam',
];

export const resources = {
  en: {
    legacyGhg: legacyGhgEn,
    esg: esgEn,
    ghg: ghgEn,
    superdashboardGhg: superdashboardGhgEn,
    superadmin: superadminEn,
    registration: registrationEn,
    registration_bnp: registrationBnpPl, // the same for both languages
    cbam: cbamEn,
    lca: lcaEn,
  },
  pl: {
    legacyGhg: legacyGhgPl,
    esg: esgPl,
    ghg: ghgPl,
    superdashboardGhg: superdashboardGhgPl,
    superadmin: superadminPl,
    registration: registrationPl,
    registration_bnp: registrationBnpPl,
    cbam: cbamPl,
    lca: lcaPl,
  },
  cs: {
    legacyGhg: legacyGhgCs,
    ghg: ghgCs,
    esg: esgCs,
    superdashboardGhg: superdashboardGhgCs,
    superadmin: superadminCs,
    registration: registrationCs,
    cbam: cbamCs,
    lca: lcaCs,
  },
  es: {
    legacyGhg: legacyGhgEs,
    ghg: ghgEs,
    esg: esgEs,
    superdashboardGhg: superdashboardGhgEs,
    superadmin: superadminEs,
    registration: registrationEs,
    cbam: cbamEs,
    lca: lcaEs,
  },
  ro: {
    legacyGhg: legacyGhgRo,
    ghg: ghgRo,
    esg: esgRo,
    superdashboardGhg: superdashboardGhgRo,
    superadmin: superadminRo,
    registration: registrationRo,
    cbam: cbamRo,
    lca: lcaRo,
  },
};

const SUPPORTED_LANGUAGES = Object.keys(resources);

const tolgee = Tolgee().use(DevTools()).use(I18nextPlugin()).init({
  apiUrl: process.env.REACT_APP_TOLGEE_API_URL,
  apiKey: process.env.REACT_APP_TOLGEE_API_KEY,
  availableLanguages: SUPPORTED_LANGUAGES,
  defaultLanguage: 'en',
  staticData: resources,
  ns: SUPPORTED_NAMESPACES,
  fallbackNs: SUPPORTED_NAMESPACES,
});

withTolgee(i18n, tolgee)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    compatibilityJSON: 'v3',
    resources,
    supportedLngs: Object.keys(resources),
    fallbackLng: 'en',
    ns: SUPPORTED_NAMESPACES,
    fallbackNS: SUPPORTED_NAMESPACES,
    detection: {
      order: ['querystring', 'localStorage', 'navigator'],
      caches: ['localStorage'],
      lookupLocalStorage: LocalStorageKeys.LOCAL_LANGUAGE,
      convertDetectedLanguage(lng: string) {
        return lng.split('-')[0];
      },
    },
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
