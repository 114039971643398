import React from 'react';
import { useTranslation } from 'react-i18next';

import { FigureAnalytics, FigureType, TaxonomyReportAnalytics } from '../types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import colors from 'src/theme/colors';
import { toCurrency, toPercentage } from '../utils';

interface Props {
  figure: FigureType;
}

export default function OverviewTable({ figure }: Readonly<Props>) {
  const figureLower = figure.toLocaleLowerCase();
  const { t } = useTranslation('esg', {
    keyPrefix: `taxonomy.overview.${figureLower}`,
  });

  const { taxonomyReport, reportAnalytics } = useEsgTaxonomy();

  const analytics = reportAnalytics._instance?.[
    figureLower as keyof TaxonomyReportAnalytics
  ] as FigureAnalytics;

  if (reportAnalytics.isLoading || !analytics) return null;

  const currency = taxonomyReport?.currency_type;

  return (
    <>
      <Typography>{t('text')}</Typography>
      <br />
      <TableContainer sx={{ overflowX: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ bgcolor: colors.backgroundInput, width: '70%' }}>
                {t('table.head')}
              </TableCell>
              <TableCell
                sx={{ bgcolor: colors.backgroundInput, width: '15%' }}
              />
              <TableCell
                sx={{ bgcolor: colors.backgroundInput, width: '15%' }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>{t('table.row1')}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {toCurrency(analytics.sustainable_value, currency, '-')}
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {toPercentage(analytics.sustainable_percentage, '0 %')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('table.row2')}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {toCurrency(analytics.not_sustainable_value, currency, '-')}
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {toPercentage(analytics.not_sustainable_percentage, '0 %')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('table.row3')}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {toCurrency(analytics.non_eligible_value, currency, '-')}
              </TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {toPercentage(analytics.non_eligible_percentage, '0 %')}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>{t('table.row4')}</TableCell>
              <TableCell sx={{ textAlign: 'right' }}>
                {toCurrency(analytics.total_value, currency, '-')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <br />
      {analytics.error_objective_missing && (
        <Typography color="error" variant="body2" textAlign="right">
          {t('error_objective_missing')}
        </Typography>
      )}
    </>
  );
}
