import { Button, TableCell, TableRow } from '@mui/material';
import React from 'react';
import { useLanguage } from 'src/common/hooks';

interface Props {
  onSaveClick: () => void;
  colSpan?: number;
}

export default function SaveRow(props: Readonly<Props>) {
  const { onSaveClick, colSpan } = props;
  const { t } = useLanguage();
  return (
    <TableRow>
      <TableCell colSpan={colSpan} sx={{ textAlign: 'right' }}>
        <Button size="small" onClick={onSaveClick}>
          {t('esg:common.save')}
        </Button>
      </TableCell>
    </TableRow>
  );
}
