import React, { useState } from 'react';
import styled from 'styled-components';

import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from '../../../../utils/api-client';
import { useEsgOrganization } from 'src/common/hooks';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '../create/closeIcon';
import { StyledTextField } from '../../../../components/esg/Inputs/index.styles';
import { useTranslation } from 'react-i18next';
import { IntuitiveCalendarPeriod } from '../../../components/IntuitiveCalendar';
import { getFirstDateOfPeriod } from '../../../../utils/timeConverter';
import { EsgReportSchemaOut } from '../../../../Esg/api.types';
import ConfirmationModal from '../../../../components/ConfirmationModal';
import { Button, Dialog } from '@mui/material';
import { useReports } from 'src/Esg/hooks';
import { PropaneSharp } from '@mui/icons-material';

interface Props {
  open: boolean;
  setClose: () => void;
  shouldRedirect?: boolean;
  report: EsgReportSchemaOut;
}

const Title = styled.p`
  color: var(--main-dark-main, #38414f);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0.48px;
`;

const Close = styled.button`
  min-width: 48px;
  min-height: 48px;
  width: 48px;
  height: 48px;
  background-color: transparent;
  border-radius: 50%;
  padding: 0;
  position: absolute;
  right: -16px;
  top: -16px;
  
   &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }

  &:active {
    background-color: rgba(0, 0, 0, 0.08);
`;

const ButtonsWrapper = styled.div`
  display: flex;
  gap: 20px;
  justify-content: flex-end;
`;

function getYearFromDateStr(dateStr: string): Date {
  const year = new Date(dateStr).getFullYear();
  return new Date(year, 0, 1);
}

export default function EditNameModal({
  setClose,
  open,
  shouldRedirect = false,
  report,
}: Props) {
  const organization = useEsgOrganization();

  const [newName, setNewName] = useState<string>(report.name);
  const [newPeriod, setNewPeriod] = useState<Date | null>(
    getYearFromDateStr(report.period) || null
  );

  const nav = useNavigate();
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const [existedReport, setExistedReport] = useState<EsgReportSchemaOut | null>(
    null
  );

  const { reports } = useReports(organization.id);

  const handleReportEdit = () => {
    const matchingReport = reports.find(
      (r) =>
        r.period === String(newPeriod?.getFullYear()) &&
        r.id !== report.id &&
        r.questionnaire_methodology_id === report.questionnaire_methodology_id
    );
    if (matchingReport) setExistedReport(matchingReport);
    else mutate();
  };

  const handleReportEditClose = () => {
    setExistedReport(null);
    setNewPeriod(getYearFromDateStr(report.period) || null);
  };

  const { mutate } = useMutation({
    mutationFn: () => {
      return client.put(
        `web/esg_v2/${organization.id}/${report.id}`,
        JSON.stringify({ name: newName, period: newPeriod?.getFullYear() })
      );
    },
    onSuccess: () => {
      if (shouldRedirect) {
        nav(`/esg/reports/${report.id}`);
      }
      queryClient.invalidateQueries({ queryKey: [`esg-reports`] });
      queryClient.invalidateQueries({ queryKey: [`report_index`] });
      localStorage.setItem('esg_report', report.id.toString());
      setClose();
    },
  });

  return (
    <Dialog open={open} onClose={setClose}>
      <div
        style={{
          display: 'flex',
          position: 'relative',
          gap: 20,
          marginBottom: 28,
        }}
      >
        <Title>{t('esg:edit-report')}</Title>
        <Close type="button" onClick={setClose}>
          <CloseIcon />
        </Close>
      </div>
      <StyledTextField
        fullWidth
        mainColor="#5A636F"
        label={t('esg:edit-name-label')}
        value={newName}
        onChange={(e) => setNewName(e.target.value)}
      />
      <div style={{ height: 40 }} />
      <IntuitiveCalendarPeriod
        reportingPeriod="Y"
        setSelectedDate={setNewPeriod}
        selectedDate={getFirstDateOfPeriod(
          newPeriod ? new Date(newPeriod) : new Date(),
          'Y'
        )}
      />
      <div style={{ height: 40 }} />
      <hr style={{ color: '#ccc', width: '100%' }} />
      <div style={{ height: 20 }} />
      <ButtonsWrapper>
        <Button variant="secondary" size="large" onClick={setClose}>
          {t('esg:cancel')}
        </Button>
        <Button size="large" onClick={handleReportEdit}>
          {t('esg:save')}
        </Button>
      </ButtonsWrapper>

      <ConfirmationModal
        open={!!existedReport && existedReport.id !== report.id}
        onClose={() => setExistedReport(null)}
        onFalse={() => handleReportEditClose()}
        onTrue={() => nav(`/esg/reports/${existedReport?.id}`)}
        titleKey={t('esg:editModalWarning.title')}
        textKey={t('esg:editModalWarning.subtitle')}
      />
    </Dialog>
  );
}
