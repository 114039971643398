import { Box, Typography } from '@mui/material';
import React from 'react';
import { StyledFlex } from 'src/components/StyledFlex';

import { dateTimeFormat } from 'src/common/constants';
import { format } from 'date-fns';
import { usePlatform } from 'src/redux-file/hooks';
import AcceptableTextField from 'src/components/AcceptableTextField';
import { useTranslation } from 'react-i18next';
import {
  FieldAnswerCommentsOutSchema,
  FieldAnswerCommentsUpdateSchema,
} from 'src/QuestionnairesV3/types';

interface Props {
  readonly comment: FieldAnswerCommentsOutSchema;
  readonly onDelete: () => void;
  // eslint-disable-next-line no-unused-vars
  readonly onUpdate: (data: FieldAnswerCommentsUpdateSchema) => void;
}

export default function Comment(props: Props) {
  const { comment, onDelete, onUpdate } = props;
  const { user } = usePlatform();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'questionnaireV3.comments',
  });

  const [editing, setEditing] = React.useState(false);

  const createdAt = format(new Date(comment.created_at), dateTimeFormat);
  const updatedAt = format(new Date(comment.updated_at), dateTimeFormat);
  const wasEdited = updatedAt !== createdAt;
  const isAuthor = comment.created_by_id === user?.id;

  const onEditClick = () => setEditing(true);
  const handleAccept = (value: unknown) => {
    onUpdate({ ...comment, text: String(value) });
    setEditing(false);
  };

  return (
    <Box
      sx={{
        bgcolor: '#eee',
        borderRadius: '20px',
        p: '10px 20px',
        mt: '10px',
        border: '1px solid #e0e0e0',
      }}
    >
      <StyledFlex>
        <Typography variant="caption">{comment.created_by}</Typography>
        <Typography variant="caption">{createdAt}</Typography>
      </StyledFlex>
      <Box p="10px 0">
        {!editing && <Typography>{comment.text}</Typography>}
        {!!editing && (
          <AcceptableTextField
            fullWidth
            multiline
            value={comment.text}
            onReject={() => setEditing(false)}
            onAccept={handleAccept}
          />
        )}
      </Box>

      {!editing && (
        <StyledFlex>
          <Typography variant="caption">
            {wasEdited && `Edited: ${updatedAt}`}
          </Typography>
          {!!isAuthor && (
            <Box>
              <Typography
                variant="caption"
                fontWeight={700}
                sx={{ cursor: 'pointer' }}
                onClick={onEditClick}
              >
                {t('editComment')}
              </Typography>
              <span style={{ padding: '10px' }}>|</span>
              <Typography
                variant="caption"
                fontWeight={700}
                sx={{ cursor: 'pointer' }}
                onClick={onDelete}
              >
                {t('deleteComment')}
              </Typography>
            </Box>
          )}
        </StyledFlex>
      )}
    </Box>
  );
}
