import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';

import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';

import { Alert, Tab, Tabs } from '@mui/material';

import Breadcrumbs from 'src/Lca/components/Breadcrumbs';
import { Subtitle, Title } from 'src/Lca/components/Texts';
import LcaLayout from 'src/Lca/components/layout';
import ProductInformation from './productInformation';
import { client } from 'src/utils/api-client';
import Goals from './goals';
import Boundaries from './boundaries';
import LcaInfo from './lca-info';
import { BigButton, BigButtonWhite } from './index.styles';
import LifeCycle from './LifeCycle';
import _ from 'lodash';
import { useLcaOrganization } from 'src/common/hooks';
import { emptyProductForm, sanitizedValues } from '../utils';
import { StyledPanel } from 'src/Lca/styles';
import { useTranslation } from 'react-i18next';

export default function Steps() {
  const params = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const nav = useNavigate();
  const organization = useLcaOrganization();
  const { t } = useTranslation('lca');

  type InitValueType = typeof emptyProductForm;

  const valuesWithPlaceholders = (values: InitValueType): InitValueType => {
    const newValues = _.cloneDeep(values);
    const atLeastOne: (keyof InitValueType)[] = [
      'nace_industries',
      'distribution_regions',
    ];
    for (const key of atLeastOne) {
      // copy missing elements from initial data
      const list = newValues[key] as any[];
      if (list?.length === 0) {
        list.push((emptyProductForm[key] as any[])[0]);
      }
    }
    const nullPlaceholder: (keyof InitValueType)[] = [
      'production_country',
      'cpc_industry',
    ];
    for (const key of nullPlaceholder) {
      (newValues[key] as any) ??= emptyProductForm[key];
    }

    for (const is_basic of [true, false]) {
      if (!newValues.materials.some((x) => x.is_basic === is_basic)) {
        newValues.materials.push({
          tempId: new Date().getTime(),
          material_name: '',
          percentage: 0,
          is_basic,
        });
      }
    }
    return newValues;
  };

  const [values, setValues] = useState(emptyProductForm);
  const [remoteValues, setRemoteValues] = useState<InitValueType | undefined>(
    undefined
  );
  if (!!params.id) {
    useQuery(
      'asd',
      () => {
        return client.get(`/web/lca/products/${params.id}`);
      },
      {
        onSuccess: ({ data }) => {
          setProductId(data.id);
          setValues(valuesWithPlaceholders(data));
          setRemoteValues(data);
        },
      }
    );
  }

  const data = [
    { name: t('productList'), url: '/lca/products' },
    { name: t('newProduct'), url: '' },
    { name: t('productStep1'), url: '' },
  ];

  const dataTabs = [
    { name: 'prod-information' },
    { name: 'goals' },
    { name: 'lca-information' },
    { name: 'boundaries' },
  ];

  const dataTabsFull = [
    { name: 'prod-information' },
    { name: 'goals' },
    { name: 'lca-information' },
    { name: 'boundaries' },
    { name: 'cycle' },
  ];

  const [error, setError] = useState<any>('');
  const [productId, setProductId] = useState(0);

  const fullTabsAccess = !!productId && remoteValues?.lifecycle_boundaries;
  const tabs = fullTabsAccess ? dataTabsFull : dataTabs;
  const tab = location.hash.split('#').pop() || 'prod-information';

  const { mutate: mutatePut } = useMutation(
    () => {
      setError('');
      return client.put(
        `/web/lca/products/${productId || params.id}`,
        sanitizedValues(values, organization)
      );
    },
    {
      onError: (e) => {
        // @ts-ignore
        setError(e.response.data.message);
      },
      onSuccess: ({ data }) => {
        const hasBoundry = !!data?.lifecycle_boundaries;
        const _tabs = hasBoundry ? dataTabsFull : dataTabs;
        const currTab = _tabs.findIndex((t) => t.name === tab);
        const newTab = _tabs[currTab + 1]?.name;
        if (newTab) {
          navigate(`#${newTab}`);
          setTimeout(() => window.scrollTo(0, 0), 200);
        }
        setRemoteValues(data);
      },
    }
  );

  /* TODO: ogarnąć nawigację */

  if (!params.id || !parseInt(params.id, 10))
    return <Navigate to="/404" replace />;

  return (
    <LcaLayout>
      <Breadcrumbs data={data} />
      <Subtitle text={t('product.productAndLcaInformation')} />
      <div style={{ marginTop: '44px', marginBottom: '40px' }}>
        <Title text={!productId ? 'New product' : values.name} />
      </div>

      <Tabs value={tab} onChange={(_, value) => navigate(`#${value}`)}>
        {tabs.map((x: any) => (
          <Tab key={x.name} label={t(`tabs.${x.name}`)} value={x.name} />
        ))}
      </Tabs>

      {!!error && <Alert severity="error">{error}</Alert>}

      {tab === 'prod-information' && (
        <StyledPanel>
          <ProductInformation values={values} setValues={setValues} />
        </StyledPanel>
      )}
      {tab === 'goals' && (
        <StyledPanel>
          <Goals values={values} setValues={setValues} />
        </StyledPanel>
      )}
      {tab === 'lca-information' && (
        <StyledPanel>
          <LcaInfo values={values} setValues={setValues} />
        </StyledPanel>
      )}
      {tab === 'boundaries' && (
        <StyledPanel>
          <Boundaries values={values} setValues={setValues} />
        </StyledPanel>
      )}

      {tab === 'cycle' && (
        <div style={{ marginBottom: 200 }}>
          <LifeCycle productId={productId} />
        </div>
      )}

      {tab !== 'cycle' && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 40,
            marginBottom: 80,
          }}
        >
          <BigButtonWhite onClick={() => nav(-1)} type="button">
            {t('product.back')}
          </BigButtonWhite>
          <BigButton onClick={() => mutatePut()} type="button">
            {t('product.saveAndContinue')}
          </BigButton>
        </div>
      )}
    </LcaLayout>
  );
}
