import React from 'react';

import { Radio, TableCell, TableRow, Typography } from '@mui/material';
import { UUID } from 'crypto';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import { FigureType } from '../../../types';
import ObjectiveRow from './ObjectiveRow';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { client } from 'src/utils/api-client';

interface Props {
  reportActivityId: UUID;
}

export default function ActivityRow({ reportActivityId }: Readonly<Props>) {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.dnsh.table',
  });

  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activity = queries.activities._dataMap[reportActivity?.activity_id];
  const activityObjective = queries.activityObjectives._data.find(
    (ao) =>
      ao.report_activity_id === reportActivityId &&
      ao.type === FigureType.Turnover
  );

  const batchUpdateTurnoverDNSH = useMutation({
    mutationFn: (isCompliant: boolean) =>
      client.post(`/web/taxonomy/dnsh-batch/turnover`, {
        report_activity_id: reportActivityId,
        is_compliant: isCompliant,
      }),
    onSuccess: () => {
      queries.turnoverDNSH.refetch();
      reportAnalytics.refetch();
    },
  });

  if (queries.isLoading || reportAnalytics.isLoading) return null;

  const dnsh = reportAnalytics._instance?.turnover?.activities_analytics.find(
    (aa) => aa.report_activity_id === reportActivityId
  )?.is_dnsh_compliant;

  return (
    <>
      <TableRow>
        <TableCell colSpan={3}>
          <Typography>{activity.label}</Typography>
        </TableCell>
        {!activityObjective && <TableCell colSpan={2} />}
        {activityObjective && (
          <>
            <TableCell>
              <Radio
                key={`${reportActivityId}-compliant`}
                checked={dnsh}
                onClick={() => batchUpdateTurnoverDNSH.mutate(true)}
              />
            </TableCell>
            <TableCell>
              <Radio
                key={`${reportActivityId}-non-compliant`}
                checked={!dnsh}
                onClick={() => batchUpdateTurnoverDNSH.mutate(false)}
              />
            </TableCell>
          </>
        )}
      </TableRow>
      {!activityObjective && (
        <TableRow>
          <TableCell />
          <TableCell colSpan={2}>
            <Typography fontStyle="italic">{t('objectiveRequired')}</Typography>
          </TableCell>
          <TableCell colSpan={2} />
        </TableRow>
      )}
      {activityObjective &&
        queries.objectives._data.map((o) => (
          <ObjectiveRow
            key={o.id}
            reportActivityId={reportActivityId}
            objectiveId={o.id}
          />
        ))}
    </>
  );
}
