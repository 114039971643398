import React from 'react';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import { FigureType } from '../types';
import ViewContainer from '../components/ViewContainer';
import TaxonomyTabPanel from '../components/TaxonomyTabPanel';
import FuelsFormSection from './Section';

export default function TaxonomyFuelsForm() {
  const { taxonomyId } = useEsgTaxonomy();
  const [tab, setTab] = React.useState<FigureType>();
  if (!taxonomyId) return null;

  return (
    <ViewContainer title="">
      <TaxonomyTabPanel
        unmountTabOnExit
        tab={tab}
        onTabChange={setTab}
        turnoverComponent={<FuelsFormSection type={FigureType.Turnover} />}
        capexComponent={<FuelsFormSection type={FigureType.Capex} />}
        opexComponent={<FuelsFormSection type={FigureType.Opex} />}
      />
    </ViewContainer>
  );
}
