import { Dialog, DialogProps } from '@mui/material';
import { UUID } from 'crypto';
import React from 'react';
import Field from 'src/QuestionnairesV3/Fields/Field';
import { FieldAnswerOutSchema } from 'src/QuestionnairesV3/types';

interface Props extends DialogProps {
  questionId: UUID;
  fieldAnswer?: FieldAnswerOutSchema;
}

export default function FieldAnswerEditDialog(props: Readonly<Props>) {
  const { questionId, fieldAnswer, ..._props } = props;

  if (!fieldAnswer) return null;
  return (
    <Dialog {..._props} maxWidth="md">
      <Field
        questionId={questionId}
        questionAnswerId={fieldAnswer.question_answer_id}
        fieldId={fieldAnswer.field_id}
        options={{ alwaysShowButtons: true }}
      />
    </Dialog>
  );
}
