import React from 'react';
import jwtDecode from 'jwt-decode';
import { UUID } from 'crypto';

import { createTaxonomyQv3TokenFetcher } from './utils';
import { TaxonomyQv3Token } from '../types';
import QuestionnaireV3 from '../../../views/components/QuestionnaireV3';

// initially this is the only one qv3 questionnaire within taxonomy

interface Props {
  taxonomyId: UUID;
  questionnaireId: UUID;
}

export default function TaxonomyQuestionnaire(props: Readonly<Props>) {
  const { taxonomyId, questionnaireId } = props;

  const [questionnaireAnswerId, setQuestionnaireAnswerId] =
    React.useState<UUID | null>(null);

  // creating tokenFetcher for qv3
  const tokenFetcher = React.useMemo(
    () => createTaxonomyQv3TokenFetcher(taxonomyId, questionnaireId),
    [taxonomyId, questionnaireId]
  );

  // fetching initial questionnaireAnswerId (get/create) through tokenFetcher
  React.useEffect(() => {
    tokenFetcher().then((token) => {
      if (token) {
        const _token = jwtDecode<TaxonomyQv3Token>(token);
        if (_token.data.questionnaire_answer_id)
          setQuestionnaireAnswerId(_token.data.questionnaire_answer_id);
      }
    });
  }, [tokenFetcher]);

  // rendering guard
  if (!questionnaireAnswerId) return null;

  return (
    <QuestionnaireV3
      tokenAuth={tokenFetcher}
      questionnaireAnswerId={questionnaireAnswerId}
    />
  );
}
