import { Box } from '@mui/material';
import React from 'react';
import Section from '../components/Section';
import { useQuestions, useQv3Context } from '../hooks';
import Question from './Question';
import QuestionTable from './QuestionTable';

export default function Questions() {
  const { questionnaireId } = useQv3Context();
  const { _data: questions, isLoading } = useQuestions(
    { questionnaire_id: questionnaireId },
    { enabled: !!questionnaireId }
  );

  if (isLoading) return null;

  return (
    <Box>
      <Section section="questions" />
      {questions.map((question) => {
        if (question.table_id)
          return <QuestionTable key={question.id} questionId={question.id} />;
        return <Question key={question.id} question={question} />;
      })}
    </Box>
  );
}
