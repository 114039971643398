import React from 'react';

import { TableCell, TableRow, Typography } from '@mui/material';
import { UUID } from 'crypto';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import NumberField from 'src/components/NumberField';
import SaveRow from 'src/Esg/Taxonomy/components/SaveRow';
import {
  ExpenditureActivityAnalytics,
  ExpenditureAnalytics,
  ExpenditureContributionValueType,
  ExpenditureType,
  TaxonomyExpenditureContributionUpdateSchema,
  TaxonomyReportAnalytics,
} from 'src/Esg/Taxonomy/types';
import { toCurrency } from 'src/Esg/Taxonomy/utils';

interface Props {
  reportActivityId: UUID;
  figure: ExpenditureType;
  valueType: ExpenditureContributionValueType;
}

export default function ActivityRow(props: Readonly<Props>) {
  const { reportActivityId, valueType, figure } = props;
  const { taxonomyReport, queries, reportAnalytics } = useEsgTaxonomy();

  const [value, setValue] = React.useState<string | number | null>(0);

  const contribution = queries.expenditureContributions._data.find(
    (tv) =>
      tv.report_activity_id === reportActivityId &&
      tv.expenditure_type === figure
  );

  const _key =
    `contribution_${valueType}` as keyof TaxonomyExpenditureContributionUpdateSchema;
  const originalContributionValues = {
    contribution_a: contribution?.contribution_a ?? 0,
    contribution_b: contribution?.contribution_b ?? 0,
    contribution_c: contribution?.contribution_c ?? 0,
  };
  const originalValue = contribution?.[_key] ?? 0;

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activity = queries.activities._dataMap[reportActivity?.activity_id];

  React.useEffect(() => {
    if (originalValue) setValue(originalValue);
  }, [originalValue]);

  if (!taxonomyReport || queries.isLoading || reportAnalytics.isLoading)
    return null;

  const handleSaveClick = () =>
    contribution
      ? queries.expenditureContributions.update
          .mutateAsync({
            id: contribution.id,
            ...originalContributionValues,
            [_key]: value,
          })
          .then(() => {
            reportAnalytics.refetch();
          })
      : queries.expenditureContributions.create
          .mutateAsync({
            report_activity_id: reportActivityId,
            expenditure_type: figure,
            ...originalContributionValues,
            [_key]: value,
          })
          .then(() => {
            reportAnalytics.refetch();
          });

  const expenditureAnalytics = reportAnalytics._instance![
    figure.toLocaleLowerCase() as keyof TaxonomyReportAnalytics
  ] as ExpenditureAnalytics;
  const analytics = expenditureAnalytics.activities_analytics.find(
    (aa) => aa.report_activity_id === reportActivityId
  );

  const isModified = originalValue !== value;
  const tableCellsx = isModified ? { borderBottom: 'none' } : {};

  return (
    <>
      <TableRow>
        <TableCell sx={tableCellsx}>
          <Typography>{activity.label}</Typography>
        </TableCell>
        <TableCell sx={tableCellsx}>
          <NumberField
            fullWidth
            size="small"
            decimalPlaces={0}
            label="%"
            value={value}
            onChange={(value) => setValue(Number(value))}
          />
        </TableCell>
        <TableCell sx={tableCellsx}>
          {toCurrency(
            analytics?.[
              `${_key}_aligned` as keyof ExpenditureActivityAnalytics
            ] as number,
            taxonomyReport.currency_type
          )}
        </TableCell>
      </TableRow>
      {isModified && <SaveRow colSpan={7} onSaveClick={handleSaveClick} />}
    </>
  );
}
