import { createTheme } from '@mui/material';
import { UUID } from 'crypto';
import { createTokenFetcher } from 'src/QuestionnairesV3/utils';
import theme from 'src/theme';
import themeColors from 'src/theme/colors';
import { client } from 'src/utils/api-client';

export const createTaxonomyQv3TokenFetcher = (
  taxonomyId: UUID,
  questionnaireId: UUID
) => {
  const url = `/web/taxonomy/questionnaires/token`;
  const data = {
    taxonomy_id: taxonomyId,
    questionnaire_id: questionnaireId,
  };
  return createTokenFetcher(async () => {
    const res = await client.post(url, data);
    return res.data;
  });
};

export const taxonomyQv3Theme = createTheme({
  typography: theme.typography,
  palette: {
    primary: {
      main: themeColors.esgMain,
      light: themeColors.esgMainLight,
      contrastText: '#fff',
    },
  },
  components: {
    MuiToggleButton: {
      styleOverrides: {
        root: {
          borderRadius: '16px',
          textTransform: 'capitalize',
          '&.Mui-selected': {
            backgroundColor: '#38414F',
            color: '#fff',
            '&:hover': {
              backgroundColor: '#49525F',
            },
          },
        },
      },
    },
  },
});
