import React from 'react';
import { useTranslation } from 'react-i18next';
import { EditMaterialityTreshold } from '../Step5/Step5Table/Head';
import { Stack, Typography } from '@mui/material';

export default function MaterialityThreshold({
  threshold,
}: {
  threshold?: number;
}) {
  const { t } = useTranslation('esg');

  return (
    <Stack alignItems="end">
      <Typography variant="h6" align="right">
        {`${t('dma.step5TableV2.materialityThreshold')}: ${threshold}`}
      </Typography>
      <EditMaterialityTreshold />
    </Stack>
  );
}
