import { Box, Button, Dialog, SxProps, Theme } from '@mui/material';
import React from 'react';
import { useLanguage } from 'src/common/hooks';
import { StyledPanel } from 'src/Esg/styles';
import colors from 'src/theme/colors';

export type Props = {
  minimizedHeight?: number | string;
  children?: React.ReactNode;
  customButtonComponent?: React.ReactNode;
};

const fullScreenSxProps: SxProps<Theme> = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  zIndex: 1300, // default MuiDialog z-index (not used MuiDialog direclty as switching components re-renders children and we loose the state)
  backgroundColor: colors.background,
};

export default function FullScreenPanel({
  minimizedHeight = '600px',
  children,
  customButtonComponent,
}: Props) {
  const { t } = useLanguage();

  const [isFullScreen, setIsFullScreen] = React.useState(false);

  return (
    <Box sx={isFullScreen ? fullScreenSxProps : {}}>
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{ my: 2, mx: isFullScreen ? 2 : 0 }}
      >
        <Button
          onClick={() => setIsFullScreen(!isFullScreen)}
          variant="outlined"
        >
          {isFullScreen ? t('fullScreen.close') : t('fullScreen.open')}
        </Button>
        {customButtonComponent}
      </Box>
      <StyledPanel
        sx={{
          p: 0,
          overflow: 'auto',
          height: isFullScreen ? 'calc(100vh - 72px)' : minimizedHeight,
        }}
      >
        {children}
      </StyledPanel>
    </Box>
  );
}
