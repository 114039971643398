import React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { StyledTextField } from '../productInformation';
import { useTranslation } from 'react-i18next';

interface Props {
  values: {
    goals: {
      intended_applications: string;
      study_reasons: string;
      target_audience: string;
      comissioner_name: string;
      external_experts_involved: boolean;
      external_experts_description: string;
      results_disclosed: boolean;
      assertions_disclosed: boolean;
      results_confidential: boolean;
      assertions_confidential: boolean;
      disclosure_description: string;
      comment: string;
    };
  };
  setValues: Function;
}

const Icon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="15"
      y="15"
      width="18"
      height="18"
      rx="1"
      fill="white"
      stroke="#7E4308"
      strokeWidth="2"
    />
  </svg>
);

const CheckedIcon = (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="15" y="15" width="18" height="18" fill="white" />
    <path
      d="M31.5 14H16.5C15.125 14 14 15.125 14 16.5V31.5C14 32.875 15.125 34 16.5 34H31.5C32.875 34 34 32.875 34 31.5V16.5C34 15.125 32.875 14 31.5 14ZM21.2 28.1L18.6125 25.5C18.1375 25.0125 18.1375 24.2375 18.6125 23.75C19.1 23.2625 19.8875 23.2625 20.375 23.75L22.075 25.4625L27.6 19.8875C28.0875 19.4 28.875 19.4 29.3625 19.8875C29.8375 20.375 29.8375 21.15 29.3625 21.6375L22.95 28.1C22.4875 28.6 21.6875 28.6 21.2 28.1Z"
      fill="#7E4308"
    />
  </svg>
);

export default function Assertions({ setValues, values }: Props) {
  const { t } = useTranslation('lca', { keyPrefix: 'assertions' });
  const handle_disclosure_description = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setValues({
      ...values,
      goals: {
        ...values.goals,
        disclosure_description: e.target.value,
      },
    });
  };

  const handleChange = (
    name:
      | 'results_disclosed'
      | 'assertions_confidential'
      | 'assertions_disclosed'
      | 'results_confidential'
  ) => {
    setValues({
      ...values,
      goals: {
        ...values.goals,
        [name]: !values.goals[name],
      },
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => handleChange('results_disclosed')}
              checked={values.goals.results_disclosed}
              checkedIcon={CheckedIcon}
              icon={Icon}
            />
          }
          label={t('resultsDisclosed')}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => handleChange('assertions_disclosed')}
              checked={values.goals.assertions_disclosed}
              checkedIcon={CheckedIcon}
              icon={Icon}
            />
          }
          label={t('comparativeResultsDisclosed')}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => handleChange('results_confidential')}
              checked={values.goals.results_confidential}
              checkedIcon={CheckedIcon}
              icon={Icon}
            />
          }
          label={t('resultsConfidential')}
        />
        <FormControlLabel
          control={
            <Checkbox
              onChange={() => handleChange('assertions_confidential')}
              checked={values.goals.assertions_confidential}
              checkedIcon={CheckedIcon}
              icon={Icon}
            />
          }
          label={t('comparativeAssertionsConfidential')}
        />
      </FormGroup>
      <StyledTextField
        fullWidth
        label={t('otherPleaseDescribe')}
        name="comissioner_name"
        value={values.goals.disclosure_description}
        onChange={handle_disclosure_description}
      />
    </div>
  );
}
