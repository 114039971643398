import React from 'react';
import { Box } from '@mui/material';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import TaxonomyQuestionnaire from '../components/Questionnaire';
import { FigureType } from '../types';
import { UUID } from 'crypto';

const questionnaireForTypes: Record<FigureType, UUID> = {
  [FigureType.Turnover]: 'b70d546e-077a-45ef-b513-b8fa0c509959',
  [FigureType.Capex]: '5d8944bc-a51f-4a87-9e6b-ce19f1f6673a',
  [FigureType.Opex]: '11848264-907b-4c43-969e-2c93a7296b11',
};

interface Props {
  type: FigureType;
}

export default function FuelsFormSection({ type }: Readonly<Props>) {
  const { taxonomyId } = useEsgTaxonomy();
  if (!taxonomyId) return null;
  return (
    <Box sx={{ p: '0px 64px' }}>
      <TaxonomyQuestionnaire
        taxonomyId={taxonomyId}
        questionnaireId={questionnaireForTypes[type]}
      />
    </Box>
  );
}
