import React from 'react';
import { StyledPanel } from '../styles';
import { StyledFlex } from 'src/components/StyledFlex';
import { Chip, MenuItem } from '@mui/material';
import FilterField from './components/FileterField';
import { EsgReportSchemaOut } from '../api.types';
import { useTranslation } from 'react-i18next';
import { useQuestionnaireMethodology } from '../hooks';

interface Props {
  // eslint-disable-next-line no-unused-vars
  setActiveReportId: (reportId: number) => void;
  activeReport: EsgReportSchemaOut;
  reports: EsgReportSchemaOut[];
}
export default function FilterPanel(props: Props) {
  const { t: _t } = useTranslation();
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:administrationPanel',
  });
  const { activeReport, setActiveReportId } = props;
  const { _dataMap } = useQuestionnaireMethodology();

  return (
    <StyledPanel>
      <StyledFlex sx={{ justifyContent: 'flex-start' }}>
        <FilterField placeholder={t('filters.reports')}>
          {props.reports.map((report) => (
            <MenuItem
              key={report.id}
              selected={activeReport.id === report.id}
              value={report.id}
              onClick={() => setActiveReportId(report.id)}
            >
              {report.name}
              {' | '}
              {report.period}:{' '}
              {_dataMap[report.questionnaire_methodology_id]?.description}
            </MenuItem>
          ))}
        </FilterField>
        <FilterField disabled placeholder={t('filters.subjects')} />
        <FilterField disabled placeholder={t('filters.responsibleUsers')} />
        <FilterField disabled placeholder={t('filters.reportingPeriod')} />
      </StyledFlex>
      <br />
      <Chip
        sx={{ backgroundColor: '#A1DF76' }}
        label={`${t('common.report')} ${activeReport.name} (${
          activeReport.period
        })`}
      />
    </StyledPanel>
  );
}
