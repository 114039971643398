import React from 'react';
import DOMPurify from 'dompurify';
import {
  Checkbox,
  IconButton,
  TableCell,
  TableRow,
  Typography,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ConfirmationModal from 'src/components/ConfirmationModal';
import colors from 'src/theme/colors';
import { UUID } from 'crypto';
import { useTranslation } from 'react-i18next';
import { useEsgTaxonomy } from 'src/Esg/hooks';

interface Props {
  reportActivityId: UUID;
}

export default function ActivityRow(props: Readonly<Props>) {
  const { reportActivityId } = props;
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.selection' });
  const { taxonomyReport, queries } = useEsgTaxonomy();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    React.useState<boolean>(false);

  if (queries.isLoading || !taxonomyReport) return null;

  const reportActivity = queries.reportActivities._dataMap[reportActivityId];
  const activity = queries.activities._dataMap[reportActivity.activity_id];

  return (
    <TableRow>
      <TableCell
        sx={{
          pl: '64px',
          backgroundColor: colors.backgroundInput,
        }}
      >
        <Typography>{activity.label}</Typography>
      </TableCell>
      <TableCell sx={{ backgroundColor: colors.backgroundInput }}>
        <Typography
          variant="captionText"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(activity.description),
          }}
        />
      </TableCell>

      {taxonomyReport.turnover_enabled && (
        <TableCell
          align="center"
          sx={{ backgroundColor: colors.backgroundInput }}
        >
          <Checkbox
            checked={reportActivity.turnover_enabled}
            onChange={(e) =>
              queries.reportActivities.update.mutate({
                ...reportActivity,
                turnover_enabled: e.target.checked,
              })
            }
          />
        </TableCell>
      )}
      {taxonomyReport.capex_enabled && (
        <TableCell
          align="center"
          sx={{ backgroundColor: colors.backgroundInput }}
        >
          <Checkbox
            checked={reportActivity.capex_enabled}
            onChange={(e) =>
              queries.reportActivities.update.mutate({
                ...reportActivity,
                capex_enabled: e.target.checked,
              })
            }
          />
        </TableCell>
      )}
      {taxonomyReport.opex_enabled && (
        <TableCell
          align="center"
          sx={{ backgroundColor: colors.backgroundInput }}
        >
          <Checkbox
            checked={reportActivity.opex_enabled}
            onChange={(e) =>
              queries.reportActivities.update.mutate({
                ...reportActivity,
                opex_enabled: e.target.checked,
              })
            }
          />
        </TableCell>
      )}

      <TableCell
        sx={{
          backgroundColor: colors.backgroundInput,
        }}
      >
        <IconButton onClick={() => setDeleteConfirmationOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={() => queries.reportActivities._delete.mutate(reportActivityId)}
        titleKey={t('removeActivity')}
      />
    </TableRow>
  );
}
