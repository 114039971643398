import { UUID } from 'crypto';
import { AnsweredQuestionsSchema, IndicatorSchema } from '../types';
import { EsgReportSchemaOut } from '../api.types';

export const getIndicatorProgress = (
  indicator: IndicatorSchema | undefined,
  answeredQuestions: UUID[]
) => {
  if (!indicator) return 0;
  const { questions_count: questionsCount } = indicator;
  if (!questionsCount || !answeredQuestions?.length) return 0;

  const uniqueAnswers = new Set(answeredQuestions);
  return Math.round((uniqueAnswers.size / questionsCount) * 100);
};

export const getReportProgress = (
  indicators: IndicatorSchema[],
  answeredQuestions: AnsweredQuestionsSchema,
  report: EsgReportSchemaOut
) => {
  const reportAnsweredQuestions = answeredQuestions[report.id];
  if (!reportAnsweredQuestions) return 0;

  const uniqueAnswered = new Set<string>();
  Object.values(reportAnsweredQuestions).forEach((ids) => {
    ids.forEach((id) => uniqueAnswered.add(id));
  });
  const answeredQuestionsCount = uniqueAnswered.size;

  const allQuestionsCount = indicators.reduce(
    (total, i) => total + i.questions_count,
    0
  );

  if (!allQuestionsCount || !answeredQuestionsCount) return 0;

  return Math.round((answeredQuestionsCount / allQuestionsCount) * 100);
};
