import React from 'react';
import { StyledTextField } from '../productInformation';
import { useTranslation } from 'react-i18next';

interface FormAssumptionsProps {
  values: {
    production_process_description: string;
    assumptions_description: string;
    reference_service_life: string;
    cutoff_rules: string;
    additional_information: string;
  };
  setValues: Function;
}
export default function FormAssumptions({
  setValues,
  values,
}: FormAssumptionsProps) {
  const { t } = useTranslation('lca', { keyPrefix: 'assumptions' });
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    name: string
  ) => {
    setValues({
      ...values,
      [name]: e.target.value,
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        gap: '24px',
        flexDirection: 'column',
      }}
    >
      <StyledTextField
        fullWidth
        label={t('describeProcess')}
        name="production_process_description"
        value={values.production_process_description}
        onChange={(e) => handleChange(e, 'production_process_description')}
        multiline
        rows={4}
      />
      <StyledTextField
        fullWidth
        label={t('describeAssumptions')}
        name="assumptions_description"
        value={values.assumptions_description}
        onChange={(e) => handleChange(e, 'assumptions_description')}
        multiline
        rows={4}
      />
      <StyledTextField
        fullWidth
        label={t('referenceServiceLife')}
        name="reference_service_life"
        value={values.reference_service_life}
        onChange={(e) => handleChange(e, 'reference_service_life')}
      />
      <StyledTextField
        fullWidth
        label={t('cutoffRules')}
        name="cutoff_rules"
        value={values.cutoff_rules}
        onChange={(e) => handleChange(e, 'cutoff_rules')}
        multiline
        rows={4}
      />
      <StyledTextField
        fullWidth
        label={t('optionalAdditionalInformation')}
        name="additional_information"
        value={values.additional_information}
        onChange={(e) => handleChange(e, 'additional_information')}
        multiline
        rows={4}
      />
    </div>
  );
}
