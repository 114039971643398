import { useMutation, useQuery, useQueryClient } from 'react-query';
import { client } from 'src/utils/api-client';
import {
  AnsweredQuestionsSchema,
  EsgQuestionnaireAnswerOutSchema,
  EsgQuestionnaireAnswerReviewInSchema,
  EsgQuestionnaireAnswerReviewOutSchema,
  EsgQuestionnaireMethodologyOutSchema,
  EsgQuestionnaireSimpleSchema,
  IndicatorResponsibility,
  IndicatorSchema,
} from './types';
import { getOrganizationReports } from './api';
import { toast } from 'react-toastify';
import {
  QueryOptions,
  useEsgOrganization,
  useLanguage,
  useResourceController,
} from 'src/common/hooks';
import { useTranslation } from 'react-i18next';
import {
  EsgStakeholdersTypeInSchema,
  EsgStakeholdersTypeSchema,
} from './Dma/Stakeholders/SelectingStakeholders/SelectingStakeholdersTable/index.types';
import { EsgReportInSchema, EsgReportOutSchema } from './api.types';
import { useParams } from 'react-router-dom';
import {
  useActivities,
  useDNSHCriterias,
  useMainCriterias,
  useObjectives,
  useSectors,
  useTaxonomyExpenditureContributions,
  useTaxonomyExpenditureDNSH,
  useTaxonomyExpenditureValues,
  useTaxonomyReportActivities,
  useTaxonomyReportActivityObjectives,
  useTaxonomyReportAnalytics,
  useTaxonomyReports,
  useTaxonomyReportSectors,
  useTaxonomyReportTotalValues,
  useTaxonomyTurnoverContributions,
  useTaxonomyTurnoverDNSH,
  useTaxonomyTurnoverValues,
} from './Taxonomy/hooks';
import { UUID } from 'crypto';

export const useIndicators = (esgReportId: number | null | undefined) => {
  const { language } = useLanguage();
  const url = '/web/esg-administration-panel';
  const params = { esg_report_id: esgReportId };

  const { data: indicators } = useQuery(
    ['indicators', esgReportId, language],
    () =>
      client
        .get<IndicatorSchema[]>(`${url}/indicators`, { params })
        .then((res) => res.data),
    { enabled: !!esgReportId }
  );

  const { data: answeredQuestions } = useQuery(
    ['answered-esg-questions', esgReportId],
    () =>
      client
        .get<AnsweredQuestionsSchema>(`${url}/answered-esg-questions`, {
          params,
        })
        .then((res) => res.data),
    { enabled: !!esgReportId }
  );

  return {
    indicators: indicators || [],
    answeredQuestions: answeredQuestions || {},
  };
};

export const useEsgReports = (queryParams: Partial<EsgReportOutSchema> = {}) =>
  useResourceController<EsgReportInSchema, EsgReportOutSchema>(
    `/web/esg/v3/reports`,
    ['dma', 'esg-reports', queryParams],
    queryParams
  );

export const useReports = (orgId: number | null = null) => {
  const { id: activeOrganizationId } = useEsgOrganization();
  const organizationId = orgId || activeOrganizationId;
  const queryKey = ['reports', { organizationId }];
  const queryClient = useQueryClient();

  const query = useQuery(queryKey, () =>
    getOrganizationReports({
      organizationId: organizationId || activeOrganizationId,
    })
  );

  const create = useMutation({
    mutationFn: (data: EsgReportInSchema) =>
      client.post(`web/esg_v2/${organizationId}`, data).then((res) => res.data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey });
    },
  });

  return { reports: query.data || [], ...query, create };
};

export const useIndicatorsResponsibilites = (
  esgReportId: number | null | undefined
) => {
  const queryKey = ['indicators-responsibilities', { esgReportId }];
  const url = '/web/esg-administration-panel/indicators-responsibilities';
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);
  const { t } = useTranslation(undefined, {
    keyPrefix: 'esg:administrationPanel.toasts',
  });
  const params = { esg_report_id: esgReportId };

  const { data } = useQuery(
    queryKey,
    () =>
      client
        .get<IndicatorResponsibility[]>(url, { params })
        .then((res) => res.data),
    { enabled: !!esgReportId }
  );

  const createIndicatorResponsibility = useMutation({
    mutationFn: (data: Omit<IndicatorResponsibility, 'id'>) =>
      client.post(url, data),
    onSuccess: () => {
      invalidateQuery();
      toast.success(t('responsibilityCreated') as string);
    },
  });

  const updateIndicatorResponsibility = useMutation({
    mutationFn: (data: IndicatorResponsibility) =>
      client.put(`${url}/${data.id}`, data),
    onSuccess: () => {
      invalidateQuery();
      toast.success(t('responsibilityUpdated') as string);
    },
  });

  const deleteIndicatorResponsibility = useMutation({
    mutationFn: (id: number) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      invalidateQuery();
      toast.success(t('responsibilityDeleted') as string);
    },
  });

  const notify = useMutation({
    mutationFn: (id: number) => client.post(`${url}/${id}/notify`),
  });

  return {
    data: data || [],
    create: createIndicatorResponsibility,
    update: updateIndicatorResponsibility,
    delete: deleteIndicatorResponsibility,
    notify,
  };
};

export const useEsgStakeholderTypes = (queryParams?: { esg_dma_id?: UUID }) => {
  const url = `/web/esg/v3/stakeholders/types`;
  const queryKey = ['esg', 'stakeholder-types'];
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);

  const query = useQuery(queryKey, () =>
    client
      .get<EsgStakeholdersTypeSchema[]>(url, { params: queryParams })
      .then((res) => res.data)
  );

  const data = query.data || [];

  const dataMap = data.reduce(
    (
      acc: Record<number, EsgStakeholdersTypeSchema>,
      item: EsgStakeholdersTypeSchema
    ) => {
      acc[item.id] = item;
      return acc;
    },
    {}
  );

  const create = useMutation({
    mutationFn: (data: EsgStakeholdersTypeInSchema) =>
      client.post<EsgStakeholdersTypeSchema>(url, data).then((res) => res.data),
    onSuccess: () => {
      invalidateQuery();
    },
  });

  const _delete = useMutation({
    mutationFn: (id: UUID) => client.delete(`${url}/${id}`),
    onSuccess: () => {
      invalidateQuery();
    },
  });

  return { data, dataMap, create, _delete };
};

export const useEsgQuestionnaires = (questionnaireMethodologyId?: UUID) => {
  const { language } = useLanguage();
  const url = '/web/esg/v3/esg-questionnaires';
  const queryKey = [
    'esg',
    'questionnaires',
    questionnaireMethodologyId,
    language,
  ];
  const query = useQuery(
    queryKey,
    () =>
      client
        .get<EsgQuestionnaireSimpleSchema[]>(url, {
          params: { questionnaire_methodology_id: questionnaireMethodologyId },
        })
        .then((res) => res.data),
    { enabled: !!questionnaireMethodologyId }
  );

  return { ...query, data: query.data || [] };
};

export const useEsgTaxonomy = () => {
  const { reportId } = useParams();
  const esgReports = useEsgReports({ id: Number(reportId) });
  const taxonomyId = esgReports._instance?.taxonomy_id;
  const taxonomyReports = useTaxonomyReports({ id: taxonomyId });

  const activities = useActivities();
  const sectors = useSectors();
  const objectives = useObjectives();
  const mainCriterias = useMainCriterias();
  const dnshCriterias = useDNSHCriterias();

  const reportSectors = useTaxonomyReportSectors({
    report_id: taxonomyId,
  });
  const reportActivities = useTaxonomyReportActivities({
    report_id: taxonomyId,
  });
  const reportTotalValues = useTaxonomyReportTotalValues({
    report_id: taxonomyId,
  });
  const turnoverValues = useTaxonomyTurnoverValues({
    report_activity__report_id: taxonomyId,
  });
  const expenditureValues = useTaxonomyExpenditureValues({
    report_activity__report_id: taxonomyId,
  });
  const activityObjectives = useTaxonomyReportActivityObjectives({
    report_activity__report_id: taxonomyId,
  });
  const turnoverDNSH = useTaxonomyTurnoverDNSH({
    report_activity__report_id: taxonomyId,
  });
  const expenditureDNSH = useTaxonomyExpenditureDNSH({
    report_activity__report_id: taxonomyId,
  });
  const turnoverContributions = useTaxonomyTurnoverContributions({
    report_activity__report_id: taxonomyId,
  });
  const expenditureContributions = useTaxonomyExpenditureContributions({
    report_activity__report_id: taxonomyId,
  });
  const reportAnalytics = useTaxonomyReportAnalytics({
    id: taxonomyId,
  });

  const queries = {
    esgReports,
    taxonomyReports,
    activities,
    sectors,
    objectives,
    mainCriterias,
    dnshCriterias,
    reportSectors,
    reportActivities,
    reportTotalValues,
    turnoverValues,
    expenditureValues,
    activityObjectives,
    turnoverDNSH,
    expenditureDNSH,
    turnoverContributions,
    expenditureContributions,
    reportAnalytics,
  };
  const isLoading = Object.values(queries).some((q) => q.isLoading);

  return {
    esgReport: esgReports._instance,
    taxonomyReport: taxonomyReports._instance,
    taxonomyId,
    reportAnalytics,
    queries: { isLoading, ...queries },
  };
};

export const useEsgQuestionnaireAnswer = (
  questionnaireAnswerId: UUID | null | undefined
) => {
  const url = `/web/esg/v3/esg-questionnaires/answers/${questionnaireAnswerId}`;
  const queryKey = ['esg', 'questionnaire-answers', questionnaireAnswerId];
  const queryClient = useQueryClient();
  const invalidateQuery = () => queryClient.invalidateQueries(queryKey);

  const query = useQuery(
    queryKey,
    () =>
      client.get<EsgQuestionnaireAnswerOutSchema>(url).then((res) => res.data),
    { enabled: !!questionnaireAnswerId }
  );

  const setIsCompleted = useMutation({
    mutationFn: (is_completed: boolean) =>
      client
        .put(`${url}/set-is-completed`, { is_completed })
        .then((res) => res.data),
    onSuccess: () => {
      invalidateQuery();
    },
  });

  return { ...query, setIsCompleted };
};

export const useEsgQuestionnaireAnswerReview = (
  queryParams: { esg_questionnaire_answer_id: UUID },
  queryOptions:
    | QueryOptions<EsgQuestionnaireAnswerReviewOutSchema>
    | undefined = undefined
) =>
  useResourceController<
    EsgQuestionnaireAnswerReviewInSchema,
    EsgQuestionnaireAnswerReviewOutSchema
  >(
    `/web/esg/v3/esg-questionnaires/reviews`,
    ['esg', 'questionnaire-answers-review', queryParams],
    queryParams,
    queryOptions
  );

export const useQuestionnaireMethodology = (
  queryParams: Partial<EsgQuestionnaireMethodologyOutSchema> = {}
) => {
  const { language } = useLanguage();
  return useResourceController<undefined, EsgQuestionnaireMethodologyOutSchema>(
    `/web/esg/v3/esg-questionnaires/methodologies`,
    ['esg', 'questionnaire-methodology', language, queryParams],
    queryParams
  );
};
