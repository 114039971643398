import React from 'react';
import {
  List,
  ListItem,
  Collapse,
  Typography,
  IconButton,
  Box,
} from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { useDmaHierarchy, useStakeholderTopics } from 'src/Esg/Dmav2/hooks';
import SubTopic from './Subtopic';

interface Props {
  esg_stakeholder_data_id: number;
  topic_id: number;
}
export default function Topic(props: Readonly<Props>) {
  const { esg_stakeholder_data_id, topic_id } = props;
  const { topics } = useDmaHierarchy({});
  const { subTopics } = useStakeholderTopics(esg_stakeholder_data_id);
  const topic = topics.filter((t) => t.id === topic_id)[0];
  const subtopics = subTopics.filter((sub) => sub.topic_id === topic_id);
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton size="small" onClick={() => setOpen((prev) => !prev)}>
          {open ? <ExpandLess /> : <ExpandMore />}
        </IconButton>
        <Typography variant="h4">{topic.name}</Typography>
      </Box>
      <Collapse in={open}>
        <List disablePadding>
          {subtopics.map((subtopic) => (
            <ListItem
              key={subtopic.id}
              sx={{
                pl: '4px',
                display: 'block',
                borderLeft: '1px solid gray',
                marginLeft: '16px',
              }}
            >
              <SubTopic
                esg_stakeholder_data_id={esg_stakeholder_data_id}
                sub_topic_id={subtopic.id}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
}
