import React from 'react';
import {
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  Typography,
  Alert,
} from '@mui/material';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';
import LightTooltip from 'src/components/LightTooltip';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { useEsgDmas } from '../../hooks';
import { useParams } from 'react-router-dom';
import { useEsgReports } from 'src/Esg/hooks';
import AcceptableNumberField from 'src/components/NumberField/AcceptableNumberField';
import { useQueryClient } from 'react-query';

interface EditButtonWithTooltipProps {
  title: string;
  buttonText: string;
  onClick: () => void;
}

function EditButtonWithTooltip({
  title,
  buttonText,
  onClick,
}: EditButtonWithTooltipProps) {
  return (
    <LightTooltip title={title}>
      <Button
        variant="text"
        endIcon={<EditOutlinedIcon />}
        disableRipple
        onClick={onClick}
        sx={{
          minWidth: 'unset',
          height: 'unset',
          fontWeight: 'normal',
          fontStyle: 'italic',
          p: 0,
          mt: '8px',
          '&:hover': { backgroundColor: colors.backgroundInput },
        }}
      >
        {buttonText}
      </Button>
    </LightTooltip>
  );
}

export function EditMaterialityTreshold() {
  const { t } = useTranslation('esg', { keyPrefix: 'dma.step5TableV2' });
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma, update } = useEsgDmas(
    { id: report?.esg_dma_id },
    { enabled: !!report }
  );
  const queryClient = useQueryClient();

  const threshold = dma?.materiality_threshold ?? 0;

  React.useEffect(() => {
    setValue(threshold);
  }, [dma]);

  const onAccept = (value: unknown) => {
    if (!dma) return;
    update
      .mutateAsync({
        id: dma.id,
        materiality_threshold: value as number,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['dma'] });
        setOpen(false);
      });
  };

  return (
    <>
      <EditButtonWithTooltip
        title={`${t('materialityThreshold')}: ${threshold}`}
        buttonText={t('edit')}
        onClick={() => setOpen(true)}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Typography variant="h6">{t('adjustMaterialityThreshold')}</Typography>
        {dma && (
          <>
            <Stack direction="row" alignItems="center" sx={{ mt: '16px' }}>
              <Typography sx={{ mr: '8px' }}>
                {t('materialityThreshold')}:
              </Typography>
              <AcceptableNumberField
                value={value}
                onAccept={onAccept}
                onReject={() => setOpen(false)}
                size="small"
                maxDigits={1}
                decimalPlaces={2}
                sx={{ width: '72px' }}
                disabled={!dma.allow_adjust_materiality_threshold}
              />
            </Stack>
            {!dma.allow_adjust_materiality_threshold && (
              <Alert severity="info" sx={{ my: '8px' }}>
                {t('contactSupportToAdjust')}
              </Alert>
            )}
          </>
        )}
      </Dialog>
    </>
  );
}

function EditStakeholderSurveyWeight() {
  const { t } = useTranslation('esg', { keyPrefix: 'dma.step5TableV2' });
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(0);

  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { _instance: dma, update } = useEsgDmas(
    { id: report?.esg_dma_id },
    { enabled: !!report }
  );
  const scoreWeightPercentage = 100 * (dma?.stakeholder_score_weight ?? 0);

  React.useEffect(() => {
    setValue(scoreWeightPercentage);
  }, [scoreWeightPercentage]);

  const queryClient = useQueryClient();

  const onAccept = (value: unknown) => {
    if (!dma) return;
    update
      .mutateAsync({
        id: dma.id,
        stakeholder_score_weight: (value as number) / 100,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['dma'] });
        setOpen(false);
      });
  };

  return (
    <>
      <EditButtonWithTooltip
        title={`${t('stakeholderScoreWeight')}: ${scoreWeightPercentage}%`}
        buttonText={t('edit')}
        onClick={() => setOpen(true)}
      />
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Typography variant="h6">
          {t('adjustStakeholderScoreWeight')}
        </Typography>
        {dma && (
          <>
            <Stack direction="row" alignItems="center" sx={{ mt: '16px' }}>
              <Typography sx={{ mr: '8px' }}>
                {t('stakeholderScoreWeight')}:
              </Typography>
              <AcceptableNumberField
                value={value}
                onAccept={onAccept}
                onReject={() => setOpen(false)}
                size="small"
                maxDigits={2}
                decimalPlaces={0}
                sx={{ width: '72px' }}
                showPercentage
                disabled={!dma.allow_adjust_stakeholder_score_weight}
              />
            </Stack>
            {!dma.allow_adjust_stakeholder_score_weight && (
              <Alert severity="info" sx={{ my: '8px' }}>
                {t('contactSupportToAdjust')}
              </Alert>
            )}
          </>
        )}
        <Typography fontStyle="italic" sx={{ mt: '16px' }}>
          {t('stakeholderScoreWeightNote')}
        </Typography>
      </Dialog>
    </>
  );
}

export default function Head() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'dma.step5TableV2.head',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          rowSpan={2}
          colSpan={1}
        >
          {t('perspective')}
        </TableCell>
        <TableCell colSpan={8} align="center" sx={{ height: '41px' }}>
          {t('impactEvaluation')}
        </TableCell>
        <TableCell colSpan={4} align="center" sx={{ height: '41px' }}>
          {t('financialEvaluation')}
        </TableCell>
      </TableRow>
      <TableRow
        sx={{
          // fix for the sticky header https://stackoverflow.com/a/74700805
          '& .MuiTableCell-root': {
            top: '41px',
          },
        }}
      >
        <TableCell>{t('scale')}</TableCell>
        <TableCell>{t('scope')}</TableCell>
        <TableCell>{t('probability')}</TableCell>
        <TableCell>{t('irreversible')}</TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('impactResult')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          <Stack sx={{ alignItems: 'center' }}>
            {t('stakeholdersResult')}
            <EditStakeholderSurveyWeight />
          </Stack>
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('finalResult')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          <Stack sx={{ alignItems: 'center' }}>
            {t('isImpactMaterial')}
            <EditMaterialityTreshold />
          </Stack>
        </TableCell>
        <TableCell>{t('financialScale')}</TableCell>
        <TableCell>{t('probability')}</TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('financialResult')}
        </TableCell>
        <TableCell
          style={{ backgroundColor: colors.backgroundInput }}
          align="center"
        >
          {t('isRiskOpportunityMaterial')}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
