import * as jwt from 'src/utils/jwt';
import {
  DecimalWithUnitSchema,
  FieldOptionOutSchema,
  FieldType,
  FieldValueSchema,
  ValuesSchema,
} from './types';
import { UUID } from 'crypto';

export const MAIN_COLOR = '#E9BC46';
export const TEXT_COLOR = '#38414F'; // headlines, values
export const TEXT_COLOR_SECONDARY = '#49525F'; // paragraphs, labels

export const createTokenFetcher = (
  tokenProvider: () => Promise<string>,
  initialToken?: string
) => {
  let token: string | undefined = initialToken;

  const fetchtoken = async () => {
    const payload = jwt.decodeToken(token);
    if (!payload) {
      try {
        token = await tokenProvider();
        jwt.tryUpdateTimestampSkewFromFreshToken(token);
      } catch (err) {
        console.error(err);
      }
    }
    return token;
  };
  return fetchtoken;
};

export const genRandomUUID = (prefix: string = '') =>
  `${prefix}${crypto.randomUUID()}`;

export const getStateQuestionWithAnswers = (values: ValuesSchema) => {
  const result: { [questionId: string]: string[] } = {};
  Object.keys(values).forEach((key) => {
    const [questionId, questionAnswerId, ..._] = key.split('::');
    if (!result[questionId]) result[questionId] = [];
    if (!result[questionId].includes(questionAnswerId))
      result[questionId].push(questionAnswerId);
  });
  return result;
};

export const fieldValueToString = (
  type: FieldType,
  value?: FieldValueSchema,
  optionsMap?: Record<number | UUID, FieldOptionOutSchema>
) => {
  if (!value) return '-';

  switch (type) {
    case FieldType.decimal_with_unit: {
      const { value: _value, unit } = value as DecimalWithUnitSchema;
      return `${_value} ${unit}`;
    }
    case FieldType.text:
      return value as string;

    case FieldType.boolean:
      return value ? 'Yes' : 'No';

    case FieldType.decimal:
      return value as number;

    case FieldType.select:
      return optionsMap?.[value as UUID].label ?? '-';

    default:
      return JSON.stringify(value);
  }
};
