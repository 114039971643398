import React from 'react';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TableCell,
  TableRow,
} from '@mui/material';

import colors from 'src/theme/colors';
import { UUID } from 'crypto';
import { useTranslation } from 'react-i18next';
import { countFigureTypes } from 'src/Esg/Taxonomy/utils';
import { useEsgTaxonomy } from 'src/Esg/hooks';

interface Props {
  sectorId: UUID;
}

export default function AddActivityRow({ sectorId }: Readonly<Props>) {
  const { t } = useTranslation('esg', { keyPrefix: 'taxonomy.selection' });
  const { taxonomyReport, taxonomyId, queries } = useEsgTaxonomy();

  if (queries.isLoading || !taxonomyReport) return null;

  const handleSave = (value: unknown) =>
    queries.reportActivities.create.mutate({
      report_id: taxonomyId,
      activity_id: value as UUID,
      turnover_enabled: taxonomyReport.turnover_enabled,
      capex_enabled: taxonomyReport.capex_enabled,
      opex_enabled: taxonomyReport.opex_enabled,
    });

  const reportActivityIds = queries.reportActivities._data.map(
    (ra) => ra.activity_id
  );
  const options = queries.activities._data.filter(
    (a) => a.sector_id === sectorId && !reportActivityIds.includes(a.id)
  );

  return (
    <TableRow>
      <TableCell
        sx={{
          pl: '64px',
          backgroundColor: colors.backgroundInput,
        }}
        colSpan={3 + countFigureTypes(taxonomyReport)}
      >
        <FormControl sx={{ minWidth: '250px' }}>
          <InputLabel id="activity">{t('addActivity')}</InputLabel>
          <Select
            labelId="activity"
            label={t('addActivity')}
            value=""
            onChange={(e) => handleSave(e.target.value)}
          >
            {options.map((activity) => (
              <MenuItem key={activity.id} value={activity.id}>
                {activity.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </TableCell>
    </TableRow>
  );
}
