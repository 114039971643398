import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import { useEsgOrganization } from 'src/common/hooks';
import QuestionnaireV3 from 'src/QuestionnairesV3';
import { IReferencesContext, ReferecesContext } from './references';

import {
  createEsgTokenFetcher,
  useEsgQuestionnaireAnswerId,
  useEsgQv3PreviousAnswerHints,
  useEsgQv3References,
} from './hooks';
import { useEsgReports } from 'src/Esg/hooks';
import { parseNumericId } from 'src/utils/navigation';
import { useDmaMaterialDatapoints } from 'src/Esg/Dmav2/hooks';
import { Box, createTheme } from '@mui/material';
import themeColors from 'src/theme/colors';
import theme from 'src/theme';

export default function EsgQuestionnaires() {
  const params = useParams<{
    formKey: string;
    reportId: string;
  }>();

  const organization = useEsgOrganization();
  const reportId = String(params.reportId);
  const questionnaireKey = String(params.formKey);

  const { data: { questionnaireAnswerId, questionnaireId } = {} } =
    useEsgQuestionnaireAnswerId(reportId, organization.id, questionnaireKey);

  const { _instance: report } = useEsgReports({ id: parseNumericId(reportId) });

  const tokenFetcher = useMemo(
    () =>
      createEsgTokenFetcher(organization.id, reportId, questionnaireAnswerId),
    [organization.id, reportId, questionnaireAnswerId]
  );

  const { data: _references } = useEsgQv3References(
    questionnaireKey,
    questionnaireId
  );
  const { data: _materiality } = useDmaMaterialDatapoints(report?.esg_dma_id);

  const references: IReferencesContext = useMemo(() => {
    const references = _references || { questions: {}, fields: {} };
    const datapointMateriality = Object.fromEntries(
      _materiality?.map((x) => [x.esg_datapoint_id, x]) || []
    );
    return { ...references, datapointMateriality };
  }, [_references, _materiality]);

  const { hints } = useEsgQv3PreviousAnswerHints(questionnaireAnswerId);

  return (
    <Box width="100%" mt="10px" p="0 64px" mb="80px">
      <ReferecesContext.Provider value={references}>
        <QuestionnaireV3
          questionnaireId={questionnaireId}
          questionnaireAnswerId={questionnaireAnswerId}
          tokenFetcher={tokenFetcher}
          options={{ hints }}
          theme={createTheme({
            typography: theme.typography,
            palette: {
              primary: {
                main: themeColors.esgMain,
                contrastText: themeColors.pureWhite,
              },
            },
          })}
        />
      </ReferecesContext.Provider>
    </Box>
  );
}
