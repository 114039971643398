import React from 'react';
import { Box } from '@mui/material';

import { useEsgTaxonomy } from 'src/Esg/hooks';
import TaxonomyQuestionnaire from './OldTaxonomyQuestionnaire';

const questionnaireId = '7e5a50b7-27f2-406a-866e-061dd39ea4ab';

export default function TaxonomyMethodology() {
  const { taxonomyId } = useEsgTaxonomy();
  if (!taxonomyId) return null;
  return (
    <Box sx={{ p: '0px 64px' }}>
      <TaxonomyQuestionnaire
        taxonomyId={taxonomyId}
        questionnaireId={questionnaireId}
      />
    </Box>
  );
}
