import { TableCell } from '@mui/material';
import { UUID } from 'crypto';
import React, { useState } from 'react';
import FieldInput from 'src/QuestionnairesV3/Fields/Inputs';
import {
  useFieldAnswers,
  useQuestionAnswers,
  useQuestionTable,
} from 'src/QuestionnairesV3/hooks';
import { FieldValueSchema } from 'src/QuestionnairesV3/types';
import ConfirmationModal from 'src/components/ConfirmationModal';
import TableRowWithDimensions from '../../components/TableRowWithDimensions';
import ErrorRow from '../../Rows/Error';
import ActionButtons from '../../Rows/ActionButtons';

interface Props {
  questionId: UUID;
  questionAnswerId: number;
}

export default function Answer(props: Readonly<Props>) {
  const { questionId, questionAnswerId } = props;
  const { _data } = useFieldAnswers({ question_answer_id: questionAnswerId });
  const { _delete } = useQuestionAnswers({ question_id: questionId });
  const { fields, dimensions, updateRow } = useQuestionTable(questionId);

  const [values, setValues] = useState<Record<UUID, FieldValueSchema>>({});
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  // Convert the data to a more usable format, mapping field_id to value
  const originalValues = React.useMemo(() => {
    return _data.reduce((acc: Record<UUID, FieldValueSchema>, item) => {
      acc[item.field_id] = item.value;
      return acc;
    }, {});
  }, [_data]);

  React.useEffect(() => {
    setValues(originalValues);
  }, [originalValues]);

  const modified = JSON.stringify(values) !== JSON.stringify(originalValues);
  const modifiedSx = modified ? { borderBottom: 'none' } : {};
  const errors = updateRow.error?.errors || {};

  return (
    <>
      <TableRowWithDimensions dimensionsCount={dimensions.length}>
        {fields.map((field) => {
          return (
            <TableCell
              key={field.id}
              size="small"
              sx={{ verticalAlign: 'top', ...modifiedSx }}
            >
              <FieldInput
                field={field}
                value={values[field.id] ?? ''}
                error={errors[field.id]}
                onChange={(value) =>
                  setValues((prevState) => ({
                    ...prevState,
                    [field.id]: value,
                  }))
                }
              />
            </TableCell>
          );
        })}
      </TableRowWithDimensions>
      {errors.row && <ErrorRow colSpan={fields.length} message={errors.row} />}
      {modified && (
        <ActionButtons
          colSpan={fields.length}
          onCancelClick={() => setValues(originalValues)}
          onDeleteClick={() => setDeleteConfirmationOpen(true)}
          onSaveClick={() => updateRow.mutate({ id: questionAnswerId, values })}
        />
      )}
      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={() => {
          setDeleteConfirmationOpen(false);
          _delete.mutate(questionAnswerId);
        }}
      />
    </>
  );
}
