import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@mui/material';
import { UUID } from 'crypto';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuestionnaireMethodology } from 'src/Esg/hooks';

interface Props {
  value: UUID;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: SelectChangeEvent<UUID>) => void;
}

export default function MethodologySelect(props: Props) {
  const { t } = useTranslation();
  const { _data: methodologies } = useQuestionnaireMethodology();

  return (
    <FormControl fullWidth>
      <InputLabel id="questionnaire-methodology-label">
        {t('esg:questionnaireMethodology.methodology')}
      </InputLabel>
      <Select
        labelId="questionnaire-methodology-label"
        label={t('esg:questionnaireMethodology.methodology')}
        {...props}
      >
        {methodologies.map((methodology) => (
          <MenuItem key={methodology.id} value={methodology.id}>
            {methodology.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}
