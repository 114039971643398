import React from 'react';
import { Section as SectionType } from '../types';
import { Box, SvgIconOwnProps, Typography } from '@mui/material';

import {
  AttachFile,
  Dangerous,
  FormatListBulleted,
  WbIncandescent,
} from '@mui/icons-material';
import { Trans } from 'react-i18next';

export const getIcon = (section: SectionType) => {
  const RotatedWbIncandescent = (props: SvgIconOwnProps) => (
    <WbIncandescent sx={{ rotate: '180deg' }} {...props} />
  );
  switch (section) {
    case 'intro':
      return RotatedWbIncandescent;
    case 'questions':
      return FormatListBulleted;
    case 'risks':
      return Dangerous;
    case 'files':
      return AttachFile;
    default:
      return null;
  }
};

interface Props {
  section: SectionType;
}

export default function Section(props: Readonly<Props>) {
  const { section } = props;
  const Icon = getIcon(section);

  return (
    <Box position="relative">
      {Icon && (
        <Box
          position="absolute"
          width="64px"
          height="64px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          top="-16px"
          left="-80px"
          border="2px solid #bbb"
          bgcolor="#fff"
          borderRadius="50%"
        >
          <Icon fontSize="large" />
        </Box>
      )}
      <Typography variant="h2" sx={{ fontWeight: 400 }}>
        <Trans i18nKey={`esg:qv3_sections_labels.${section}`} />
      </Typography>
    </Box>
  );
}
