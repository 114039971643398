import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import colors from 'src/theme/colors';
import { useTranslation } from 'react-i18next';

export default function Head() {
  const { t } = useTranslation('esg', {
    keyPrefix: 'taxonomy.activities.turnover.table.headers',
  });

  return (
    <TableHead>
      <TableRow>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '70%' }}>
          {t('activity')}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '20%' }}>
          {t('turnover')}
        </TableCell>
        <TableCell sx={{ bgcolor: colors.backgroundInput, width: '10%' }} />
      </TableRow>
    </TableHead>
  );
}
