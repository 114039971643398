import { Button, Typography } from '@mui/material';
import React from 'react';
import { useQuestionAnswers } from '../hooks';
import { StyledPanel } from 'src/components/StyledPanel';
import Fields from '../Fields';
import { QuestionOutSchema } from '../types';
import { Add } from '@mui/icons-material';
import { useLanguage } from 'src/common/hooks';

interface Props {
  question: QuestionOutSchema;
}
export default function Question(props: Readonly<Props>) {
  const { question } = props;
  const { t } = useLanguage({ keyPrefix: 'questionnaireV3.questionAnswer' });

  const { _data: questionAnswers, create } = useQuestionAnswers(
    { question_id: question.id },
    { enabled: !!question.id }
  );

  const isCreateAllowed = questionAnswers.length < 1 || !!question.allow_many;

  return (
    <StyledPanel m="24px 0px" maxWidth="900px" mx="auto">
      <Typography variant="h5" fontWeight={700}>
        {question.title}
      </Typography>

      <Typography sx={{ mt: '10px' }} variant="body1">
        {question.subtitle}
      </Typography>

      {/* render fields for each questionAnswer */}
      {questionAnswers.map((questionAnswer) => (
        <Fields
          key={questionAnswer.id}
          questionId={question.id}
          questionAnswerId={questionAnswer.id}
        />
      ))}

      <br />
      {isCreateAllowed && (
        <Button
          fullWidth
          startIcon={<Add />}
          variant="outlined"
          sx={{ m: '48px 0px' }}
          onClick={() => create.mutate({ question_id: question.id })}
        >
          {t('createButton')}
        </Button>
      )}
    </StyledPanel>
  );
}
