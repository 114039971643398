import { IconButton, TableCell } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React, { useState } from 'react';
import ConfirmationModal from 'src/components/ConfirmationModal';
import { useEsgSubTopic } from '../hooks';
import { EsgDmaSubtopicOutSchema } from '../types';
import { useTranslation } from 'react-i18next';
import { useQueryClient } from 'react-query';

export default function DeleteCustomSubtopicCell(props: {
  subtopic: EsgDmaSubtopicOutSchema;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const { controller } = useEsgSubTopic({}, { enabled: false });

  if (props.subtopic.esg_dma_id === null) return null;

  return (
    <>
      <TableCell>
        <IconButton onClick={() => setDeleteConfirmationOpen(true)}>
          <DeleteIcon />
        </IconButton>
      </TableCell>

      <ConfirmationModal
        open={deleteConfirmationOpen}
        onClose={() => setDeleteConfirmationOpen(false)}
        onFalse={() => setDeleteConfirmationOpen(false)}
        onTrue={() =>
          controller._delete
            .mutateAsync(props.subtopic.id)
            .then(() => queryClient.invalidateQueries('dmaHierarchy'))
        }
        titleKey={t('esg:dma.tableV2.removeSubtopic')}
      />
    </>
  );
}
