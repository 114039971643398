import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams, useNavigate } from 'react-router-dom';
import { client } from '../../../../utils/api-client';
import { useTranslation } from 'react-i18next';
import { useEsgOrganization } from 'src/common/hooks';
import DmaMatrixPlot, { Props as DmaMatrixPlotProps } from '../DmaMatrixPlot';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { IconButton } from '@mui/material';
import SomethingWentWrong from '../../../../components/SomethingWentWrong';
import PageLoading from '../../../../components/PageLoading';
import { StyledPanel, StyledTitle } from '../../../styles';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import DmaMatrixTable from '../DmaMatrixTable';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export interface Score {
  financial?: number | unknown;
  nonfinancial?: number | unknown;
}

export interface TimeHorizonScores {
  short: Score;
  medium: Score;
  long: Score;
}

export enum ImpactLevel {
  HIGH = 'HIGH',
  MEDIUM = 'MEDIUM',
  LOW = 'LOW',
}

export interface DmaMatrixDatapoint {
  esg_topic_color?: string;
  esg_topic_key: string;
  esg_topic_title: string;
  scores: TimeHorizonScores;
  impact_level: ImpactLevel;
}

export default function DmaMatrixView() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { id: organizationId } = useEsgOrganization();

  const { reportId } = useParams();
  const [showDirections, setShowDirections] = useState<boolean>(true);
  const [visibleDataPoints, setVisibleDataPoints] = useState<
    Record<string, boolean>
  >({});

  const { data, isSuccess, isError } = useQuery(
    ['dma-table', organizationId, reportId],
    () =>
      client
        .get(
          `web/esg_dma/${organizationId}/${reportId}/dma-matrix?preliminary=false`
        )
        .then((res) => res.data)
  );

  useEffect(() => {
    if (data) {
      const visibilityMap: Record<string, boolean> = {};
      Object.keys(data).forEach((key) => {
        visibilityMap[key] = true;
      });
      setVisibleDataPoints(visibilityMap);
    }
  }, [data]);

  const toggleDataPointVisibility = (id: string) => {
    setVisibleDataPoints((prev: Record<string, boolean>) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  if (isError) {
    return <SomethingWentWrong />;
  }
  if (!isSuccess) {
    return <PageLoading />;
  }

  // filter out topics that do not have financial and nonfiancial score for any time horizon
  const filteredData: Record<string, DmaMatrixDatapoint> = Object.fromEntries(
    Object.entries(data).filter(([_, datapoint]: [string, any]) =>
      ['short', 'medium', 'long'].some(
        (timeHorizon) =>
          Number.isFinite(datapoint.scores[timeHorizon].financial) &&
          Number.isFinite(datapoint.scores[timeHorizon].nonfinancial)
      )
    )
  ) as Record<string, DmaMatrixDatapoint>;

  const hasData = Object.keys(filteredData).length > 0;

  const dmaMatrixPlotData: DmaMatrixPlotProps = {
    xLabel: t('esg:dmaMatrix.financialScore'),
    xSubLabel: t('esg:dmaMatrix.outSideIn'),
    yLabel: t('esg:dmaMatrix.nonfinancialScore'),
    ySubLabel: t('esg:dmaMatrix.inSideOut'),
    showDirections,
    visibleDataPoints,
    traces: Object.values(filteredData).map(
      (datapoint: DmaMatrixDatapoint) => ({
        color: datapoint.esg_topic_color || 'yellow',
        label: datapoint.esg_topic_key,
        path: [
          {
            x: datapoint.scores.short.financial as number,
            y: datapoint.scores.short.nonfinancial as number,
          },
          {
            x: datapoint.scores.medium.financial as number,
            y: datapoint.scores.medium.nonfinancial as number,
          },
          {
            x: datapoint.scores.long.financial as number,
            y: datapoint.scores.long.nonfinancial as number,
          },
        ].filter(
          (point) => Number.isFinite(point.x) && Number.isFinite(point.y)
        ),
      })
    ),
  };

  return (
    <>
      <StyledTitle>
        <IconButton onClick={() => navigate(-1)}>
          <KeyboardArrowLeftIcon fontSize="large" sx={{ color: '#38414f' }} />
        </IconButton>
        {t('esg:dmaMatrix.title')}
      </StyledTitle>
      <Box
        style={{
          padding: '36px 32px',
          borderRadius: '16px',
          border: '1px solid #ccc',
          background: '#fff',
          color: '#38414F',
        }}
      >
        {hasData ? (
          <>
            <div style={{ width: '1000px' }}>
              <DmaMatrixPlot {...dmaMatrixPlotData} />
            </div>
            <div style={{ display: 'flex', marginBottom: '20px' }}>
              <IconButton
                sx={{ color: '#38414F' }}
                onClick={() => setShowDirections(!showDirections)}
              >
                {showDirections ? (
                  <CheckBoxIcon />
                ) : (
                  <CheckBoxOutlineBlankIcon />
                )}
              </IconButton>
              <Typography mt="10px" variant="inherit" sx={{ color: '#38414F' }}>
                {t('esg:dmaMatrix.showLongTermDirection')}
              </Typography>
            </div>

            {t('esg:dmaMatrix.topicListTitle')}
            <ul>
              {Object.values(filteredData).map(
                (datapoint: DmaMatrixDatapoint) => (
                  <li key={datapoint.esg_topic_key}>
                    {datapoint.esg_topic_key}: {datapoint.esg_topic_title}
                  </li>
                )
              )}
            </ul>
          </>
        ) : (
          <Typography variant="h5">{t('esg:dmaMatrix.noData')}</Typography>
        )}
      </Box>
      {hasData && (
        <>
          <br />
          <StyledPanel>
            <DmaMatrixTable
              data={filteredData}
              onButtonClick={toggleDataPointVisibility}
              visibleDataPoints={visibleDataPoints}
            />
          </StyledPanel>
        </>
      )}
    </>
  );
}
