import { useQuery } from 'react-query';

import { client } from 'src/utils/api-client';
import { useLanguage } from 'src/common/hooks';
import { IReferencesContext } from './references';
import { FieldAnswerHintsOutSchema } from 'src/QuestionnairesV3/types';
import { createTokenFetcher } from 'src/QuestionnairesV3/utils';

export const createEsgTokenFetcher = (
  organizationId: number,
  reportId: string,
  questionnaireAnswerId: string
) => {
  const url = `web/esg_v2/${organizationId}/${reportId}/indicators/${questionnaireAnswerId}/token`;
  return createTokenFetcher(async () => {
    const res = await client.post(url);
    return res.data;
  });
};

export const useEsgQuestionnaireAnswerId = (
  reportId: string,
  organizationId: number | string,
  questionnaireKey: string
) => {
  const url = `/web/esg_v2/${organizationId}/${reportId}/indicators?esg_questionnaire_key=${questionnaireKey}`;
  const queryKey = [
    'esg-questionnaire-answer-id',
    organizationId,
    reportId,
    questionnaireKey,
  ];
  return useQuery(
    queryKey,
    () =>
      client.put(url).then((res) => {
        const questionnaireAnswerId = res?.data?.esg_questionnaire_answer_id;
        const questionnaireId = res?.data?.questionnaire_id;
        return {
          questionnaireAnswerId,
          questionnaireId,
        };
      }),
    { enabled: !!organizationId }
  );
};

export const useEsgQv3References = (
  questionnaireKey: string,
  questionnaireId: string
) => {
  const lang = useLanguage();
  const queryKey = ['references', questionnaireKey, lang];
  const url = `/web/esg/v3/esg-questionnaires/esrs-references/${questionnaireId}`;
  return useQuery(
    queryKey,
    () => client.get<IReferencesContext>(url).then((res) => res.data),
    { enabled: !!questionnaireId }
  );
};

export const useEsgQv3PreviousAnswerHints = (questionnaireAnswerId: string) => {
  const queryKey = ['esg-previous-answer-hints', questionnaireAnswerId];
  const url = `/web/esg/v3/esg-questionnaires/hints/${questionnaireAnswerId}`;
  const query = useQuery(
    queryKey,
    () => client.get<FieldAnswerHintsOutSchema>(url).then((res) => res.data),
    { enabled: !!questionnaireAnswerId }
  );
  return {
    hints: query.data || {},
    ...query,
  };
};
