import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import { ExpenditureType } from '../../../types';
import SumRows from './SumRows';
import Head from './Head';
import ActivityRow from './ActivityRow';

interface Props {
  type: ExpenditureType;
}

export default function ExpenditureTable({ type }: Readonly<Props>) {
  const { queries } = useEsgTaxonomy();
  if (queries.isLoading) return null;

  const reportActivities = queries.reportActivities._data.filter((ra) => {
    if (type === ExpenditureType.Capex) return ra.capex_enabled;
    else return ra.opex_enabled;
  });

  return (
    <TableContainer sx={{ overflowX: 'auto' }}>
      <Table stickyHeader>
        <Head figure={type} />
        <TableBody>
          {reportActivities.map((activity) => (
            <ActivityRow
              key={activity.id}
              type={type}
              reportActivityId={activity.id}
            />
          ))}
          <SumRows type={type} />
        </TableBody>
      </Table>
    </TableContainer>
  );
}
