import React from 'react';

import Field from '../components/Field';
import { ValueChainEmployeesByCountryOutSchema } from 'src/Esg/Dmav2/types';
import { useAllCountries, useLanguage } from 'src/common/hooks';
import SaveButton from '../components/SaveButton';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

interface Props {
  entityEmplyeesByCountry: ValueChainEmployeesByCountryOutSchema;
  // eslint-disable-next-line no-unused-vars
  onSave: (data: ValueChainEmployeesByCountryOutSchema) => void;
  // eslint-disable-next-line no-unused-vars
  onDelete: (id: number) => void;
}

export default function EntityEmpolyeesByCountry(props: Readonly<Props>) {
  const { entityEmplyeesByCountry, onSave, onDelete } = props;
  const { t } = useLanguage();
  const { data: countries } = useAllCountries();

  const [form, setForm] = React.useState<ValueChainEmployeesByCountryOutSchema>(
    entityEmplyeesByCountry
  );

  const handleFormChange = (fieldName: string, value: any) =>
    setForm({ ...form, [fieldName]: value });

  const country = countries.find(({ iso_code }) => iso_code === form.country);

  const isModified =
    JSON.stringify(form) !== JSON.stringify(entityEmplyeesByCountry);

  return (
    <>
      <Field
        label={t('esg:dma.step1.valueChain.country')}
        value={country?.name ?? form.country}
        textFieldProps={{
          InputProps: {
            readOnly: true,
            endAdornment: (
              <IconButton
                size="small"
                sx={{ mr: '-10px' }}
                onClick={() => onDelete(form.id)}
              >
                <Close fontSize="small" />
              </IconButton>
            ),
          },
        }}
      />
      <Field
        sx={{ mt: '-10px', mb: '10px' }}
        value={form.count ?? ''}
        isNumber
        onChange={(e) => handleFormChange('count', e.target.value || null)}
        label={t('esg:dma.step1.valueChain.employeeCount')}
      />
      {!!isModified && <SaveButton onSave={() => onSave(form)} />}
    </>
  );
}
