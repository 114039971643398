import React from 'react';
import { TableCell, TableCellProps, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledNoData = styled(TableCell)`
  text-align: center;
  background-color: #fafafa;
  font-size: 12px;
`;

export default function NoData(props: Readonly<TableCellProps>) {
  return (
    <TableRow>
      <StyledNoData {...props}>No data</StyledNoData>
    </TableRow>
  );
}
