import React from 'react';
import { useFieldAnswers } from 'src/QuestionnairesV3/hooks';
import ValueCell from './ValueCell';
import { FieldOutSchema } from 'src/QuestionnairesV3/types';

interface Props {
  questionAnswerId: number;
  field: FieldOutSchema;
  // eslint-disable-next-line no-unused-vars
  onClick: (fieldAnswer: any) => void;
}

// we need this middle component to call useFieldAnswers by question_answer_id
// for query invalidation to work in a optimal way
export default function Answer(props: Readonly<Props>) {
  const { questionAnswerId, field, onClick } = props;

  const fieldAnswers = useFieldAnswers({
    question_answer_id: questionAnswerId,
  });

  const fieldAnswer = fieldAnswers._data.find((fa) => fa.field_id === field.id);

  return (
    <ValueCell
      field={field}
      value={fieldAnswer?.value}
      onClick={() => onClick(fieldAnswer)}
    />
  );
}
