import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Title, Image } from '../SomethingWentWrong/index.styles';
import errorIcon from '../../images/error.png';

export default function MaintenanceModeActive() {
  const { t } = useTranslation();
  return (
    <Container>
      <Image src={errorIcon} />
      <Title>{t('error.maintenance')}</Title>
    </Container>
  );
}
