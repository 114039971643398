import React from 'react';
import { Box, Table, TableBody } from '@mui/material';

import { useDmaHierarchy, useEsgDmaActions } from '../../hooks';
import Head from './Head';
import TitleRow from './Rows/TitleRow';
import FullScreenPanel from 'src/Esg/components/StyledPanelFullscreen';
import { UUID } from 'crypto';
import ExportToCsvButton from '../../components/DmaExportButton';
import { useParams } from 'react-router-dom';
import { useEsgReports } from 'src/Esg/hooks';
import DmaAdditionalFilesButton from '../../components/DmaAdditionalFilesButton';
import ReasoningDialogProvider from './Rows/MatterReasoningRow/ReasoningDialog';

export default function Step5Table({ esg_dma_id }: { esg_dma_id: UUID }) {
  const { stages23Hierarchy } = useDmaHierarchy({ esg_dma_id });

  const { reportId } = useParams();
  const { _instance: report } = useEsgReports({ id: Number(reportId) });
  const { exportStep5ToCsv } = useEsgDmaActions(report?.esg_dma_id);

  return (
    <FullScreenPanel
      customButtonComponent={
        <Box sx={{ display: 'flex', gap: '10px' }}>
          <DmaAdditionalFilesButton />
          <ExportToCsvButton
            handleExportToCsv={() => exportStep5ToCsv.mutate(null)}
          />
        </Box>
      }
    >
      <ReasoningDialogProvider esgDmaId={esg_dma_id}>
        <Table stickyHeader sx={{ minWidth: '1330px' }}>
          <Head />
          <TableBody>
            {Object.entries(stages23Hierarchy).map(([title, data]) => (
              <TitleRow key={title} title={title} data={data} />
            ))}
          </TableBody>
        </Table>
      </ReasoningDialogProvider>
    </FullScreenPanel>
  );
}
