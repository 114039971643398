import React from 'react';
import { Table, TableBody, TableContainer } from '@mui/material';
import Head from './Head';
import { useEsgTaxonomy } from 'src/Esg/hooks';
import ActivityRow from './ActivityRow';
import {
  ExpenditureContributionValueType,
  ExpenditureType,
} from 'src/Esg/Taxonomy/types';

interface Props {
  type: ExpenditureType;
  valueType: ExpenditureContributionValueType;
}

export default function ExpenditureTable(props: Readonly<Props>) {
  const { type, valueType } = props;
  const { taxonomyReport, queries } = useEsgTaxonomy();
  if (!taxonomyReport || queries.isLoading) return null;

  const reportActivities = queries.reportActivities._data.filter((ra) => {
    if (type === ExpenditureType.Capex) return ra.capex_enabled;
    else return ra.opex_enabled;
  });

  return (
    <TableContainer sx={{ overflowX: 'auto' }}>
      <Table stickyHeader>
        <Head type={type} valueType={valueType} />
        <TableBody>
          {reportActivities.map((reportActivity) => (
            <ActivityRow
              key={reportActivity.id}
              figure={type}
              valueType={valueType}
              reportActivityId={reportActivity.id}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
