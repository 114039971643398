import React from 'react';
import { Box, Typography } from '@mui/material';
import AcceptableTextField from 'src/components/AcceptableTextField';
import { format } from 'date-fns';
import { dateTimeFormat } from 'src/common/constants';
import { StyledFlex } from 'src/components/StyledFlex';
import { EsgStakeholderAnswerCommentsOutSchema } from 'src/Esg/Dmav2/types';

interface SingleCommentProps {
  comment: EsgStakeholderAnswerCommentsOutSchema;
  onUpdate?: (
    updated: EsgStakeholderAnswerCommentsOutSchema
  ) => void | Promise<void>;
  onDelete?: (id: number) => void | Promise<void>;
  t: (key: string) => string;
}

export default function SingleComment({
  comment,
  onUpdate,
  onDelete,
  t,
}: SingleCommentProps) {
  const [editing, setEditing] = React.useState(false);

  const createdAt = format(new Date(comment.created_at), dateTimeFormat);
  const updatedAt = format(new Date(comment.updated_at), dateTimeFormat);
  const wasEdited = updatedAt !== createdAt;

  async function handleAcceptEdit(value: string) {
    if (onUpdate) {
      await onUpdate({ ...comment, text: value });
      setEditing(false);
    }
  }

  function handleRejectEdit() {
    setEditing(false);
  }

  return (
    <Box
      sx={{
        bgcolor: '#eee',
        borderRadius: '20px',
        p: '10px 20px',
        mt: '10px',
        border: '1px solid #e0e0e0',
      }}
    >
      <StyledFlex>
        <Typography variant="caption">{comment.created_by}</Typography>
        <Typography variant="caption">{createdAt}</Typography>
      </StyledFlex>

      <Box p="10px 0">
        {!editing ? (
          <Typography>{comment.text}</Typography>
        ) : (
          <AcceptableTextField
            fullWidth
            multiline
            value={comment.text}
            onAccept={(value) => handleAcceptEdit(String(value))}
            onReject={() => handleRejectEdit()}
          />
        )}
      </Box>

      <StyledFlex>
        <Typography variant="caption">
          {wasEdited ? `Edited: ${updatedAt}` : ''}
        </Typography>
        {!editing && onUpdate && onDelete && (
          <Box>
            <Typography
              variant="caption"
              sx={{ cursor: 'pointer', fontWeight: 700 }}
              onClick={() => setEditing(true)}
            >
              {t('editComment')}
            </Typography>
            <span style={{ padding: '10px' }}>|</span>
            <Typography
              variant="caption"
              sx={{ cursor: 'pointer', fontWeight: 700 }}
              onClick={() => onDelete(comment.id)}
            >
              {t('deleteComment')}
            </Typography>
          </Box>
        )}
      </StyledFlex>
    </Box>
  );
}
