import { SelectChangeEvent } from '@mui/material';
import { UUID } from 'crypto';
import { ChangeEvent, MouseEvent } from 'react';

/* eslint-disable no-unused-vars */

export type Qv3Context = {
  questionnaireId?: UUID;
  questionnaireAnswerId?: UUID;
  options?: Qv3Options;
  modifiedQuestionAnswers: {
    ids: number[];
    add: (questionAnswerId: number) => void;
    remove: (questionAnswerId: number) => void;
  };
};

export type DecimalWithUnitSchema = {
  value: number | string | null;
  unit: string | null;
};

export enum FieldType {
  boolean = 'boolean',
  decimal = 'decimal',
  decimal_with_unit = 'decimal_with_unit',
  text = 'text',
  select = 'select',
  multiselect = 'multiselect',
  file = 'file',
  multifile = 'multifile',
}

/* eslint-disable no-unused-vars */
export enum VisualType {
  Empty = 'empty',
  SimpleTextField = 'simple-text-field',
  DecimalTextField = 'decimal-text-field',
  DecimalWithUnitSelectHorizontal = 'decimal-with-unit-select-horizontal',
  SimpleCheckbox = 'simple-checkbox',
  SimpleSelect = 'simple-select',
  CheckboxMultiselect = 'checkbox-multiselect',
  RadioGroup = 'radio-group',
  Rating = 'rating',
  ToggleButtonGroup = 'toggle-button-group',
  SimpleFilePicker = 'simple-file-picker',
  MultiFilePicker = 'multi-file-picker',
}

export interface InputProps {
  // questionAnswerId?: AnswerStateId;
  // fieldAnswerId?: AnswerStateId;
  field: FieldOutSchema;
  error?: string;
  value?: FieldValueSchema;
  onChange?: (
    value: FieldValueSchema,
    event: ChangeEvent | MouseEvent | SelectChangeEvent
  ) => void;
}
// Questionnaires v3 secions
export type Section = 'intro' | 'questions' | 'risks' | 'files';

export type FieldValueSchema =
  | string
  | string[]
  | number
  | boolean
  | null
  | DecimalWithUnitSchema;

// Questionnaire Interfaces
export interface QuestionnaireOutSchema {
  id: UUID;
  key: string;
  title?: string;
  subtitle?: string;
  description?: string;
}

export interface QuestionnaireFilterSchema {
  id: UUID; // needs id for exact match
  key?: string; // Exact match on the `key` field
  key__icontains?: string; // Case-insensitive substring match on `key`
  key__in?: string[]; // List-based match for `key`
}

export enum QuestionTableLayoutSchema {
  IN_COLUMNS = 'in_columns',
  IN_ROWS = 'in_rows',
}

export interface QuestionTableOutSchema {
  id: number;
  dimensions: UUID[];
  layout: QuestionTableLayoutSchema;
}

export interface QuestionTableFilterSchema {
  id: number;
  question_id: UUID;
}

// Question Interfaces

export interface QuestionOutSchema {
  id: UUID;
  key: string;
  title?: string;
  description?: string;
  subtitle?: string;
  questionnaire_id?: UUID;
  allow_many?: boolean;
  table_id?: number;
}

export interface QuestionFilterSchema {
  id: UUID; // needs id for exact match
  key?: string; // Exact match on the `key` field
  key__icontains?: string; // Case-insensitive substring match on `key`
  key__in?: string[]; // List-based match for `key`
  questionnaire_id?: UUID;
}

// Field Interfaces
export interface FieldOutSchema {
  id: UUID;
  key: string;
  title?: string;
  description?: string;
  label?: string;
  placeholder?: string;
  type: FieldType;
  visual_type: VisualType;
  required: boolean;
  use_previous_answers_as_hints: boolean;
  reference_id?: number; // PositiveIntegerField
  question_id: UUID;
  enum_id?: number;
}

export interface FieldOptionOutSchema {
  id: UUID;
  field_id: UUID;
  label?: string;
}

export interface FieldOptionFilterSchema {
  field_id: UUID;
}

export interface FieldFilterSchema {
  key?: string; // Exact match on the `key` field
  key__icontains?: string; // Case-insensitive substring match on `key`
  key__in?: string[]; // List-based match for `key`
  question__questionnaire_id?: UUID;
  question_id?: UUID;
}

// Question Answer Interfaces
export interface QuestionAnswerInSchema {
  question_id: UUID;
}

export interface QuestionAnswerOutSchema extends QuestionAnswerInSchema {
  id: number;
}

// Field Answer Interfaces
export interface FieldAnswerInSchema {
  field_id: UUID;
  question_answer_id?: number;
  value: FieldValueSchema;
}

export interface FieldAnswerFilterSchema {
  question_answer_id?: number;
  question_answer__question_id?: UUID;
  field_id?: UUID;
}

export interface FieldAnswerOutSchema extends FieldAnswerInSchema {
  id: number;
  question_answer_id: number;
}

export interface FieldAnswerUpdateSchema {
  id: number;
  value: FieldValueSchema;
}

// FieldAnswerStateId allows strings to assign temporary ids to new field answers
export type AnswerStateId = number | string;
export interface FieldAnswerStateSchema {
  id: AnswerStateId;
  question_answer_id: AnswerStateId;
  field_id: UUID;
  value: FieldValueSchema;

  // internal state
  __isNew?: boolean;
  __isUpdated?: boolean;
}

export interface ValuesSchema {
  // key = questionId::questionAnswerId::fieldId::fieldAnswerId
  [key: string]: FieldAnswerStateSchema;
}

export interface FieldAnswerHintsOutSchema {
  [key: string]: any[];
}

export interface Qv3Options {
  hints?: FieldAnswerHintsOutSchema;
}

export type Qv3EnumOptionSchema = {
  value: string;
  label: string;
};

export type Qv3EnumSchema = {
  name: string;
  default: string;
  options: Qv3EnumOptionSchema[];
};

export interface FieldAnswerCommentsInSchema {
  text: string;
  field_answer_id: number;
}

export interface FieldAnswerCommentsOutSchema
  extends FieldAnswerCommentsInSchema {
  id: number;
  created_at: string;
  created_by_id: number;
  created_by: string; // full_name backend user property (resolved in BE out schema)
  updated_at: string;
}

export interface FieldAnswerCommentsUpdateSchema {
  text: string;
}

export interface FieldAnswersOutSchema {
  id: number;
  field_id: UUID;
  question_answer_id: UUID;
  value: FieldValueSchema;
}

export interface QuestionTableRowSchema {
  id: number;
  values: Record<UUID, FieldValueSchema>;
}
