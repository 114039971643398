import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';

import assets from '../assets';
import Container from '../components/Container';
import Title from '../components/Title';
import QuestionnaireTable from './Table';

import { useEsgStakeholderSurveyMetadata } from '../../hooks';
import SomethingWentWrong from 'src/components/SomethingWentWrong';

export default function StakeholderSurvey() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { metadata, isError } = useEsgStakeholderSurveyMetadata();

  const nextLink = {
    onClick: () => navigate(`../end`),
    label: 'esg:stakeholderSurvey.finish',
  };

  if (isError) return <SomethingWentWrong />;
  return (
    <Container
      header={<img alt="" src={assets.intro.image} />}
      nextLink={nextLink}
    >
      <Title
        title={t('esg:stakeholderSurvey.title')}
        period={metadata?.report_period || ''}
      />
      <Typography
        dangerouslySetInnerHTML={{
          __html: t('esg:stakeholderSurvey.description'),
        }}
      />
      <br />
      <Typography variant="h5">
        {t('esg:stakeholderSurvey.subtitle')}
      </Typography>
      <br />
      <QuestionnaireTable topics={metadata.topics} />
    </Container>
  );
}
