import React from 'react';
import { client } from 'src/utils/api-client';
import { Autocomplete } from '@mui/material';
import { StyledTextField } from '../Modals/index.styles';
import { LcaProductMap, LcaProductOutSchema, Pagination } from 'src/Lca/types';
import { useTranslation } from 'react-i18next';

interface Props {
  value: number | undefined;
  // eslint-disable-next-line no-unused-vars
  onChange: (value: number) => void;
}

interface ProductOption {
  label: string;
  value: string;
}

export default function ProcessesSearchField({ value, onChange }: Props) {
  const valueString = value !== undefined ? String(value) : '';
  const [searchTimeout, setSearchTimeout] = React.useState<any>(null);
  const { t } = useTranslation('lca');

  const [data, setData] = React.useState<LcaProductMap>({});

  const [searchText, setSearchText] = React.useState('');

  const getData = (searchText: string) => {
    client
      .get<Pagination<LcaProductOutSchema>>('/web/lca/products', {
        params: {
          name: searchText,
          offset: 0,
          limit: 50,
        },
      })
      .then((res) => {
        const newDataMap: LcaProductMap = {};
        Object.values(data).forEach((product) => {
          newDataMap[String(product.id)] = product;
        });
        res.data.items.forEach((product: LcaProductOutSchema) => {
          newDataMap[String(product.id)] = product;
        });
        setData(newDataMap);
      });
  };

  // handle search input change
  // fetch data from the API after 1 second of inactivity
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSearchText(value);
    if (searchTimeout) clearTimeout(searchTimeout);
    const timeout = setTimeout(() => {
      getData(value);
    }, 1000);
    setSearchTimeout(timeout);
  };

  React.useEffect(() => {
    if (value !== undefined) {
      client.get(`/web/lca/products/${value}`).then((res) => {
        const newDataMap: LcaProductMap = {};
        Object.values(data).forEach((product) => {
          newDataMap[String(product.id)] = product;
        });
        newDataMap[valueString] = res.data;
        setData(newDataMap);
      });
    }
  }, [value]);

  // fetch data from the API on component mount
  React.useEffect(() => {
    getData('');
  }, []);

  const handleOnChange = (
    _: React.SyntheticEvent<Element, Event>,
    value: any
  ) => {
    // get the activity from the data array
    // and send it to the parent component
    const product = data[value];
    onChange(product?.id);
  };

  const getLabel = (value: string) => {
    const product = data[value];
    if (!product) return '';
    return `${product.organization_name} - ${product.name}`;
  };

  // data parsed to { value,label } array for MuiAutocomplete
  const options: ProductOption[] = Object.values(data).map(
    (product: LcaProductOutSchema) => ({
      value: String(product.id),
      label: getLabel(String(product.id)),
    })
  );

  // searchText filtered and parsed to value array for MuiAutocomplete
  const _options = (options || [])
    .filter((item: ProductOption) =>
      item.label.toLowerCase().includes(searchText.toLowerCase())
    )
    .sort((a, b) => a.label.localeCompare(b.label))
    .map((item: ProductOption) => String(item.value));

  return (
    <Autocomplete
      fullWidth
      options={_options}
      value={valueString}
      onChange={handleOnChange}
      sx={{ backgroundColor: !!value ? '#F6F6F6' : 'inherit' }} // I couldn't force it with themes styling
      renderInput={(params) => (
        <StyledTextField
          {...params}
          value=""
          label={t('searchSubproduct')}
          onChange={handleSearchChange}
        />
      )}
      getOptionLabel={getLabel}
    />
  );
}
