import { Chart, Plugin } from 'chart.js';
import { EsgDmaTopicHierarchySchema, Stage23HierarchySchema } from './types';
import React from 'react';
import { useSidebar } from 'src/views/components/Sidebar/hooks';

export const SESSION_SURVEY_TOKEN_KEY = 'stakeholderSurveyToken';

// hierarchy for stages 2 and 3
// is only 2 levels: TitleRow -> (SubtopicRow or SubSubTopicRow) -> ImpactRow
export const parseStages23Hierarchy = (
  topics: EsgDmaTopicHierarchySchema[]
) => {
  const results: {
    [key: string]: Stage23HierarchySchema;
  } = {};
  for (const topic of topics) {
    for (const subtopic of topic.subtopics) {
      const subsubtopics = subtopic.subsubtopics.filter((s) => !s.is_virtual);
      const key =
        subsubtopics.length === 0
          ? `${topic.key} ${topic.name}`
          : `${topic.key} ${topic.name} > ${subtopic.description}`;

      // register the topic if it doesn't exist
      if (!results[key])
        results[key] = {
          topicId: topic.id,
          subtopics: [],
          subsubtopics: [],
          color: topic.color,
        };

      // add the subtopic and subsubtopics
      if (subsubtopics.length === 0) results[key].subtopics.push(subtopic);
      else results[key].subsubtopics.push(...subsubtopics);
    }
  }
  return results;
};

interface CustomScatterDataPoint {
  x: number;
  y: number;
}

export const dataLabelPlugin: Plugin<'scatter'> = {
  id: 'dataLabelPlugin',
  afterDatasetsDraw: (
    chart: Chart<'scatter', CustomScatterDataPoint[], string>
  ) => {
    const { ctx } = chart;

    chart.data.datasets.forEach((dataset) => {
      ctx.fillStyle = '#38414F';
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = '600 16px Poppins';

      dataset.data.forEach((dataPoint) => {
        if (!dataPoint) {
          return;
        }
        const xPos = chart.scales.x.getPixelForValue(dataPoint.x);
        const yPos = chart.scales.y.getPixelForValue(dataPoint.y);

        ctx.fillText(dataset.label as string, xPos, yPos);
      });
    });
  },
};

export const generateImageUrls = (
  language: string,
  presentationNumber: number,
  slidesCount: number
) => {
  const baseUrl = `https://assets.envirly.pl/dmav2/topics-slides/${language}`;
  return Array.from(
    { length: slidesCount },
    (_, index) =>
      `${baseUrl}/${String(presentationNumber).padStart(2, '0')}_${String(
        index + 1
      ).padStart(2, '0')}.png`
  );
};

export const hideSidebarOnMount = () => {
  const sidebar = useSidebar();
  React.useEffect(() => {
    const { isOpen } = sidebar;
    sidebar.update({ isOpen: false });
    return () => {
      sidebar.update({ isOpen });
    };
  }, []);
};

export const dateToString = (date: Date, language: string) => {
  return date.toLocaleString(language, {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
};
